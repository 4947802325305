import React, {useEffect, useState} from 'react';

import CategoryListItem from 'components/profiles/categories/CategoryListItem';
import Control from 'components/atoms/Control';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [coreOrg, setCoreOrg] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [categories, setCategories] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID){
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token){
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.coreOrg && props.coreOrg !== coreOrg){
			setCoreOrg(props.coreOrg);
		}
	}, [props.coreOrg]);
	
	useEffect(() => {
		if(props.languageCode && 
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}else if(!languageCode) {
			setLanguageCode('en');
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.categories && languageCode) {
			if(Array.isArray(props.categories) &&
				props.objExists(props.categories[0], 'category_id') && 
				props.categories !== categories) {
				setCategories(props.categories);			
			}else if(Object.entries(props.categories).length > 0 && 
					props.objExists(props.categories, `${languageCode}`)) {
				setCategories(props.categories[languageCode]);
			}
		}
	}, [props.categories, languageCode]);
	
	// FUNCTIONS
	function handleAddEditCategory(category = null, category_parent = null) {
		//console.log("We're Gonna Add A Category");
		if(props.actionAddEdit) {
			props.actionAddEdit(category, category_parent);
		}
	}
	
	//console.log("CAT LIST CATEGORIES", categories, props.categories, props.languageCode);
	
	return (
		<div className="list-set">
			{props.site.persona.languages && 
				props.site.persona.languages.length > 0 &&
				categories && 
				Array.isArray(categories) &&
				categories.length > 0 &&
				<div className="info-block margin-top-1em margin-bottom-1em">
					Click the <b>Category Name</b> to add/remove additional languages.
				</div>
			}
			<ul className="ul">
				{categories && 
					Array.isArray(categories) &&
					categories.map((cat, index) => {
						return <CategoryListItem
									{...props}
									key={`catLstItm${index}`}
									userID={userID}
									token={token}
									coreOrg={coreOrg} 
									category={cat}
									languageCode={languageCode}
									actionEdit={handleAddEditCategory}	/>
					})
				}
			</ul>
			{coreOrg &&
				<div className="list-set-ctl margin-top-2em">
					<Control icon="add" label="Add Category" showLabel={true} action={()=>{handleAddEditCategory()}} />
				</div>
			}
		</div>
	)
}