import React, {useEffect, useState} from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import Loader from 'components/atoms/Loader';
import FormDynamic from 'components/forms/FormDynamic';
import ModalConfirmation from 'components/molecules/ModalConfirmation';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [productLanguage, setProductLanguage] = useState(null);
	const [coreOrg, setCoreOrg] = useState(null);
	const [additionalLanguages, setAdditionalLanguages] = useState(null);
	const [productTypes, setProductTypes] = useState(null);
	const [optionsTypes, setOptionsTypes] = useState(null);
	const [form, setForm] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	// const [productCode, setProductCode] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
		  setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
	    	setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode && !languageCode) {
			setLanguageCode(props.languageCode);
		}else if(!languageCode) {
			setLanguageCode('en');
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.productLanguage && !productLanguage) {
			setProductLanguage(props.languageCode);
		}else if(!productLanguage) {
			setProductLanguage('en');
		}
	}, [props.productLanguage]);
	
	useEffect(() => {
		if(props.organizationID && 
			props.organizationID !== organizationID) {
			setOrganizationID(props.organizationID);	
		}
	}, [props.organizationID]);
	
	useEffect(() => {
		if(props.coreOrg && !coreOrg) {
			setCoreOrg(props.coreOrg);
		} 
	}, [props.coreOrg]);
	
	useEffect(() => {
		let languageOptions = [];
		if(coreOrg && props.objExists(props.admin, 'organizations')) {
			let orgObj = props.admin.organizations.find(org => org.organization_id === coreOrg);
			if(props.objExists(orgObj, 'languages') && Array.isArray(orgObj.languages)) {
				orgObj.languages.map(lang => {
					if(lang.language_code !== 'en') {
						languageOptions.push(
							{option_name: lang.language_name, option_value: lang.language_code}
						);
					}
				})
				setAdditionalLanguages(languageOptions);
			}else{
				setAdditionalLanguages([]);
			}
		}else if(props.objExists(props.admin, 'languages') && Array.isArray(props.admin.languages)) {
			props.admin.languages.map(lang => {
				if(lang.language_code !== 'en') {
					languageOptions.push(
						{option_name: lang.language_name, option_value: lang.language_code}
					);
				}
			})
			setAdditionalLanguages(languageOptions);
		}else if(Array.isArray(props.site.persona.languages)) {
			props.site.persona.languages.map(lang => {
				if(lang.language_code !== 'en') {
					languageOptions.push(
						{option_name: lang.language_name, option_value: lang.language_code}
					);
				}
			})
			setAdditionalLanguages(languageOptions);
		}
	}, [props.site.persona, props.admin.organizations, coreOrg]);
	
	useEffect(() => {
		if(languageCode && Array.isArray(props.productTypes)) {
			let pTypes = props.productTypes.filter(pType => pType.organization_id > 1);
			if(coreOrg) {
				pTypes = pTypes.filter(pType => pType.organization_id === coreOrg);
			}
			
			if(Array.isArray(pTypes) && pTypes !== productTypes) {
				setProductTypes(pTypes);
			}
		}
	}, [props.productTypes, coreOrg, languageCode]);
	
	useEffect(() => {
		if(productLanguage && productTypes &&
		  Array.isArray(productTypes)) {
			let typeTest = [];
			let typeOptions = [];
			productTypes.map(type => {
			  if(type[productLanguage] && !typeTest.includes(type[productLanguage].type_name)) {
				typeTest.push(type[productLanguage].type_name);
				return typeOptions.push({option_name: props.capitalizeString(type[productLanguage].type_name, true), option_value: type.type_id})
			  }else{
				return false;
			  }
			});
			if(optionsTypes !== typeOptions) {
			  setOptionsTypes(typeOptions);
			}
		}else{
			setOptionsTypes([]);
		}
	}, [productTypes, productLanguage]);
	
	useEffect(() => {
		if(organizationID && 
			props.productLanguage && 
			optionsTypes &&
			userID &&
			token) {
			setFieldsets(null);
			let prodFieldset = {fieldset_name: 'Product Details', fields: []};
			
			if(organizationID === 1 && props.optionsOrgs) {
				prodFieldset.fields.push({
					field_type: 'select',
				  	field_name:'organization_id',
				  	field_label: 'Organization',
				  	field_default: coreOrg ? coreOrg : null,
				  	field_required: true,
				  	field_validate: true,
				  	option_groups: [
						{options: [
								{option_name: 'Select Organization...', option_value: null},
								...props.optionsOrgs
							]
						}
				  	],
				  	field_order: 1,
				  	fieldOnChange: updateOrg
				});
			}else{
				prodFieldset.fields.push(
					{
						field_type: 'hidden',
						field_name: 'organization_id',
						field_value: organizationID
					}
				)
			}
			
			
			prodFieldset.fields.push(
				{field_type: 'select',
			  	field_name: 'language_code',
			  	field_label: 'Language',
			  	field_placeholder: "Select Language",
			  	field_default: productLanguage ? 
								  	productLanguage : 
									  	props.objExists(props, 'product.language_code') ?
										  	props.product.language_code : 'en',
			  	field_required: true,
			  	field_validate: true,
			  	field_order: 2,
			  	fieldOnChange: updateLanguage,
			  	option_groups: [
					{options: [
				  	{option_name: 'English', option_value: 'en'},
				  	...additionalLanguages
					]
			  	}]
				},
				{field_type: 'select',
			  	field_name: `type_id`,
			  	field_label: 'Type',
			  	field_placeholder: 'Product Type',
			  	field_value: props.objExists(props, `product.details.${productLanguage}.type.type_id`) ?
								props.product.details[productLanguage].type.type_id : null,
			  	field_required: true,
			  	field_validate: true,
			  	field_order: 3,
			  	// fieldOnChange: updateProductCode,
			  	option_groups: [ 
					{options: [
						{option_name: 'Add New Type...', option_value: '0',
							fieldsets: [
								{
									fields: [
										{field_type: 'text',
										field_name: `type_name`,
										field_label: 'New Product Type',
										field_placeholder: 'Add a new product type',
										field_value: props.objExists(props, `product.details.${props.productLanguage}.type.type_name`) ?
											props.product.details[props.productLanguage].type.type_name : null,
										field_required: true,
										field_validate: true,
										field_order: 4,
										}
									]
								}
							]
						},
						...optionsTypes
				  	]
					}
			  	]
			  	},{field_type: 'text',
			  	field_name: `product_name`,
			  	field_label: 'Name',
			  	field_placeholder: 'Product Name',
			  	field_value: props.objExists(props, `product.details.${productLanguage}.product_name`) ?
							  	props.product.details[productLanguage].product_name : '',
			  	field_required: true,
			  	field_validate: true,
			  	field_order: 5,},
			  	// {field_type: 'text',
			  	// field_name: `product_code`,
			  	// field_label: 'Product Code (ORG-PRD-LANG)',
			  	// field_placeholder: 'Product Code',
			  	// field_value: productCode ? buildProductCode(productCode) : '',
			  	// field_required: true,
			  	// field_validate: true,
			  	// field_order: 6,},
			  	{field_type: 'text',
				field_name: `product_sku`,
				field_label: 'Product SKU',
				field_placeholder: 'Product SKU',
				field_value: props.objExists(props, `product.details.${productLanguage}.product_sku`) ?
				  props.product.details[productLanguage].product_sku : '',
				field_required: true,
				field_validate: true,
				field_order: 6,},
			  	{field_type: 'hidden',
			  	field_name: `product_published`,
			  	field_value: '0',
			  	},
			  	{field_type: 'hidden',
			  	field_name: 'user_id',
			  	field_value: userID,
			  	},
			  	{field_type: 'hidden',
			  	field_name: 'token',
			  	field_value: token,
			  	},
			  	{field_type: 'hidden',
			  	field_name: 'process',
			  	field_value: 'add_product'
			  	}
			);
			setFieldsets([prodFieldset]);
		}
		
	}, [languageCode, userID, token, organizationID, props.optionsOrgs, coreOrg, additionalLanguages, optionsTypes]);
	
	useEffect(() => {
		if(fieldsets) {
			setForm(
				<FormDynamic
				  content={fieldsets}
				  name="add-product"
				  returnObject={true}
				  cancelable={props.cancelable}
				  fieldOnChange={handleChange}
				  fieldOnLoad={null}
				  formActions={{formCancel: handleCancel, formSubmit: handleAdd}} />
			)
		}
	}, [fieldsets, coreOrg]);
	
	// FUNCTIONS
	function handleCancel() {
		props.setModal(
			<ModalConfirmation {...props}
								message={"Are you sure you want to clear this information"}
								actionCancel={()=>{props.setModal(null);}}
								actionAccept={clearProduct} />
		)
	}
	
	function clearProduct() {
		props.setModal(null);
		if(props.actionClear) {
			props.actionClear();
		}
	}
	
	function handleChange(param) {
		props.actionFieldOnChange &&
		props.objGetValue(props[props.location ? props.location : 'admin'], param.name) !== param.value &&
		props.actionFieldOnChange(param);
	}
	
	function handleAdd(params) {
		//console.log("Form has been submitted", params);
		props.objExists(props, 'actionAdd') &&
		props.actionAdd(params);
	}
	
	function updateOrg(field) {
		if(parseInt(field.value) > 0 &&
			parseInt(field.value) !== coreOrg) {
			setCoreOrg(parseInt(field.value));
			// updateProductCode({name: field.name, value: field.value});
		}else if (parseInt(field.value <= 0 && coreOrg)){
			setCoreOrg(null);
			// setProductCode(null);
		}
	}
	
	function updateLanguage(field) {
		if(field.value !== productLanguage) {
			setProductLanguage(field.value);
		}
	}
	
	// function updateProductCode(input) {
	// 	if(input.name === 'organization_id' && 
	// 		props.objExists(props.admin, 'organizations') &&
	// 		Array.isArray(props.admin.organizations)) {
	// 		const pOrg = props.admin.organizations.find(org => org.organization_id === parseInt(input.value));
	// 		if(pOrg && pOrg.organization_code);
	// 		setProductCode({...productCode, org: pOrg.organization_code});
	// 	}else if(input.name === 'type_id' && 
	// 				productTypes && Array.isArray(productTypes)) {
	// 		const pType = productTypes.find(type => type.type_id === parseInt(input.value));
	// 		console.log("WORKING WITH THIS", input, props.objExists(pType, `${productLanguage}.type_name`), pType);
	// 		if(props.objExists(pType, `${productLanguage}.type_name`)) {
	// 			let typeCode = '';
	// 			let nameNodes = pType[productLanguage].type_name.split(' ');
	// 			nameNodes.map(nNode => {
	// 				typeCode += nNode[0];
	// 			});
	// 			console.log("WORKING p code", typeCode);
	// 			setProductCode({...productCode, product: typeCode});
	// 		}
	// 	}
	// }
	
	// function buildProductCode(code) {
	// 	let pCode = '';
	// 	if(code.org) {
	// 		pCode = `${code.org.toUpperCase()}-`;
	// 		
	// 		if(code.product) {
	// 			pCode += `${code.product.toUppperCase()}-`;
	// 			
	// 			if(code.language) {
	// 				pCode += `${code.language.toUppperCase()}-`;
	// 			}
	// 		}
	// 	}
	// 	return pCode;
	// }
	
	return (
		form ? form : <Loader />
	)
}