import React, {useEffect, useState, Fragment} from 'react';
import {useParams, useLocation, Redirect} from 'react-router';

import Project from 'components/profiles/projects/Project';
import Button from 'components/forms/elements/Button';
import Loader from 'components/atoms/Loader';

import * as forms from 'methods/forms';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [canEdit, setCanEdit] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  const [projectStatus, setProjectStatus] = useState(null);
  const [project, setProject] = useState(null);
  const [redirect, setRedirect] = useState(null);
  

  const pgParams = useParams();
  const location = useLocation();

  useEffect(() => {
    return() => {
      props.adminDeleteVal('project');
    }
  }, []);

  useEffect(() => {
    if(props.userID) {
      setUserID(props.userID)
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token) {
      setToken(props.token)
    }
  }, [props.token]);

  useEffect(() => {
    if(props.organizationID && organizationID !== props.organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);
  
  useEffect(() => {
    props.canEdit &&
    props.canEdit !== canEdit &&
    setCanEdit(props.canEdit);
  }, [props.canEdit]);
  
  useEffect(() => {
    props.isSuper &&
    props.isSuper !== isSuper &&
    setIsSuper(props.isSuper);
  }, [props.isSuper]);

  useEffect(() => {
    if(userID && pgParams.component && token && organizationID) {
      let params =  [
                      {name: 'process', value: 'get_projects'},
                      {name: 'project_id', value: pgParams.component},
                      {name: 'user_id', value: userID},
                      {name: 'token', value: token}]
      if(![1, 2].includes(organizationID)) {
        params.push({name: 'organization_id', value: organizationID});
      }
      if(!props.objExists(props.admin, 'project') ||
          props.objGetValue(props.admin, 'project.project_id') !== parseInt(pgParams.component) ||
          props.objGetValue(props.admin, 'reload.project') === true) {
        props.adminLoadState(params, 'orders', 'project');
        if(props.objGetValue(props.admin, 'reload.project') === true) {
          props.adminDeleteVal('reload.project');
        }
      }
    }
  }, [userID, token, organizationID, pgParams.component, props.admin.project, props.admin.reload]);
  
  useEffect(() => {
    if(props.admin.project && 
        props.objGetValue(props.admin, `project.project_id`) ===  parseInt(pgParams.component)
        && props.admin.project !== project) {
      setProject(props.admin.project);  
    }
  }, [props.admin.project]);
  
  // FUNCTIONS
  return(
      <Fragment>
        <div className="profile">
          {redirect && <Redirect to={redirect} />}
          <Button icon="arrow"
                  iconClass="rotate270"
                  label="Back to Orders"
                  btnClass="success-bg"
                  btnAction={()=>{setRedirect(`/admin/orders/order-list`)}} />
        {userID && project ?
          <Project {...props}
                    isAdmin={true}
                    project={project}
                    location={'admin'}
                    userID={userID}
                    token={token}
                    organizationID={organizationID}
                    organizationName={organizationName}
                    languageCode={languageCode}
                    canEdit={canEdit}
                    isSuper={isSuper}
                    userAccess={userAccess}
                    actionAddOrderItemsToCart={props.actionAddOrderItemsToCart} />
            :
          <Loader label="Loading order..." />
        }
        </div>
      </Fragment>
  )
}
