import React, {useEffect, useState, Fragment} from 'react';
import { Redirect } from 'react-router-dom';
import { useParams } from "react-router";
import axios from 'axios';

import * as apis from 'methods/api.js';
import * as utils from 'methods/site';

import Project from 'components/profiles/projects/Project';
import ModalConfirmation from 'components/molecules/ModalConfirmation';
import Loader from 'components/atoms/Loader';

export default (props) => {
  props = {...props.children, ...utils};

  const [userID, setUserID] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [token, setToken] = useState(null);
  const [siteLanguage, setSiteLanguage] = useState(null);
  const [tasksSet, setTasksSet] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [isAddingItemsToCart, setIsAddingItemsToCart] = useState(null);
  const pgParams = useParams();

  // HOOKS
  useEffect(() => {
    return () => {
      props.userDeleteVal('order');
    }
  }, []);
  
  useEffect(() => {
    if(props.objExists(props.user, 'user_id') &&
        props.user.user_id !== userID) {
          setUserID(props.user.user_id);
    }
    
    if(sessionStorage.getItem('token')) {
      setToken(sessionStorage.getItem('token'))
    }else if(localStorage.getItem('token')) {
      setToken(localStorage.getItem('token'))
    }
  }, [props.user]);

  useEffect(() => {
    if(props.objExists(props.site, 'persona.organization_id')) {
      setOrganizationID(props.site.persona.organization_id);
      setOrganizationName(props.site.persona.organization_name);
    }
    if(props.objExists(props.site, 'persona.language_code')) {
      setSiteLanguage(props.site.persona.language_code);
    }else{
      setSiteLanguage('en');
    }

  }, [props.site.persona]);

  useEffect(() => {
    if(parseInt(pgParams.project_id) > 0 && userID && token) {
      const params = [
        {name: 'process', value: 'get_projects'},
        {name: 'user_id', value: userID},
        {name: 'core_user', value: userID},
        {name: 'token', value: token},
        {name: 'project_id', value: pgParams.project_id}
      ]
      props.userLoadState(params, 'orders', 'order');
    }
  }, [pgParams, userID, token]);
  
  useEffect(() => {
    if(!tasksSet && props.objExists(props.user, 'order.project_id')) {
      if(!props.objExists(props.user, 'order.tasks') || 
          !Array.isArray(props.user.order.tasks) || 
          props.user.order.tasks.length <= 0) {
            const taskParams = [
              {name: 'process', value: 'set_project_tasks'},
              {name: 'project_id', value: props.user.order.project_id},
              {name: 'organization_id', value: props.user.order.organization_id},
              {name: 'user_id', value: userID},
              {name: 'return_tasks', value: true}
            ];
            props.userLoadState(taskParams, 'tasks', 'order.tasks');
            setTasksSet(true);
            
      }else{
        setTasksSet(true);
      }
      
    }
  }, [props.user.order, userID]);
  
  useEffect(() => {
    if(props.objExists(props.cart, 'project')) {
      sessionStorage.removeItem('corePrjct');
      props.filesDeleteVal('uploads');
      props.filesDeleteVal('uploadComplete');
      props.cartEmpty();
    }
  }, [props.cart]);
  
  function handleSubmit() {
    props.setModal(null);
    setRedirect(`/cart`);
  }
  
  function addOrderItemsToCart(project_id) {
    
    if(project_id) {
      
      setIsAddingItemsToCart(true);
      
      let formData = `process=duplicate_order_items&user_id=${userID}&token=${token}&project_id=${project_id}`;
      axios.post(apis.getReqUrl('orders'), formData).then(res => {
        //console.log('TEST res.data', res.data);
        if((res.data.approval_required && res.data.approval_required.length > 0) || (res.data.declined_max && res.data.declined_max.length > 0) || (res.data.declined_stock && res.data.declined_stock.length > 0)) {
          props.setModal(
            <ModalConfirmation {...props}
              labelDecline={`Close`}
              labelAccept={`View Cart`}
              message={props.buildConfirmationMessage(res.data.approval_required, res.data.declined_max, res.data.declined_stock)}
              actionAccept={handleSubmit} />
          )
        }
        if(res.data.approved && res.data.approved.length > 0) {
          props.cartAssignValue('items', res.data.approved);
          setRedirect(`/cart`);
          setIsAddingItemsToCart(false);
        }else if((res.data.declined_max && res.data.declined_max.length > 0) || (res.data.declined_stock && res.data.declined_stock.length > 0)) {
          setIsAddingItemsToCart(false);
        }
      }).catch(error => {
        console.log("INIT SITE ERROR:", error);
      })
      
    }
    
  }

  return (
    <div className="profile">
      {redirect && <Redirect to={redirect} />}
      {!isAddingItemsToCart && props.objExists(props.user, 'order') ?
        <Fragment>
          <h3>Thank you! Your order has been received.</h3>
          <Project {...props}
            {...utils}
            isAdmin={false}
            userID={userID}
            token={token}
            organizationID={organizationID}
            organizationName={organizationName}
            languageCode={siteLanguage}
            project={props.user.order} admin={false}
            actionAddOrderItemsToCart={addOrderItemsToCart} />
        </Fragment>
      :
        isAddingItemsToCart ?
          <Loader label="Adding items to cart" overlayContents={true} />
        :
          <Loader label="Creating your order"/>
      }
    </div>

  )
}
