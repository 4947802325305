import React, {useEffect, useState, Fragment} from 'react';

import ValueSelector from 'components/forms/elements/ValueSelector';
import Shell from 'components/profiles/shells/Shell';
import ShellFieldCtl from 'components/profiles/shells/ShellFieldCtl';
import ShellGroupCtl from 'components/profiles/shells/ShellGroupCtl';
import ModalConfirmation from 'components/molecules/ModalConfirmation';
import Switch from 'components/forms/elements/Switch';
import IconToken from 'components/icons/IconToken';
import Control from 'components/atoms/Control';
import FormShellEditGroup from 'components/forms/common_forms/FormShellEditGroup';
import FormShellAddField from 'components/forms/common_forms/FormShellAddField';
import FormShellAddFieldGroup from 'components/forms/common_forms/FormShellAddFieldGroup';
import ShellFieldListItem from 'components/profiles/shells/ShellFieldListItem';
import PDFViewer from 'components/pdf/PDFViewer';

import * as shellMethods from 'methods/shells';

export default (props) => {
	props = {...props, ...shellMethods};
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [shell, setShell] =  useState(null);
	const [product, setProduct] = useState(null);
	const [pdfFonts, setPDFFonts] = useState(null);
	const [pages, setPages] =  useState(null);
	const [page, setPage] = useState(null);
	const [curPage, setCurPage] = useState(null);
	const [dimensions, setDimensions] = useState(null);
	const [activeField, setActiveField] = useState(null);
	const [activeGroup, setActiveGroup] = useState(null);
	const [fieldCtl, setFieldCtl] = useState(null);
	const [groupCtl, setGroupCtl] = useState(null);
	const [reset, setReset] = useState(null);
	const [productFields, setProductFields] = useState(null);
	const [assignField, setAssignField] = useState(null);
	const [addFieldGroup, setAddFieldGroup] = useState(null);
	const [assignGroupField, setAssignGroupField] = useState(null);
	const [previewURL, setPreviewURL] = useState(null);
	const [refreshPreview, setRefreshPreview] = useState(null);
	
	useEffect(() => {
		if(props.userID && 
			props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && 
			props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode && 
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.language]);
	
	useEffect(() => {
		if(props.shell && 
			props.shell !== shell) {
			setShell(props.shell);
		}
	}, [props.shell]);
	
	useEffect(() => {
		if(props.product &&
			props.product !== product) {
			setProduct(props.product);
		}
	}, [props.product]);
	
	useEffect(() => {
		if(userID, token, shell, product) {
			setPreviewURL(
				`https://api.aecore.app/files/create_pdf.php?query=${btoa(`?process=output_shell_pdf&user_id=${userID}&token=${token}&product_id=${product.product_id ? product.product_id : 0}&language_code=${languageCode ? languageCode : 'en'}&preview_mode=true`)}`
			);
		}
	}, [shell, userID, token, product, curPage]);
	
	useEffect(() => {
		if(languageCode &&
			Array.isArray(props.objGetValue(product, `details.${languageCode}.fieldsets`))) {
			let fields = [];
			product.details[languageCode].fieldsets.map(fieldset => {
				fields.push(...fieldset.fields);
			})
			if(fields.length > 0 && fields !== productFields) {
				setProductFields(fields);
			}
		}
	}, [product, languageCode]);
	
	useEffect(() => {
		if(props.pdfFonts && 
			props.pdfFonts !== pdfFonts) {
			setPDFFonts(props.pdfFonts);
		}
	}, [props.pdfFonts]);
	
	useEffect(() =>{
		if(props.objExists(shell, 'pages') &&
			shell.pages !== pages) {
			setPages(shell.pages);
		}
	}, [shell]);
	
	useEffect(() => {
		if(!curPage) {
			setCurPage(1);
		}
	}, [curPage]);
	
	useEffect(() => {
		if(pages && curPage &&
			pages[curPage] &&
			pages[curPage] !== page) {
			setPage(pages[curPage]);
		}
	}, [pages, curPage]);
	
	useEffect(() => {
		if(activeField) {
			fieldCtl && setFieldCtl(null);
			groupCtl && setGroupCtl(null);
			
			setFieldCtl(
				<ShellFieldCtl {...props}
					field={activeField}
					groupID={activeGroup ? activeGroup.group_id : null}
					fonts={pdfFonts}
					reset={reset}
					actionConfirm={loadConfirmation}
					actionUpdate={updateField} />
			);
		}else if(!activeField && fieldCtl) {
			setFieldCtl(null);
		}
	}, [activeField, page, reset, pdfFonts]);
	
	useEffect(() => {
		if(activeGroup) {
			fieldCtl && setFieldCtl(null);
			groupCtl && setGroupCtl(null);
			
			setGroupCtl(
				<ShellGroupCtl {...props}
					group={activeGroup}
					fonts={pdfFonts}
					reset={reset}
					actionConfirm={loadConfirmation}
					actionUpdate={updateGroup} />
			);
		}else if(groupCtl) {
			setGroupCtl(null);
		}
	}, [activeGroup, page, reset, pdfFonts]);
	
	useEffect(() => {
		if(page && shell && (assignField || assignGroupField)) {
			props.setModal(
				<FormShellAddField {...props}
					userID={userID}
					token={token}
					languageCode={languageCode}
					productFields={productFields}
					page={page}
					shell={shell}
					group={assignGroupField}
					actionSubmit={addPageField}
					actionCancel={()=> {
						props.setModal(null);
						setAssignField(false);
						setAssignGroupField(false);
					}} />
			)
		}
	}, [assignField, assignGroupField, page, shell, userID, token, languageCode]);
	
	useEffect(() => {
		if(addFieldGroup) {
			//console.log("Group Parent = ", addFieldGroup !== true, addFieldGroup > 0, addFieldGroup);
			props.setModal(
				<FormShellAddFieldGroup {...props}
					userID={userID}
					token={token}
					languageCode={languageCode}
					parent={props.objExists(addFieldGroup, 'group_id') ? addFieldGroup : null}
					page={page}
					shell={shell}
					actionSubmit={addPageFieldGroup}
					actionCancel={()=> {
						props.setModal(null);
						setAddFieldGroup(false);
					}} />
			)
		}
	}, [addFieldGroup]);
	
	// FUNCTIONS
	function loadConfirmation(type, target = 'field') {
		props.setModal(
			<ModalConfirmation {...props}
				message={
						type === 'cancel'?
							'Discard changes and return to original settings?'
						: type === 'reset' ?
							'Reset the values to the original settings?'
						: type === 'update' ?
							`Apply these settings to this ${target}?`
						:	'not your type'
					}
				actionCancel={()=>{
					props.setModal(null);
				}}
				actionAccept={
					type === 'cancel'?
						target === 'group' ? groupCancel : fieldCancel
					: type === 'reset' ?
						target === 'group' ? groupReset : fieldReset
					: type === 'update' ?
						target === 'group' ? groupUpdate : fieldUpdate
					:	null
				} />
		)
		// setActiveField(null);
	}
	
	function fieldCancel() {
		resetValues(); 
		setActiveField(null); 
		props.setModal(null);
	}
	
	function groupCancel() {
		resetValues(); 
		setActiveGroup(null); 
		props.setModal(null);
	}
	
	function fieldReset() {
		resetValues();
		props.setModal(null);
	}
	
	function groupReset() {
		resetValues();
		props.setModal(null);
	}
	
	function resetValues() {
		props.adminSetVal(`reload.product`, true);
	}
	
	function fieldUpdate(params) {
		props.setModal(null);
		let udField;
		if(activeField && 
			activeField.group_id &&
			page &&
			Array.isArray(page.groups)){
			let udGroup;
			let pGroup;
			if(activeField.group_parent > 0) {
				pGroup = page.groups.find(pgrp => pgrp.group_id === activeField.group_parent);
				udGroup = pGroup.subgroups.find(sgrp => sgrp.group_id === activeField.group_id);
			}else{
				udGroup = page.groups.find(grp => grp.group_id === activeField.group_id);	
			}
			
			if(udGroup && Array.isArray(udGroup.fields)) {
				udField = udGroup.fields.find(fld => fld.field_id === activeField.field_id);
			}
		}else if(activeField &&
					!activeField.group_id && 
					page &&
					Array.isArray(page.fields)) {
					udField = page.fields.find(field => field.field_id === activeField.field_id);
		}
		//console.log("UD FIELD?", params, udField, activeGroup, activeField, page, Array.isArray(page.fields));
		if(udField) {
			//console.log("Going to update the field", udField);
			const paramsUpdate = [
				{name: 'process', value: 'update_shell_field_styles'},
				{name: 'user_id', value: userID},
				{name: 'token', value: token},
				{name: 'field_id', value: activeField.field_id},
				{name: 'field_json', value: JSON.stringify(udField)},
				{name: 'group_id', value: props.objExists(activeGroup, `group_id`) ? activeGroup.group_id : null}
			];
			//console.log("FIELD UPDATE PARAMS", paramsUpdate);
			
			setRefreshPreview(true);
			props.adminLoadState(paramsUpdate, 'products', 'reload.product');
		}
	}
	
	function groupUpdate(params) {
		//console.log("HERE GOES THE GROUP UPDATE", params);
		props.setModal(null);
		if(activeGroup && page &&
			Array.isArray(page.groups)){
			let udGroup;
			let pGroup;
			if(activeGroup.group_parent > 0) {
				pGroup = page.groups.find(pgrp => pgrp.group_id === activeGroup.group_parent);
				udGroup = pGroup.subgroups.find(sgrp => sgrp.group_id === activeGroup.group_id);
			}else{
				udGroup = page.groups.find(grp => grp.group_id === activeGroup.group_id);	
			}
			
			if(udGroup) {
				//console.log("Going to update the group", udGroup);
				const paramsUpdate = [
					{name: 'process', value: 'update_shell_group'},
					{name: 'user_id', value: userID},
					{name: 'token', value: token},
					{name: 'group_json', value: JSON.stringify(udGroup)},
				];
				//console.log("GROUP UPDATE PARAMS", paramsUpdate);
				
				setRefreshPreview(true);
				props.adminLoadState(paramsUpdate, 'products', 'reload.product');
			}
		}
	}
	
	function selectPage(val) {
		if(!isNaN(parseInt(val))) {
			setActiveField(null);
			curPage !== parseInt(val) &&
			setCurPage(parseInt(val));
		}
	}
	
	function selectField(field, group = null, parent = null) {
		setActiveGroup(null);
		let selectedGroup;
		let selectedField;
		if(group && props.objExists(page, `groups`)) {
			if(parent) {
				const pGroup = page.groups.find(grp => grp.group_id === parent);
				if(Array.isArray(props.objGetValue(pGroup, `subgroups`))){
					selectedGroup = pGroup.subgroups.find(sgrp => sgrp.group_id === group);
				}
			}else{
				selectedGroup = page.groups.find(grp => grp.group_id === group);	
			}
			
			if(selectedGroup && field) {
				selectedField = selectedGroup.fields.find(fld => fld.field_id === field);
			}
		}else if(!group && field && props.objExists(page, 'fields')) {
			selectedField = page.fields.find(fld => fld.field_id === field);
		}
		
		setActiveField(selectedField ? 
				{...selectedField, group_id: group, group_parent: parent} : null);
	}
	
	function selectGroup(group) {
		setActiveField(null);
		if(group && props.objExists(page, 'groups')) {
			let selectedGroup;
			if(props.objGetValue(group, 'group_parent') > 0 ) {
				const pGroup = page.groups.find(grp => grp.group_id === group.group_parent);
				if(pGroup && Array.isArray(pGroup.subgroups)) {
					selectedGroup = pGroup.subgroups.find(sgrp => sgrp.group_id === group.group_id);
				}
			}else{
				selectedGroup = page.groups.find(grp => grp.group_id === group.group_id);	
			}
			
			if(selectedGroup) {
				setActiveGroup(selectedGroup);
			}
		}else if(!group && activeGroup) {
			setActiveGroup(null);
		}
	}
	
	function updateField(params) {
		//console.log("UPDATE FIELD", params);
		if(!params.id) {
			params['id'] = activeField.field_id;
		}
		let udParents;
		let udParent;
		let udGroups;
		let udGroup;
		if(props.objExists(activeField, `group_id`)) {
			if(props.objGetValue(activeField, `group_parent`) > 0) {
				udParents = page.groups;
				if(Array.isArray(udParents)) {
					udParent = udParents.find(pgrp => pgrp.group_id === activeField.group_parent);
					udParents = udParents.filter(pgrp => pgrp.group_id !== activeField.group_parent);
					
					if(Array.isArray(props.objGetValue(udParent, 'subgroups'))) {
						udGroups = udParent.subgroups;
						if(Array.isArray(udGroups)) {
							udGroup = udGroups.find(grp => grp.group_id === activeField.group_id);
							udGroups = udGroups.filter(grp => grp.group_id !== activeField.group_id);
						}
					}
				}
			}else{
				if(Array.isArray(page.groups)) {
					udGroups = page.groups;
					
					if(udGroups) {
						udGroup = udGroups.find(grp => grp.group_id === activeField.group_id);
						udGroups = udGroups.filter(grp => grp.group_id !== activeField.group_id);
					}
				}	
			}
			
		}
		
		let udFields;
		if(udGroup && udGroup.fields) {
			udFields = udGroup.fields;
		}else if(!props.objExists(activeField, `group_id`) && 
				props.objExists(page, 'fields')){
			udFields = page.fields; 	
		}
		
		let udField;
		if(Array.isArray(udFields)) {
			udField = udFields.find(udf => udf.field_id === params.id);
			
			if(udField && props.objGetValue(udField, params.name) !== params.value) {
				let testObj = {};
				props.objSetValue(udField, params.name, params.value);
				udFields = [...udFields.filter(udf => udf.field_id !== params.id), udField];
				
				//console.log("UD FIELD", udField);
				
				if(Array.isArray(udGroups) && udGroup) {
				
					//console.log("CHECKING GROUPS AND GROUP", udGroups, udGroup);
					udGroup = {...udGroup, fields: udFields};
					udGroups.push(udGroup);
					
					if(udParent) {
						udParent = {...udParent, subgroups: udGroups};
					}
					
					if(Array.isArray(udParents)) {
						udParents.push(udParent);
					}
					
					//setPage({...page, groups: udParents ? udParents : udGroups});
					setPages({...pages, [curPage]:{...page, groups: udParents ? udParents : udGroups}});
				}else if(!udGroup) {
					// setActiveField(udField);
					//setPage({...page, fields: udFields});
					setPages({...pages, [curPage]:{...page, fields: udFields}});
				}
				
				
			}
		}
	}
	
	function updateGroup(params) {
		if(!params.id) {
			params['id'] = activeGroup.group_id;
		}
		let udParents;
		let udParent;
		let udGroups;
		let udGroup;
		if(props.objExists(activeGroup, `group_id`)) {
			if(props.objGetValue(activeGroup, `group_parent`) > 0) {
				udParents = page.groups;
				if(Array.isArray(udParents)) {
					udParent = udParents.find(pgrp => pgrp.group_id === activeGroup.group_parent);
					udParents = udParents.filter(pgrp => pgrp.group_id !== activeGroup.group_parent);
					
					if(Array.isArray(props.objGetValue(udParent, 'subgroups'))) {
						udGroups = udParent.subgroups;
						if(Array.isArray(udGroups)) {
							udGroup = udGroups.find(grp => grp.group_id === activeGroup.group_id);
							udGroups = udGroups.filter(grp => grp.group_id !== activeGroup.group_id);
						}
					}
				}
			}else{
				if(Array.isArray(page.groups)) {
					udGroups = page.groups;
					
					if(udGroups) {
						udGroup = udGroups.find(grp => grp.group_id === activeGroup.group_id);
						udGroups = udGroups.filter(grp => grp.group_id !== activeGroup.group_id);
					}
				}	
			}
			
		}
		
		if(udGroup && props.objGetValue(udGroup, params.name) !== params.value) {
			props.objSetValue(udGroup, params.name, params.value);
			udGroups = [...udGroups, udGroup];
			udGroups = udGroups.sort((a, b) => {return a.group_order > b.group_order ? 1 : -1});
		}
		
		if(Array.isArray(udGroups) && udGroup) {
			if(udParent) {
				udParent = {...udParent, subgroups: udGroups};
			}
			
			if(Array.isArray(udParents)) {
				udParents.push(udParent);
			}
			
			//setPage({...page, groups: udParents ? udParents : udGroups});
			setPages({...pages, [curPage]:{...page, groups: udParents ? udParents : udGroups}});
		}
	}
	
	function publishShell(params) {
		updateLink('shellToProduct', shell.shell_id, product.product_id, 'link_published', params.value);
	}
	
	function updateLink(linkType, linkFrom, linkTo, linkField, linkValue) {
		let paramsLink = [
			{name: 'process', value: 'update_links'},
			{name: 'user_id', value: userID},
			{name: 'token', value: token},
			{name: 'link_type', value: linkType},
			{name: 'link_from', value: linkFrom},
			{name: 'link_to', value: linkTo},
			{name: 'link_field', value: linkField},
			{name: 'link_value', value: linkValue},	
		];
		props.adminLoadState(paramsLink, 'links', 'reload.product');
	}
	
	function loadAssignField() {
		setAssignField(true);
	}
	
	function loadAssignGroupField(group) {
		setAssignGroupField(group);
	} 
	
	function addPageField(params) {
		props.setModal(null);
		setAssignField(false);
		setAssignGroupField(false);
		props.adminLoadState(params, 'products', 'reload.product');
	}
	
	function loadEditGroup(group) {
		props.setModal(
			<FormShellEditGroup {...props}
				userID={userID}
				token={token}
				languageCode={languageCode}
				group={group}
				actionSubmit={editShellGroup}
				actionCancel={()=> {
					props.setModal(null);
				}} />
		)
	}
	
	function editShellGroup(params) {
		props.setModal(null);
		props.adminLoadState(params, 'products', 'reload.product');
	}
	
	function loadAddFieldGroup(parentGroup) {
		setAddFieldGroup(parentGroup ? parentGroup : true);
	}
	
	function addPageFieldGroup(params) {
		props.setModal(null);
		setAddFieldGroup(false);
		props.adminLoadState(params, 'products', 'reload.product');
	}
	
	function confirmRemove(type, subject, id, group_id) {
		props.setModal(
			<ModalConfirmation {...props}
				icon={type === 'field' ?
						'field' : type === 'field group' ?
							'field-group' : 'close'
					}
				message={
					`Remove ${type} <b>${props.capitalizeString(subject, true)}</b>?`
				} 
				actionAccept={()=>{remove(type, id, group_id)}} />
		)
	}
	
	function remove(type, id, group_id) {
		props.setModal(null);
		//console.log("Remove ", type, id, group_id);
		const linkType = type === 'field group' ?
							'groupToPage' :
							group_id > 0 ?
								'shellFieldToGroup' :
								'shellFieldToPage';
		updateLink(linkType, id, group_id > 0 ? group_id : page.page_id, 'link_removed', 1);
	}
	
	// console.log("SHELL INTERFACE", JSON.stringify(props.objGetValue(shell, `pages.1.fields[0]`)));
	// console.log("SHELL PAGE FIELDS", props.objGetValue(shell, `pages.1.fields`), props.admin && props.admin.reload);
	// console.log("SHELL", shell);
	// console.log("PRODUCT", product);
	// console.log("RELOAD", props.admin.reload);
	// console.log('SELECTED', activeField, activeGroup);
	// console.log("PREVIEW URL", previewURL);
	// console.log("DO WE HAVE A GROUP?", activeGroup);
	// console.log("ACTIVE FIELD", activeField);
	return (
		<div className="shell-interface">
			{shell &&
				<Fragment>
					<div className="margin-bottom-1em">
						<Switch
							name="shell_published"
							label="Published" 
							value={shell.shell_published ? true : false}
							onChange={publishShell} />
					</div>
					{pages && Object.keys(pages).length > 1 &&
						<Fragment>
							<ValueSelector {...props}
								label="Shell Page"
								values={Object.keys(pages)}
								value={String(curPage)}
								actionSelect={selectPage} />
							<h4>{`Page ${curPage}`}</h4>
						</Fragment>
					}
					<div style={{
						display: 'flex',
						flexWrap: 'wrap',
						alignItems: 'flex-start',
						marginBottom: '2em',
					}} >
						{shell && page &&
							<div className="form-row"
								style={{
									flex: '2 0 15em',
									marginRight: '2em',
								}}
							>
								<PDFViewer {...props}
									file={previewURL}
									page={curPage}
									hidePageCtl={true}
									refresh={refreshPreview}
									actionClearRefresh={()=>{setRefreshPreview(false)}}
									loadingMsg="Loading Preview" />
							</div>
						}
						
						{shell && page &&
							<div className="block-border" 
								style={{
									flex: '1 0 15em',
								}}>
								<div className="block-row">
									<b>Dimensions:</b>&nbsp;
									{`${parseFloat(shell.shell_width)} ${shell.shell_units} W x ${parseFloat(shell.shell_height)} ${shell.shell_units} H`}
								</div>
								<div className="block-row">
									<b>Bleed:</b>&nbsp;
									{shell.shell_bleed > 0 ? `${shell.shell_bleed} ${shell.shell_units}` : 'no bleed'}
								</div>
								<div className="block-row">
									<b>Pages:</b>&nbsp;
									{`${shell.pages && Object.entries(shell.pages).length > 0 ? 
											Object.entries(shell.pages).length : 
												'none' }`}
								</div>
							</div>
						}
					
					</div>
					
					
					<div style={{
						display: 'flex',
						flexWrap: 'wrap',
						alignItems: 'flex-start',
						marginBottom: '2em',
					}} >
					{shell && page && productFields &&
						<div className="block-border" 
							style={{
								flex: '1 0 15em',
								width: '50%',
								marginRight: '2em',
								marginBottom: '2em',
							}}>
							<h3>{`Page ${curPage}`}</h3>
							{Array.isArray(page.fields) &&
								<div className="block">
									<h4 style={{padding: '0em', marginBottom: '0em'}}>Fields</h4>
									{page.fields.map((pField, index) => {
										return (
											<ShellFieldListItem {...props}
												key={`sfliFld${curPage}${index}`}
												userID={userID}
												token={token}
												group={null}
												field={pField}
												curPage={curPage}
												activeField={activeField}
												activeGroup={activeGroup}
												actionEdit={null}
												actionRemove={confirmRemove}
												actionSelectField={selectField} />
										)
									})}
								</div>	
							}
							{Array.isArray(page.groups) &&
								<div className="block border-bottom">
									<h4 style={{padding: '0em', marginBottom: '0em'}}>Field Groups</h4>
									{page.groups.map((pGroup, index) => {
										return (
											<ShellFieldListItem {...props}
												key={`sfliGrp${curPage}${index}`}
												userID={userID}
												token={token}
												group={pGroup}
												field={null}
												curPage={curPage}
												activeField={activeField}
												activeGroup={activeGroup}
												actionAssign={loadAssignGroupField}
												actionAddGroup={loadAddFieldGroup}
												actionEdit={loadEditGroup}
												actionRemove={confirmRemove}
												actionSelectGroup={selectGroup}
												actionSelectField={selectField}
												 />
										)
									})}
								</div>	
							}
							{Array.isArray(productFields) &&
								<div className="block" 
										style={{
											marginTop: '1em',
											paddingTop: '1em',
											borderRadius: '0em',
											borderTop: '.0325em solid #333333'
											
										}}>
									<Control className="ctl-btn info-bg"
										iconClass="no-btn"
										icon="field"
										label="Assign a field"
										showLabel={true}
										action={loadAssignField} />
									<Control className="ctl-btn info-bg"
										iconClass="no-btn"
										icon="field-group"
										label="Add a field group"
										showLabel={true}
										tooltip="Add a field to page"
										action={loadAddFieldGroup} />
								</div>	
							}
							
						</div>
					}
						<div style={{
							flex: '1 0 15em'
						}}>
						
							{groupCtl}
							{fieldCtl}
						</div>
					</div>
				</Fragment>
			}
		</div>
	)
}