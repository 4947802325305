import React, {useEffect, useState, useCallback, Fragment} from 'react';
import {Redirect} from 'react-router';

import Select from 'components/forms/elements/Select';

export default (props) => {
	
	const [languages, setLanguages] = useState(null);
	
	useEffect(() => {
		let headerLanguages = [];
		if(props.objExists(props.admin, 'languages') && Array.isArray(props.admin.languages)) {
			props.admin.languages.map(lang => {
				headerLanguages.push(
					{option_name: lang.language_name, option_value: lang.language_code}
				);
			  })
			setLanguages(headerLanguages);
		}else if(Array.isArray(props.languages)) {
			props.languages.map(lang => {
				headerLanguages.push(
					{option_name: lang.language_name, option_value: lang.language_code}
				);
			})
			setLanguages(headerLanguages);
		}
	}, [props.languages, props.admin]);
	
	function handleLanguageSelect(param) {
		props.siteSetValue('site_language', param.value); // Page Public
		props.siteSetValue('language_code', param.value); // Page Internal
		props.storeDeleteVal('categories');
		props.storeDeleteVal('products');
		props.storeDeleteVal('product');
		props.adminDeleteVal('categories');
		props.adminDeleteVal('category');
		props.adminDeleteVal('products');
	}
	
	return (
		languages &&
			<div>
				<Select
					name="site_language"
					label="Language"
					defaultValue={props.languageCode}
					option_groups={[{options: languages}]}
					className="site-language"
					hideSelect={true}
					onChange={handleLanguageSelect} />
			</div>
	)
	
}