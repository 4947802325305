import React, {useEffect, useState} from 'react';

import Loader from 'components/atoms/Loader';
import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
	const [form, setForm] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	
	useEffect(() => {
		let availableLanguages = [];
		if(props.site.persona.languages && Array.isArray(props.site.persona.languages) && props.site.persona.languages.length > 0) {
			props.site.persona.languages.map(language => {
				if(props.languages && !props.languages.find((language_code) => language_code === language.language_code)) {
					availableLanguages.push({option_name: props.capitalizeString(language.language_name, true), option_value: language.language_id});	
				}
			})
		}
		setFieldsets([{fieldset_name: `Add Language`,
			fields: [
				availableLanguages.length > 0 && {field_type: 'select',
				field_name: 'language_id',
				field_label: 'Languages',
				field_placeholder: "Select language...",
				field_value: null,
				field_required: false,
				field_validate: false,
				field_order: 1,
				option_groups: [{
				  options: availableLanguages
				}]},
				{field_type: 'text',
				field_name: 'category_name',
				field_label: 'Category Name',
				field_placeholder: 'Name of category',
				field_required: true,
				field_validate: true,
				field_order: 2,
				},
				{field_type: 'switch',
				field_name: 'category_published',
				field_label: 'Publish',
				field_value: false,
				field_required: true,
				field_validate: true,
				field_order: 3,
				},
				{field_type: 'hidden',
				field_name: 'process',
				field_value: 'add_product_category_text'
				},
				{field_type: 'hidden',
				field_name: 'category_id',
				field_value: props.category_id
				},
				{field_type: 'hidden',
				field_name: 'user_id',
				field_value: props.userID
				},
				{field_type: 'hidden',
				field_name: 'return_boolean',
				field_value: props.returnBool
				},
			]
		}]);
	}, [props.catgeory_id]);
	
	useEffect(() => {
		if(fieldsets) {
			setForm(
				<FormDynamic
				  content={fieldsets}
				  name="add-category-language"
				  returnObject={true}
				  fieldOnLoad={null}
				  formActions={{formCancel: handleCancel, formSubmit: handleAddLanguage}} />
			)
		}
	}, [fieldsets]);
	
	function handleCancel() {
		props.setModal(null);
	}
	
	function handleAddLanguage(params) {
		props.objExists(props, 'actionSubmit') &&
		props.actionSubmit(params);
	}
	
	//console.log('TEST props.languages', props.languages);
	
	return (
		<div>
			{form ? form : <Loader />}
		</div>
	)	
}