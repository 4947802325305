import React, {useEffect, useState} from 'react';
import {useParams, useLocation} from 'react-router';
import {Redirect} from 'react-router-dom';

import Link from 'components/navigation/Link';
import IconSVG from 'components/icons/IconSVG';
import Loader from 'components/atoms/Loader';
import ShellInterface from 'components/profiles/shells/ShellInterface';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [canEdit, setCanEdit] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  const [curLanguage, setCurLanguage] = useState(null);
  const [productID, setProductID] = useState(null);
  const [product, setProduct] = useState(null);
  const [shell, setShell] = useState(null);
  const [pdfFonts, setPDFFonts] = useState(null);
  const [pdfFontError, setPDFFontError] = useState(null);

  const pgParams = useParams();
  const location = useLocation();

  // HOOKS
  useEffect(() => {
    props.adminSetVal(`reload.product`, true);
    return () => {
      // props.adminDeleteVal('product');
      setProduct(null);
    }  
  }, []);
  
  useEffect(() => {
    if(props.userID && props.userID !== userID) {
      setUserID(props.userID)
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token && props.token !== token) {
      setToken(props.token)
    }
  }, [props.token]);

  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);
  
  useEffect(() => {
    if(props.curLanguage && props.curLanguage !== curLanguage) {
      setCurLanguage(props.curLanguage);
    }else if(!props.curLanguage && languageCode && 
              languageCode !== curLanguage) {
      setCurLanguage(languageCode);
    }
  }, [props.curLanguage, languageCode]);
  
  useEffect(() => {
    if(pgParams.component && 
        parseInt(pgParams.component) > 0 &&
        parseInt(pgParams.component) !== productID) {
      setProductID(parseInt(pgParams.component));
    }
  }, [pgParams]);
  
  useEffect(() => {
    if(window.performance) {
      if(performance.navigation.type === 1) {
        // setShell(null);
      }
    }
  }, [window]);
  
  useEffect(() => {
    if(userID && token && languageCode && productID) {
      const productParams = [
        {name: 'process', value: 'get_product'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'language_code', value: languageCode},
        {name: 'product_id', value: productID}
      ];
      
      if(!props.objExists(props.admin, 'product.product_id') || 
          (productID > 0 && 
            props.objGetValue(props.admin, 'product.product_id') !== productID) ||
            props.objGetValue(props.admin, 'reload.product') === true) {
        props.adminLoadState(productParams, 'products', 'product');
        if(props.objGetValue(props.admin, 'reload.product') === true) {
          props.adminDeleteVal('reload.product');
        }
      }
    }
    
  }, [userID, token, languageCode, productID, props.admin.reload, props.admin.product]);
  
  useEffect(() => {
    if(props.objExists(props.admin, `product`) &&
      props.admin.product !== product) {
        setProduct(props.admin.product);
      }
  }, [props.admin.product]);
  
  useEffect(() => {
    if(userID && token) {
      const paramsFonts = [
        {name: 'process', value: 'get_fonts'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'font_type', value: 'pdf'}
      ];
      if(!props.objExists(props.admin, `fonts.pdf`) || 
        props.objGetValue(props.admin, `reload.fonts.pdf`) === true) {
          if(props.objGetValue(props.admin, `reload.fonts.pdf`) === true) {
            props.adminDeleteVal('reload.fonts.pdf');
          }
          props.adminLoadState(paramsFonts, 'files', 'fonts.pdf');
      }
    }
  }, [userID, token, props.admin.reload, props.admin.fonts]);
  
  useEffect(() => {
    if(props.objExists(props.admin, `fonts.pdf`)) {
      if(Array.isArray(props.admin.fonts.pdf) && 
        props.admin.fonts.pdf.length > 0 &&
        props.admin.fonts.pdf !== pdfFonts) {
          setPDFFonts(props.admin.fonts.pdf);
      }else if(!Array.isArray(props.admin.fonts.pdf) &&
              props.admin.fonts.pdf !== pdfFontError) {
          setPDFFontError(props.admin.fonts.pdf); 
      }
    }
  }, [props.admin.fonts]);
  
  useEffect(() => {
    if(product && 
        props.objExists(product, `details.${languageCode}.shell`) &&
        product.details[languageCode].shell !== shell) {
        setShell(product.details[languageCode].shell);
    }
  }, [product, languageCode]);
  
  // FUNCTIONS
  
  return (
    <div style={{width: '100%'}}>
      {product &&
        <Link to={`/admin/products/product/${product.product_id}`}>
          <IconSVG icon="arrow" className="rotate270 p" /> Back to Product
        </Link>  
      }
      <h3 className="margin-bottom-1em margin-top-1em">Shell Interface</h3>
      {pdfFontError &&
        <div>
          <b>Font Error: </b>
          {pdfFontError}
        </div>
      }
      {product && shell ?
        <ShellInterface {...props}
                  userID={userID}
                  token={token}
                  languageCode={curLanguage}
                  userAccess={userAccess}
                  isAdmin={true}
                  location='admin'
                  product={product}
                  shell={shell}
                  product={product}
                  pdfFonts={pdfFonts} />
      :
        <Loader isOverlay={true}/>
      }
    </div>
  )
}
