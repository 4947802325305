import React, {useEffect, useState} from 'react';

import Loader from 'components/atoms/Loader';
import FormDynamic from 'components/forms/FormDynamic';
import ModalConfirmation from 'components/molecules/ModalConfirmation';

import * as formData from 'methods/forms.js';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [userAccess, setUserAccess] = useState(null);
	const [payment, setPayment] = useState(null);
	const [form, setForm] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
		  setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
		  setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.organizationID && organizationID !== props.organizationID) {
		  setOrganizationID(props.organizationID);
		}
	}, [props.organizationID]);
	
	useEffect(() => {
		if(props.languageCode && languageCode !== props.languageCode) {
		  setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.userAccess && props.userAccess !== userAccess) {
		  setUserAccess(props.userAccess);
		} 
	}, [props.userAccess]);
	
	useEffect(() => {
		if(userID) {
			setFieldsets([
				{fieldset_name: props.objExists(props.payment, 'option_id') ?
									'Update Payment Option' : 'Add Payment Option',
				fields: [
					{field_type: "hidden",
					field_name: 'process',
					field_value: props.objExists(props.payment, 'option_id') ? 'update_payment_option' : 'add_payment_option'
					},
					{field_type: 'hidden',
					field_name: 'user_id', 
					field_value: userID
					},
					{field_type: 'hidden',
					field_name: 'token', 
					field_value: token
					},
					{field_type: 'hidden',
					field_name: 'option_id', 
					field_value: props.objExists(props.payment, 'option_id') ? props.payment.option_id : null
					},
					{field_type: 'text',
					field_name: 'option_name',
					field_label: 'Name',
					field_placeholder: 'Name',
					field_required: true,
					field_validate: true,
					field_order: 1,
					field_value: props.objExists(props.payment, 'option_name') ? props.payment.option_name : null
					},
					{field_type: 'text',
					field_name: 'option_component',
					field_label: 'Component',
					field_placeholder: 'Component',
					field_required: true,
					field_validate: true,
					field_order: 2,
					field_value: props.objExists(props.payment, 'option_component') ? props.payment.option_component : null
					},
					{field_type: 'text',
					field_name: 'option_icon',
					field_label: 'Icon',
					field_placeholder: 'Icon',
					field_required: false,
					field_validate: false,
					field_order: 3,
					field_value: props.objExists(props.payment, 'option_icon') ? props.payment.option_icon : null
					},
					{field_type: 'textarea',
					field_name: 'option_description',
					field_label: 'Description',
					field_placeholder: 'Description',
					field_required: false,
					field_validate: false,
					field_order: 4,
					field_value: props.objExists(props.payment, 'option_description') ? props.payment.option_description : null
					},
					{field_type: 'switch',
					field_name: 'option_public',
					field_label: 'Public',
					field_default: props.objExists(props.payment, 'option_public') && props.payment.option_public === 1 ? true : false,
					field_required: false,
					field_verify: false,
					field_order: 5
					},
				]}
			])
		}
	}, [userID, props.payment]);
	
	useEffect(() => {
		if(fieldsets) {
			setForm(
				<FormDynamic
				  content={fieldsets}
				  name={props.objExists(props.payment, 'option_id') ? `updated-payment-option` : `add-payment-option`}
				  formCompleteLabel={props.objExists(props.payment, 'option_id') ? `Update` : `Create`}
				  returnObject={true}
				  fieldOnChange={null}
				  fieldOnLoad={null}
				  formActions={{formCancel: handleCancel, formSubmit: handleAddEdit}} />
			)
		}
	}, [fieldsets, props.payment]);
	
	// FUNCTIONS
	function handleCancel() {
		if(props.actionCancel) {
			props.actionCancel();
		}
	}
	
	function handleAddEdit(params) {
		if(props.actionAddEdit) {
			props.actionAddEdit(params, props.actionParams ? props.actionParams : null);	
		}
	}
	
	return (
		form ? form : <Loader />
	)
}