import React, {useEffect, useState} from 'react';

import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [location,setLocation] = useState(null);
	const [ctlLocation, setCtlLocation] = useState(null);
	const [locationChanged, setLocationChanged] = useState(null);
	const [optionsCarriers, setOptionsCarriers] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	const [form, setForm] = useState(null);
	
	// HOOKS
	useEffect(() => {
		return () =>{
			// setFieldsets(null);
			// setForm(null);
		}
	}, []);
	
	useEffect(() => {
		if(props.userID && props.userID != userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token != token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode && 
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}else if (!props.languageCode && 
					!languageCode &&
					props.objExists(props.site, 'site_language')) {
			setLanguageCode(props.site.site_language);
		}else if (!languageCode) {
			setLanguageCode('en');
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.location && props.location !== location) {
			setLocation(props.location);
			setCtlLocation(props.location);
		}
	}, [props.location]);
	
	useEffect(() => {
		if(Array.isArray(props.shippingCarriers) &&
			props.shippingCarriers.length > 0) {
			let optCarriers = [];
			props.shippingCarriers.map(carrier => {
				optCarriers.push({option_name: carrier.carrier_name, 
									option_value: carrier.carrier_id});
			})
			if(optCarriers.length > 0 &&
				optCarriers !== optionsCarriers) {
				setOptionsCarriers(optCarriers);	
			}
		}
	}, [props.shippingCarriers]);
	
	useEffect(() => {
		if(location &&
			location !== ctlLocation) {
			setLocationChanged(true);
		}else if(location === ctlLocation 
					&& locationChanged){
			setLocationChanged(false);
		}
	}, [location, ctlLocation]);
	
	useEffect(() => {
		if(location) {
			let formFieldsets = [{
				fieldset_name: `Shipping Info`,
				fields: [
					optionsCarriers ?
						{field_type: 'select',
						field_name: 'shipping_carrier',
						field_label: 'Carrier',
						field_value: location.carrier_id,
						fieldOnChange: updateLocation,
						option_groups: [{options: optionsCarriers}],
						field_order: 1,
						field_required: true,
						field_validate: true,
						field_returnObject: true,
						}
					:
						{field_type: 'text',
						field_name: 'carrier_name',
						field_label: 'Carrier',
						field_value: null,
						fieldOnChange: updateLocation,
						field_order: 1,
						field_required: true,
						field_validate: true
						}
					,
					{field_type: 'text',
					field_name: 'shipping_account_number',
					field_label: 'Account Number',
					field_value: location.shipping_account_number,
					fieldOnChange: updateLocation,
					field_order: 2,
					field_required: false,
					field_validate: false
					},
					{field_type: 'text',
					field_name: 'shipping_tracking',
					field_label: 'Tracking Number',
					field_value: location.shipping_tracking,
					fieldOnChange: updateLocation,
					field_order: 3,
					field_required: true,
					field_validate: true
					},
					{field_type: 'number',
					field_name: 'shipping_num_boxes',
					field_label: 'Number of Boxes',
					field_value: location.shipping_num_boxes,
					fieldOnChange: updateLocation,
					field_order: 4,
					field_required: true,
					field_validate: true
					},
					{field_type: 'float',
					field_name: 'shipping_cost',
					field_label: 'Shipping Cost',
					field_value: location.shipping_cost,
					field_order: 5,
					fieldOnChange: updateLocation,
					field_required: false,
					field_validate: false
					},
					{field_type: 'hidden',
					field_name: 'process',
					field_value: location.shipping_id ? 'update_shipping' : 'add_shipping'
					},
					{field_type: 'hidden',
					field_name: 'user_id',
					field_value: userID
					},
					{field_type: 'hidden',
					field_name: 'token',
					field_value: token
					},
					{field_type: 'hidden',
					field_name: location.shipping_id ? 'shipping_id' : 'location_id',
					field_value: location.shipping_id ? location.shipping_id : location.location_id
					},
					{field_type: 'hidden',
					field_name: 'return_bool',
					field_value: true}
				]
			}];
			if(fieldsets !== formFieldsets) {
				setFieldsets(formFieldsets);	
			}
		}
	}, [optionsCarriers]);
	
	useEffect(() => {
		if(fieldsets) {
			const shpForm = <FormDynamic {...props}
			   					content={fieldsets}
			   					name="add-shipping"
			   					returnObject={true}
			   					fieldOnChange={null}
			   					formActions={{formCancel: handleRevert, formSubmit: handleComplete}}
			   					formCompleteLabel={location.shipping_id ? 'Update' : 'Add'}
			   					formCancelLabel={location.shipping_id ? "Cancel" : "Cancel"}
			   					disabled={locationChanged ? false : true}
			   					includeControls={locationChanged ? true : false} />
			
			if(form !== shpForm) {
				setForm(shpForm);
			}
		}
	}, [fieldsets, locationChanged]);
	
	// FUNCTIONS
	function updateLocation(params) {
		if(params.name === 'shipping_carrier') {
			params.name = 'carrier_id';
			params.value = parseInt(params.value);
		}
		
		if(location[params.name] !== params.value &&
			(((((params.value && params.value !== '') ||
				params.value >= 0) && 
				(!location[params.value]) || location[params.value] <= 0)) ||
				(location[params.value] || location[params.value] >= 0) &&
			   	(params.value < 0 || params.value === ''))
		) {
			setLocation({...location, [params.name]: params.value});
		}
	}
	
	function handleRevert() {
		props.setModal(null);
		// if(ctlLocation) {
		// 	setLocation(ctlLocation);
		// }else{
		// 	setLocation(null);
		// }
	}
	
	function handleComplete(params) {
		props.setModal(null);
		props[`${props.stateLocation ? props.stateLocation : 'dashboard'}LoadState`](
			params, 
			'orders', 
			`${props.resultLocation ? props.resultLocation : 'project'}`
		);
		if(props.onUpdate) {
			props.onUpdate();
		}
	}

	return (
		<div>
		{form}
		</div>
	)
	
}