import React, {useEffect, useState, Fragment} from 'react';

import HeaderIdentity from 'components/header/HeaderIdentity';
import HeaderNav from 'components/header/HeaderNav';

import IconToken from 'components/icons/IconToken';
import HeaderLanguage from 'components/header/HeaderLanguage';
import HeaderSearch from 'components/header/HeaderSearch';
import TokenUser from 'components/header/TokenUser';
import TokenCart from 'components/header/TokenCart';
import TokenDashboard from 'components/header/TokenDashboard';
import Button from 'components/forms/elements/Button';

import NavBtnMenu from 'components/navigation/NavBtnMenu';
import Menu from 'components/navigation/Menu';

import * as utils from 'methods/site.js';

export default (props) => {
  props = {...props, ...utils};
  
  const [logo, setLogo] = useState(null);
  const [isPublic, setIsPublic] = useState(null);
  const [orgName, setOrgName] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userID, setUserID] = useState(null);
  const [user, setUser] = useState(null);
  const [messages, setMessages] = useState(null);
  const [msgDate, setMsgDate] = useState(null);
  const [taskCount, setTaskCount] = useState(null);
  const [messageCount, setMessageCount] = useState(null);
  const [token, setToken] = useState(null);
  const [tokenRetry, setTokenRetry] = useState(null);
  const [menu, setMenu] = useState(false);
  const [modules, setModules] = useState(null);
  const [userModules, setUserModules] = useState(null);
  const [switchRequested, setSwitchRequested] = useState(null);
  const [userRequested, setUserRequested] = useState(null);  

  
  const menuID = 'site-menu';
  
  let ivlModules;
  
  // HOOKS
  useEffect(() => {
    if(props.objExists(props.site, 'persona')) {
      if(props.objExists(props.site, 'persona.media') && 
        Array.isArray(props.site.persona.media) &&
        props.site.persona.media.find((media)=>media.media_type === 'site_identity')) {
          const idMedia = props.site.persona.media.find((media)=>media.media_type === 'site_identity');
          setLogo({path: props.localizeURL(idMedia.media_file_path, 'https://aecore.app'), name: idMedia.media_file_name});
      }else{
        setLogo({path: 'https://aecore.app/assets/img/', name: 'ae-core-logo.svg'});
      }
    }
  }, [props.site.persona]);
  
  useEffect(() => {
    if(props.userID && props.userID !== userID) {
      setUserID(props.userID);
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.organizationID && props.organizationID !== organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    if(props.isPublic && props.isPublic !== isPublic) {
      setIsPublic(props.isPublic);
    }
  }, [props.isPublic]);
  
  useEffect(() => {
    if(props.languageCode && props.languageCode !== languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.token && props.token !== token) {
      setToken(props.token);
      if(tokenRetry) {
        setTokenRetry(false);
      }
    }else if(props.objExists(props.user, 'token')) {
      setToken(props.user.token);
    }else{
      if(sessionStorage.getItem('token') && sessionStorage.getItem('token') !== token) {
        setToken(sessionStorage.getItem('token'));
        if(tokenRetry) {
          setTokenRetry(false);
        }
      }else if(localStorage.getItem('token')  && localStorage.getItem('token') !== token){
        setToken(localStorage.getItem('token'));
        if(tokenRetry) {
          setTokenRetry(false);
        }
      }else{
        setTokenRetry(true);
      }
    }
  }, [props.token, userID, props.user]);
  
  // useEffect(() => {
  //   // if(props.objExists(props.user, 'user_id') && 
  //   //     props.user.user_id !== userID) {
  //   //   setUser(props.user);
  //   // }else if(userID && !props.objExists(props.user, 'user_id')){
  //   //   setUser(null);
  //   // }
  //   // 
  //   // if(props.objExists(props.user, 'token')) {
  //   //   setToken(props.user.token);
  //   // }
  // }, [props.user]);
  
  useEffect(() => {
    
    if(props.objExists(props.user, 'user_id') && 
        props.user.user_id !== userID) {
      setUser(props.user);
    }
    
    if(Array.isArray(props.objGetValue(props.user, `tasks`)) && props.user.tasks.length > 0) {
      const userTasks = props.user.tasks.filter(task => task.task_owner <= 0 || task.task_owner === props.user.user_id);
      if(userTasks && userTasks.length > 0) {
        setTaskCount(userTasks.length);
      }
    }
    
    if(Array.isArray(props.objGetValue(props.user, `messages`)) && props.user.messages.length > 0) {
      const userMessages = props.user.messages;
      setMessages(userMessages);
      if(userMessages && userMessages.length > 0) {
        setMessageCount(userMessages.length);
      }
    }
  }, [props.user]);
  
  useEffect(() => {
    if(Array.isArray(messages) && messages.length > 0) {
      const msgsByTime = messages.filter(msg => msg.message_read !== 1).sort((a, b) => {
        return props.convertISODate(a.message_created).getTime() > props.convertISODate(b.message_created).getTime() ? -1 : 1
      });
      
      if(msgDate) {
        const newMessages = messages.filter(msg => props.convertISODate(msg.message_created).getTime() > msgDate.getTime() && msg.message_read !== 1);
        
        if((userID === 1 || userID === 73) && Array.isArray(newMessages) && newMessages.length > 0) {
          props.notifyUser({icon: 'message', message: `You have ${newMessages.length} new message${newMessages.length === 1 ? '' : 's'}`});
          setMsgDate(new Date());
          //console.log("NEW MESSAGES", msgDate.getTime(), props.convertISODate(newMessages[0].message_created).getTime(), newMessages);
        }
      }
      
      if(Array.isArray(msgsByTime) && msgsByTime.length > 0 && 
        msgDate !== props.convertISODate(msgsByTime[0].message_created)) {
        setMsgDate(new Date());
      }
    
    }
    
    
  }, [messages, userID]);
  
  // LOAD MODULES IF USER OR IS PUBLIC
  useEffect(() => {
    if(organizationID && (isPublic || (userID && token))) {
      
      let moduleParams = [
        {name: 'process', value: 'build_site_modules'},
        {name: 'organization_id', value: organizationID}
      ];
      if(userID && token) {
        moduleParams.push(
          {name: 'user_id', value: userID},
          {name: 'token', value: token}
        );
      }
      if(!props.objExists(props.site, 'modules') || 
          props.objGetValue(props.site, 'reload.modules') === true) {
        props.siteLoadState(moduleParams, 'site', 'modules');
        props.objExists(props.site, 'reload.modules') &&
        props.siteRemoveValue('reload.modules');
        
        if(ivlModules) {
          clearTimeout(ivlModules);
        }
        
        ivlModules = setInterval(() => {
          if(document.visibilityState === 'hidden') {
            props.siteLoadState(moduleParams, 'site', 'modules');
          }
        }, props.minToMS(60));
      
      }
    }
  }, [userID, token, organizationID, isPublic, languageCode, props.site.reload, props.user]);
  
  useEffect(() => {
    if(props.objExists(props.site, 'modules') &&
        Array.isArray(props.site.modules)) {
      //console.log('TEST props.site.modules', props.site.modules);
      const storeModules = props.site.modules.find(module => module.module_component === 'Store');
      if(storeModules && (userID || isPublic)) {
        setModules(storeModules['modules']);  
      }
    }
  }, [props.site.modules, userID, isPublic]);
  
  // useEffect(() => {
  //   if(props.languageCode && props.languageCode !== languageCode) {
  //     setLanguageCode(props.languageCode);
  //   }else if(props.objExists(props.site, 'site_language') &&
  //           languageCode !== props.site.site_language) {
  //             setLanguageCode(props.site.site_language);
  //   }else{
  //     setLanguageCode('en');
  //   }
  // }, [props.languageCode]);
  
  useEffect(()=>{
    let itemTotal = 0;
    if(props.objExists(props.cart, 'items') && 
        Array.isArray(props.cart.items) &&
        props.cart.items.length > 0) {
        itemTotal += props.cart.items.length;  
    }
    
    if(props.objExists(props.cart, 'project.orders') && 
        Array.isArray(props.cart.project.orders) &&
        props.cart.project.orders.length > 0) {
        props.cart.project.orders.map(order => {
          if(order.items && 
              Array.isArray(order.items) &&
              order.items.length > 0) {
            itemTotal += order.items.length;
          }
          return true;
        });  
    }
    
    if(itemTotal > 0 && props.objGetValue(props.cart, 'cartCount') !== itemTotal) {
      props.cartSetValue('cartCount', itemTotal);
    }else if((!itemTotal || itemTotal === 0) &&  
              props.objGetValue(props.cart, 'cartCount') > 0){
      props.cartSetValue('cartCount', 0);
    }
    
    if(props.objExists(props.cart, 'items') &&
      Array.isArray(props.cart.items) &&
      props.cart.items.length > 0 &&
      props.cart.items !== JSON.parse(sessionStorage.getItem('coreCrt'))) {
        sessionStorage.setItem('coreCRT', JSON.stringify(props.cart.items));
    }else{
      if((!props.objExists(props.cart, 'items') ||
          !Array.isArray(props.cart.items) ||
        props.cart.items.length <= 0) &&
        sessionStorage.getItem('coreCRT')) {
        const crtSessTest = JSON.parse(sessionStorage.getItem('coreCRT'));
        if(Array.isArray(crtSessTest) && crtSessTest.length > 0) {
          props.cartSetValue('items', crtSessTest);
        }else{
          sessionStorage.removeItem('coreCRT');
        }
      }
    }
  }, [props.cart.items]);
  
  useEffect(() => {
    if(props.objExists(props.cart, 'project.orders[0].items[0]') &&
      props.cart.project !== JSON.parse(sessionStorage.getItem('corePrjct'))) {
        sessionStorage.setItem('corePrjct', JSON.stringify(props.cart.project));
    }else{
      if(!props.objExists(props.cart, 'project') &&
          sessionStorage.getItem('corePrjct')) {
        const prjctSessTest = JSON.parse(sessionStorage.getItem('corePrjct'));
        if(props.objExists(prjctSessTest, `orders[0].items[0]`)) {
          props.cartSetValue('project', prjctSessTest);
        }else{
          sessionStorage.removeItem('corePrjct');
        }
      }
    }
    
  }, [props.cart.project]);
  
  useEffect(() => {
    if(props.objExists(props.site, 'menuState')) {
      if(props.site.menuState.active) {
        setMenu(true);
      }
    }
  }, [props.site.menuState]);
  
  return (
    <header className="header-public">
      <HeaderIdentity organization_id={props.objExists(props.site, 'persona.organization_id') ? props.site.persona.organization_id : null}
                      organization_name={props.objExists(props.site, 'persona.organization_name') ? props.site.persona.organization_name : null}
                      site_logo={logo ? logo.path+logo.name : null}
                      linkTo={props.objExists(props.site, 'preferences.home_page') ? props.site.preferences.home_page : null} />
      <HeaderNav className={modules ? 'margin-right-1em' : ''}>
        
        {props.objExists(props.site, 'persona.languages') && 
          Array.isArray(props.site.persona.languages) &&
          props.site.persona.languages.length > 1 &&
            <HeaderLanguage {...props} languages={props.site.persona.languages} languageCode={languageCode} />
        }
        
        {userID && (props.objGetValue(props.site, 'preferences.show_search') === true) && 
          <HeaderSearch {...props} />
        }
        {userID && props.objGetValue(props.cart, 'cartCount') > 0  &&
          <TokenCart className="no-mobile" {...props} />
        }
        {userID && organizationID === 1 &&
          <TokenDashboard {...props}
            className="no-mobile"
            userID={userID}
            siteUser={user}
            taskCount={taskCount}
            messageCount={[1, 2, 73].includes(userID) && messageCount}
            token={token}
            tooltip="Your Dashboard" />
        }
        {userID && organizationID !== 1 &&
          <TokenUser {...props}
            className="no-mobile"
            userID={userID}
            siteUser={user}
            taskCount={organizationID > 1 ? taskCount : null}
            messageCount={organizationID > 1 ? messageCount : null}
            token={token} />
        }
      </HeaderNav>
      {modules &&
          <Fragment>
            <NavBtnMenu  {...props} 
                          icon="menu" 
                          className="icon-token"
                          active={menu}
                          action={()=>{setMenu(menu ? false : true)}} /> 
            <Menu {...props}
              active={menu}
              id={menuID}
              userID={userID}
              organizationID={organizationID}
              languageCode={languageCode}
              token={token}
              modules={modules} />
          </Fragment>
      }
    </header>
  )
}
