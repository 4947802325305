import React, {useEffect, useState} from 'react';

import IconToken from 'components/icons/IconToken';
import IconToggle from 'components/icons/IconToggle';
import IconSVG from 'components/icons/IconSVG';
import Link from 'components/navigation/Link';

export default (props) => {
	const [active, setActive] = useState(false);
	const [type, setType] = useState(false);
	const [languages, setLanguages] = useState(false);
	const [productCount, setProductCount] = useState(false);
	
	// HOOKS
	useEffect(() => {
		if(props.type && props.type !== type) {
			setType(props.type);
		}
	}, [props.type]);
	
	useEffect(() => {
		if(type) {
			let languageOptions = [];
			let typeProductCount = 0;
			if(Array.isArray(props.site.persona.languages) && props.site.persona.languages.length > 0) {
				props.site.persona.languages.map(lang => {
					if(!type[lang.language_code]) {
						languageOptions.push(
							{option_name: lang.language_name, option_value: lang.language_code}
						);	
					}
					if(type[lang.language_code]) {
						typeProductCount += type[lang.language_code].product_count;	
					}
				})
				setLanguages(languageOptions);
				setProductCount(typeProductCount);
			}else{
				setProductCount(type[props.languageCode].product_count);
			}
		}
	}, [props.site.persona, type]);
	
	// FUNCTIONS
	function handleAddLanguage() {
		if(type && languages && props.actionAddEdit) {
			props.actionAddEdit(type, languages);
		}
	}
	
	function handleEdit() {
		if(type && props.actionAddEdit) {
			props.actionAddEdit(type[props.languageCode]);
		}
	}
	
	function handleRemove() {
		if(type && props.actionRemove) {
			props.actionRemove(type);
		}
	}
	
	return (
		type &&
		<li className={`li${active ? ' active' : ''}`}>
			<div className={`list-item`}  style={{padding: '.5em 0em'}}>
				<div className={`list-item-content`}>
					<div className="list-item-content-block">
						<b>{`${!props.coreOrg && props.objExists(type, `organization_name`) ? `${props.capitalizeString(type.organization_name, true)} : ` : ''}${props.capitalizeString(type[props.languageCode].type_name, true)}`}</b>
					</div>
					<div className="list-item-content-block">
						{`${type[props.languageCode].product_count ? type[props.languageCode].product_count : 'No'} Product${type[props.languageCode].product_count !== 1 ? 's' : ''}`}
					</div>
				</div>
				{props.isAdmin &&
					<div className="li-ctl">
						{props.site.persona.languages && props.site.persona.languages.length > 0 &&
							<IconToken icon="add" 
								className="btn"
								disabled={languages.length > 0 ? false : true}
								tooltip="Add Language"
								action={languages.length > 0 ? handleAddLanguage : null} />	
						}
						<IconToken icon="edit" 
								className="btn"
								tooltip="Edit" 
								action={handleEdit} />
						<IconToken icon="trash"
								className="btn"
								disabled={productCount === 0 ? false : true}
								tooltip="Remove" 
								action={productCount === 0 ? handleRemove : null} />
					</div>
				}
			</div>
		</li>
	)
}