import React, {useEffect, useState} from 'react';

import LiveFieldInput from 'components/forms/elements/LiveFieldInput';
import * as utils from 'methods/site';
import CartSelectedProducts from 'components/cart/CartSelectedProducts';

export default (props) => {
  const [liveFields, setLiveFields] = useState(null);
  const [itemActive, setItemActive] = useState(false);

  useEffect(() => {
    if(props.itemActive && props.ItemActive !== itemActive) {
      setItemActive(props.itemActive);
    }else if(itemActive) {
      setItemActive(false);
    }
  }, [props.itemActive])
  useEffect(() => {
    if(props.fieldsets &&
      props.field_values &&
      Array.isArray(props.fieldsets) &&
      props.fieldsets.length > 0) {
        let liFields = [];

        props.fieldsets.map(fieldset => {
        if(fieldset.fields && Array.isArray(fieldset.fields)) {
          if(fieldset.fields.length > 0) {
            let liNameField = [];
            fieldset.fields.map((field, index) => {
              let liName = null;
              let liValue = null;
              let liType = null;
              let liLabel = null;
              let liOptionGroups = null;
              let liID = null
              if(field.field_name && field.field_name.match('name' || 'name_first' || 'name_last')) {
                liNameField.push({name: field.field_id ? field.field_id : field.field_name,
                                  value: props.field_values[field.field_id],
                                  type: field.field_type,
                                  label: field.field_label,
                                  option_groups: field.option_groups ? field.option_groups : null,
                                  id: field.field_id});
              }else{
                if (props.field_values[field.field_id] === true || props.field_values[field.field_id] === 'true') {
                  liValue = 'Yes';
                } else if (props.field_values[field.field_id] === false || props.field_values[field.field_id] === 'false') {
                  liValue = 'No';
                } else {
                  liValue = props.field_values[field.field_id];
                }
                liType = field.field_type;
                liLabel = field.field_label;
                liName = field.field_id ? field.field_id : field.field_name;
                liID = field.field_id;
                liOptionGroups = field.option_groups ? field.option_groups : null;
              }
              liType != 'hidden' && liValue &&
              liFields.push(<LiveFieldInput {...props}
                                            key={`cidlvi${index}`}
                                            value={liValue}
                                            label={liLabel}
                                            type={liType} 
                                            media={liType === 'file' &&
                                            props.objExists(props.cart, `media.${props.index}`) && 
                                            parseInt(liValue) > 0 ?
                                            props.cart.media[props.index] : null}
                                            id={liID}
                                            name={liName}
                                            location={props.location}
                                            action={updateItemValue}
                                            disabled={liType === 'file' ? true : liType === 'switch' ? true : false}
                                            option_groups={liOptionGroups} />);
            });
            liNameField.length > 0 &&
            liFields.push(<LiveFieldInput {...props}
                                          value={liNameField}
                                          label={'Name'}
                                          type={'combo'}
                                          name={'combo'}
                                          location={props.location}
                                          action={updateItemValue} />);
          }
        }
      });
      liFields.length > 0 &&
      setLiveFields(liFields);

    }
  }, [props.fieldsets, props.field_values, props.cart.media]);

  function fieldValues(values) {
    let valueSet = [];
    let nameSet = false;
    if(values.name_first && values.name_last) {
      valueSet.push(
        <div className="cart-item-custom-info-set">
          <label>Name</label>
          {`${values.name_first} ${values.name_last}`}
        </div>
      )
      nameSet = true;
    }
    for (let [name, value] of Object.entries(values)) {
        if(!nameSet || (nameSet && name !== 'name_first' && name !== 'name_last')) {
          const field = utils.getFieldsetFieldFromId(props.fieldsets, name);
          valueSet.push(<LiveFieldInput {...props}
                                        value={value}
                                        media={field.field_type === 'file' &&
                                                props.objExists(props.cart, `media.${props.index}`) && 
                                                parseInt(value) > 0 ?
                                                props.cart.media[props.index] : null}
                                        label={field.field_label}
                                        type={field.field_type}
                                        name={name}
                                        action={updateItemValue}
                                        type='text' />)
        }
    }
    return valueSet;
  }

  function updateItemValue(fieldName, fieldValue) {
    props.cartSetValue(`${props.location}.${fieldName}`, fieldValue)
  }
  
  return (
    <div className={`cart-item-details${itemActive ? ' active' : ''}`} ref={props.detailRef}>
      {liveFields && <div className="cart-item-custom-info-set">{liveFields}</div>}
      {props.objExists(props.item, 'selected_products') &&
        Array.isArray(props.item.selected_products) &&
        props.item.selected_products.length > 0 &&
        <CartSelectedProducts {...props} 
                              selectedProducts={props.item.selected_products}
                              products={props.item.details[props.item.language_code].products}
                              productLanguage={props.item.language_code}
                              location={props.location} />
      }
    </div>
  )
}
