import React, {useEffect, useState} from 'react';

import Input from 'components/forms/elements/Input';

export default (props) => {
	const [value, setValue] = useState(null);
	const [minQty, setMinQty] = useState(null);
	const [maxQty, setMaxQty] = useState(null);
	
	useEffect(() => {
		if(props.defaultValue && !value) {
			setValue(parseInt(props.defaultValue));
		}
	}, [props.defaultValue]);
	
	useEffect(() => {
		if(props.minQty && parseInt(props.minQty) !== minQty) {
			setMinQty(parseInt(props.minQty));
		}
	}, [props.minQty]);
	
	useEffect(() => {
		if(props.maxQty && 
			parseInt(props.maxQty) > 0 &&
			parseInt(props.maxQty) !== maxQty) {
			setMaxQty(parseInt(props.maxQty));
		}
	}, [props.maxQty]);
	
	// useEffect(() => {
	// 	if(minQty && minQty > value) {
	// 		setValue(minQty);
	// 	}
	// }, [value, minQty]);
	
	// FUNCTIONS
	function handleChange(e) {
		let qty = parseInt(e.value) > 0 ? parseInt(e.value) : 0;
		
		let qtyMsg = null;
		if(maxQty && qty > maxQty) {
			qtyMsg = `This exceeds the quantity limit of ${maxQty}`;
		}
		
		setValue(qty);
		if(props.actionOnChange) {
			props.actionOnChange(qty, qtyMsg);
		}
	}
	
	
	return (
		<Input {...props}
			onChange={handleChange}
			className={props.className}
			type="number"
			name={props.name}
			value={value}
			label={props.label}
			size={props.size}
			override={props.override}
			preventNull={props.preventNull}
			minValue={minQty}
			maxValue={maxQty}
			isDecimal={props.isDecimal}
		 />
	)
}