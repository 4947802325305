import React, {useEffect, useState, Fragment} from 'react';

import CartPrice from 'components/cart/CartPrice';
import CoreRule from 'components/atoms/CoreRule';

import * as utils from 'methods/site';

export default (props) => {
	const [languageCode, setLanguageCode] = useState(null);
	const [charges, setCharges] = useState(null);
	const [taxes, setTaxes] = useState(null);
	const [project, setProject] = useState(null);
	const [orders, setOrders] = useState(null);
	const [projectPricing, setProjectPricing] = useState(null);
	const [pricingLocation, setPricingLocation] = useState(null);
	const [pricingDisplay, setPricingDisplay] = useState(null);
	const [orderIndex, setOrderIndex] = useState(null);
	const [locationIndex, setLocationIndex] = useState(null);
	const [hidePricing, setHidePricing] = useState(null);
	const [displayPricing, setDisplayPricing] = useState(true);
	
	const labelUnavailable = "To Be Determined";
	
	useEffect(() => {
		if(props.languageCode && props.languageCode !== languageCode) {
		  setLanguageCode(props.languageCode);
		}else if(!languageCode) {
		  setLanguageCode('en');
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.site &&
			props.site.persona.charges !== charges) {
			setCharges(props.site.persona.charges);
		}
	}, [props.site]);
	
	useEffect(() => {
		if(props.site &&
			props.site.persona.taxes !== taxes) {
			setTaxes(props.site.persona.taxes);
		}
	}, [props.site]);
	
	useEffect(() => {
		if(props.project &&
			props.project !== project) {
			setProject(props.project);
		}
	}, [props.project]);
	
	useEffect(() => {
		if(Array.isArray(props.objGetValue(project, 'orders')) &&
			project.orders.length > 0 &&
			JSON.stringify(project.orders) !== JSON.stringify(orders)) {
				setOrders([...project.orders]);
		}
	}, [project]);
	
	useEffect(() => {
		if(Array.isArray(orders)) {
			let pPricing = [];
			let pSubtotalItems = 0;
			let pCharges = 0;
			let pChargesArr = [];
			let pAddCharges = 0;
			let pShipping = 0;
			let pTax = 0;
			let pTaxRates = {};
			let pSubtotal = 0;
			let pTotal = 0;
			let oLocations = [];
			let oPricing = [];
			let oSubtotalItems = 0;
			let oCharges = 0;
			let oChargesArr = [];
			let oShipping = 0;
			let oTax = 0;
			let oTaxRates = {};
			let oSubtotal = 0;
			let oTotal = 0;
			orders.map((order, index) => {
				if (Array.isArray(props.objGetValue(order, `shipping.locations`))) {
					oLocations = order.shipping.locations;
					oLocations.map((location, locIndex) => {
						let locPricing = {};
						let locSubtotalItems = 0;
						let locCharges = 0;
						let locChargesArr = [];
						let locShipping = 0;
						let locTax = 0;
						let locTaxRates = {};
						let locSubtotal = 0;
						let locTotal = 0;
						if(Array.isArray(order.items) && order.items.length > 0) {
							order.items.map(item => {
								if (props.objExists(item, 'location_quantities')) {
									Object.keys(item.location_quantities).map(locQtyIndex => {
										let itemPrice = 0;
										if(parseInt(locIndex) === parseInt(locQtyIndex)) {
											itemPrice = parseFloat(item.product_price) * (parseInt(item.location_quantities[locQtyIndex]) / parseInt(item.product_quantity));
											locSubtotalItems += itemPrice;
											oSubtotalItems += itemPrice;
										}
									})
								}
							});
						}
						if(Array.isArray(props.objGetValue(charges, `order`)) && charges.order.length > 0) {
							let locPackHandCharge = charges.order.find((charge) => { return charge.charge_name === 'Packaging/Handling'});
							locChargesArr.push(locPackHandCharge);
							locCharges += parseFloat(locPackHandCharge["charge_value"]);
							oCharges += parseFloat(locCharges);
							let locShippingCharge = charges.order.find((charge) => { return charge.charge_name === 'Shipping'});
							locShipping += parseFloat(locShippingCharge["charge_value"]);
							oShipping += parseFloat(locShipping);
						}
						locSubtotal = parseFloat(locSubtotalItems) + parseFloat(locCharges) + parseFloat(locShipping);
						let taxRates = 
							props.objExists(location, `location_country`) && 
							props.objExists(location, `location_province`) ?
								props.site.persona.taxes[location.location_country][location.location_province]
							: null;
						if(Array.isArray(taxRates) && taxRates.length > 0) {
							taxRates.map(tax => {
								let locTaxRate = parseFloat((locSubtotal * tax.tax_rate).toFixed(2));
								locTax += locTaxRate;
								let locTaxRateTotal = 0;
								if(props.objExists(locTaxRates, `${tax.tax_name}.tax_rate_total`)) {
									locTaxRateTotal = locTaxRates[`${tax.tax_name}`].tax_rate_total + locTaxRate;
								} else {
									locTaxRateTotal = locTaxRate;
								}
								locTaxRates[`${tax.tax_name}`] = {tax_id: tax.tax_id, tax_rate: tax.tax_rate, tax_rate_total: parseFloat(locTaxRateTotal.toFixed(2))}
								let oTaxRateTotal = 0;
								if(props.objExists(oTaxRates, `${tax.tax_name}.tax_rate_total`)) {
									oTaxRateTotal = oTaxRates[`${tax.tax_name}`].tax_rate_total + locTaxRate;
								} else {
									oTaxRateTotal = locTaxRate;
								}
								oTaxRates[`${tax.tax_name}`] = {tax_id: tax.tax_id, tax_rate: tax.tax_rate, tax_rate_total: parseFloat(oTaxRateTotal.toFixed(2))}
								let pTaxRateTotal = 0;
								if(props.objExists(pTaxRates, `${tax.tax_name}.tax_rate_total`)) {
									pTaxRateTotal = pTaxRates[`${tax.tax_name}`].tax_rate_total + locTaxRate;
								} else {
									pTaxRateTotal = locTaxRate;
								}
								pTaxRates[`${tax.tax_name}`] = {tax_id: tax.tax_id, tax_rate: tax.tax_rate, tax_rate_total: parseFloat(pTaxRateTotal.toFixed(2))}
							});
						}
						locTotal = parseFloat((locSubtotal + locTax).toFixed(2));
						oTax += locTax;
						oTotal += locTotal;
						locPricing["location_subtotal_items"] = parseFloat(locSubtotalItems.toFixed(2));
						locPricing["location_charges"] = parseFloat(locCharges.toFixed(2));
						locPricing["location_shipping"] = parseFloat(locShipping.toFixed(2));
						locPricing["location_subtotal"] = parseFloat(locSubtotal.toFixed(2));
						locPricing["location_tax"] = parseFloat(locTax.toFixed(2));
						locPricing["location_tax_rates"] = locTaxRates;
						locPricing["location_total"] = parseFloat(locTotal.toFixed(2));
						locPricing["charges"] = locChargesArr;
						oLocations[locIndex]["pricing"] = locPricing;
					});
				}
				if(Array.isArray(props.objGetValue(charges, `order`)) && charges.order.length > 0) {
					charges.order.map((charge, index) => {
						if(charge.charge_name != 'Packaging/Handling' && charge.charge_name != 'Shipping') {
							oCharges += parseFloat(charge.charge_value);
							oChargesArr.push(charge);
						}
					});
				}
				oSubtotal = parseFloat(oSubtotalItems) + parseFloat(oCharges) + parseFloat(oShipping);
				oPricing.push({
					order_id: order.order_id,
					order_subtotal_items: parseFloat(oSubtotalItems.toFixed(2)),
					order_charges: parseFloat(oCharges.toFixed(2)),
					order_shipping: parseFloat(oShipping.toFixed(2)),
					order_subtotal: parseFloat(oSubtotal.toFixed(2)),
					order_tax: parseFloat(oTax.toFixed(2)),
					order_tax_rates: oTaxRates,
					order_total: parseFloat(oTotal.toFixed(2)),
					locations: oLocations,
					charges: oChargesArr
				});
				pSubtotalItems += oSubtotalItems;
				pCharges += oCharges;
				pShipping += oShipping;
				pTax += oTax;
				pTotal += oTotal;
			})
			if(Array.isArray(props.objGetValue(charges, `project`)) && charges.project.length > 0) {
				charges.project.map((charge, index) => {
					pAddCharges += parseFloat(charge.charge_value);
					pChargesArr.push(charge);
				});
			}
			pSubtotal += parseFloat(pSubtotalItems) + parseFloat(pCharges) + parseFloat(pAddCharges) + parseFloat(pShipping);
			let addTaxRate = props.site.persona.taxes['CA']['ON'];
			if(Array.isArray(addTaxRate) && addTaxRate.length > 0) {
				addTaxRate.map(tax => {
					let pAddTax = parseFloat((pAddCharges * tax.tax_rate).toFixed(2));
					pTax += pAddTax;
					let pTaxRateTotal = 0;
					if(props.objExists(pTaxRates, `${tax.tax_name}.tax_rate_total`)) {
						pTaxRateTotal = pTaxRates[`${tax.tax_name}`].tax_rate_total + pAddTax;
					} else {
						pTaxRateTotal = pAddTax;
					}
					pTaxRates[`${tax.tax_name}`] = {tax_id: tax.tax_id, tax_rate: tax.tax_rate, tax_rate_total: parseFloat(pTaxRateTotal.toFixed(2))}
				});
			}
			pTotal = parseFloat(pSubtotal) + parseFloat(pTax);
			pPricing["project_id"] = project.project_id;
			pPricing["project_subtotal_items"] = parseFloat(pSubtotalItems.toFixed(2));
			pPricing["project_charges"] = parseFloat(pCharges.toFixed(2));
			pPricing["project_shipping"] = parseFloat(pShipping.toFixed(2));
			pPricing["project_charges_add"] = parseFloat(pAddCharges.toFixed(2));
			pPricing["project_subtotal"] = parseFloat(pSubtotal.toFixed(2));
			pPricing["project_tax"] = parseFloat(pTax.toFixed(2));
			pPricing["project_tax_rates"] = pTaxRates;
			pPricing["project_total"] = parseFloat(pTotal.toFixed(2));
			pPricing["orders"] = oPricing;
			pPricing["charges"] = pChargesArr;
			if(pPricing && pPricing !== projectPricing) {
				setProjectPricing(pPricing);
			}
		}
	}, [project, orders]);
	
	useEffect(() => {
		if(props.pricingLocation && props.pricingLocation !== pricingLocation) {
			setPricingLocation(props.pricingLocation);
		}
	}, [props.pricingLocation]);
	
	useEffect(() => {
		if(props.orderIndex >= 0 && props.orderIndex !== orderIndex) {
			setOrderIndex(props.orderIndex);
		}
	}, [props.orderIndex]);
	
	useEffect(() => {
		if(props.locationIndex >= 0 && props.locationIndex !== locationIndex) {
			setLocationIndex(props.locationIndex);
		}
	}, [props.locationIndex]);
	
	useEffect(() => {
		if (projectPricing) {
			let pDisplay = [];
			if (pricingLocation === 'project') {
				pDisplay["displayItemsSubtotal"] = projectPricing.project_subtotal_items;
				pDisplay["displayChargesLabel"] = 'Order Charges';
				pDisplay["displayCharges"] = projectPricing.project_charges;
				pDisplay["displayChargesArr"] = projectPricing.charges;
				let chargesArrTotal = 0;
				if (projectPricing.charges.length > 0) {
					projectPricing.charges.map((charge, index) => {
					  chargesArrTotal += parseFloat(charge.charge_value)
					})	
				}
				pDisplay["displayChargesArrTotal"] = chargesArrTotal;
				pDisplay["displayShipping"] = projectPricing.project_shipping;
				pDisplay["displaySubtotal"] = projectPricing.project_subtotal;
				pDisplay["displayTax"] = projectPricing.project_tax;
				pDisplay["displayTaxRates"] = projectPricing.project_tax_rates;
				pDisplay["displayTotal"] = projectPricing.project_total;
			} else if (pricingLocation === 'order' && parseInt(orderIndex) >= 0) {
				pDisplay["displayItemsSubtotal"] = projectPricing.orders[orderIndex].order_subtotal_items;
				pDisplay["displayChargesLabel"] = 'Location Charges';
				pDisplay["displayCharges"] = projectPricing.orders[orderIndex].order_charges;
				pDisplay["displayChargesArr"] = projectPricing.orders[orderIndex].charges;
				let chargesArrTotal = 0;
				if (projectPricing.orders[orderIndex].charges.length > 0) {
					projectPricing.orders[orderIndex].charges.map((charge, index) => {
					  chargesArrTotal += parseFloat(charge.charge_value)
					})	
				}
				pDisplay["displayChargesArrTotal"] = chargesArrTotal;
				pDisplay["displayShipping"] = projectPricing.orders[orderIndex].order_shipping;
				pDisplay["displaySubtotal"] = projectPricing.orders[orderIndex].order_subtotal;
				pDisplay["displayTax"] = projectPricing.orders[orderIndex].order_tax;
				pDisplay["displayTaxRates"] = projectPricing.orders[orderIndex].order_tax_rates;
				pDisplay["displayTotal"] = projectPricing.orders[orderIndex].order_total;
				if (parseFloat(props.objGetValue(projectPricing.orders[orderIndex], `order_total`)) === parseFloat(props.objGetValue(projectPricing, `project_total`))) {
					setDisplayPricing(false);
			  	}
			} else if (pricingLocation === 'location' && parseInt(orderIndex) >= 0  && parseInt(locationIndex) >= 0) {
				pDisplay["displayItemsSubtotal"] = projectPricing.orders[parseInt(orderIndex)].locations[parseInt(locationIndex)].pricing.location_subtotal_items;
				pDisplay["displayCharges"] = projectPricing.orders[orderIndex].locations[locationIndex].pricing.location_charges;
				pDisplay["displayChargesArr"] = projectPricing.orders[orderIndex].locations[locationIndex].pricing.charges;
				let chargesArrTotal = 0;
				if (projectPricing.orders[orderIndex].locations[locationIndex].pricing.charges.length > 0) {
					projectPricing.orders[orderIndex].locations[locationIndex].pricing.charges.map((charge, index) => {
					  chargesArrTotal += parseFloat(charge.charge_value)
					})	
				}
				pDisplay["displayChargesArrTotal"] = chargesArrTotal;
				pDisplay["displayShipping"] = projectPricing.orders[orderIndex].locations[locationIndex].pricing.location_shipping;
				pDisplay["displaySubtotal"] = projectPricing.orders[orderIndex].locations[locationIndex].pricing.location_subtotal;
				pDisplay["displayTax"] = projectPricing.orders[orderIndex].locations[locationIndex].pricing.location_tax;
				pDisplay["displayTaxRates"] = projectPricing.orders[orderIndex].locations[locationIndex].pricing.location_tax_rates;
				pDisplay["displayTotal"] = projectPricing.orders[orderIndex].locations[locationIndex].pricing.location_total;
				if (parseFloat(props.objGetValue(projectPricing.orders[orderIndex].locations[locationIndex].pricing, `location_total`)) === parseFloat(props.objGetValue(projectPricing, `project_total`))) {
					setDisplayPricing(false);
			  	}
			}
			setPricingDisplay(pDisplay);
		}
	}, [projectPricing, pricingLocation, orderIndex, locationIndex]);
	
	useEffect(() => {
		if (projectPricing) {
			if (props.objGetValue(project, 'billing.charges') !== projectPricing.charges) {
				props.cartSetValue(`project.billing.charges`, projectPricing.charges);
			}
			if (props.objGetValue(project, 'billing.project_subtotal_items') !== projectPricing.project_subtotal_items) {
				props.cartSetValue(`project.billing.project_subtotal_items`, projectPricing.project_subtotal_items);
			}
			if (props.objGetValue(project, 'billing.project_charges') !== projectPricing.project_charges + projectPricing.project_charges_add) {
				props.cartSetValue(`project.billing.project_charges`, projectPricing.project_charges + projectPricing.project_charges_add);
			}
			if (props.objGetValue(project, 'billing.project_shipping') !== projectPricing.project_shipping) {
				props.cartSetValue(`project.billing.project_shipping`, projectPricing.project_shipping);
			}
			if (props.objGetValue(project, 'billing.project_subtotal') !== projectPricing.project_subtotal) {
				props.cartSetValue(`project.billing.project_subtotal`, projectPricing.project_subtotal);
			}
			if (props.objGetValue(project, 'billing.project_tax') !== projectPricing.project_tax) {
				props.cartSetValue(`project.billing.project_tax`, projectPricing.project_tax);
			}
			if (props.objGetValue(project, 'billing.project_tax_rates') !== projectPricing.project_tax_rates) {
				props.cartSetValue(`project.billing.project_tax_rates`, projectPricing.project_tax_rates);
			}
			if (props.objGetValue(project, 'billing.project_total') !== projectPricing.project_total) {
				props.cartSetValue(`project.billing.project_total`, projectPricing.project_total);
			}
			if (Array.isArray(project.orders) && project.orders.length > 0) {
				project.orders.map((order, index) => {
					if (props.objGetValue(project, `orders[${index}].billing.charges`) !== projectPricing.orders[index].charges) {
						props.cartSetValue(`project.orders[${index}].billing.charges`, projectPricing.orders[index].charges);
					}
					if (props.objGetValue(project, `orders[${index}].billing.order_subtotal_items`) !== projectPricing.orders[index].order_subtotal_items) {
						props.cartSetValue(`project.orders[${index}].billing.order_subtotal_items`, projectPricing.orders[index].order_subtotal_items);
					}
					if (props.objGetValue(project, `orders[${index}].billing.order_charges`) !== projectPricing.orders[index].order_charges) {
						props.cartSetValue(`project.orders[${index}].billing.order_charges`, projectPricing.orders[index].order_charges);
					}
					if (props.objGetValue(project, `orders[${index}].billing.order_shipping`) !== projectPricing.orders[index].order_shipping) {
						props.cartSetValue(`project.orders[${index}].billing.order_shipping`, projectPricing.orders[index].order_shipping);
					}
					if (props.objGetValue(project, `orders[${index}].billing.order_subtotal`) !== projectPricing.orders[index].order_subtotal) {
						props.cartSetValue(`project.orders[${index}].billing.order_subtotal`, projectPricing.orders[index].order_subtotal);
					}
					if (props.objGetValue(project, `orders[${index}].billing.order_tax`) !== projectPricing.orders[index].order_tax) {
						props.cartSetValue(`project.orders[${index}].billing.order_tax`, projectPricing.orders[index].order_tax);
					}
					if (props.objGetValue(project, `orders[${index}].billing.order_tax_rates`) !== projectPricing.orders[index].order_tax_rates) {
						props.cartSetValue(`project.orders[${index}].billing.order_tax_rates`, projectPricing.orders[index].order_tax_rates);
					}
					if (props.objGetValue(project, `orders[${index}].billing.order_total`) !== projectPricing.orders[index].order_total) {
						props.cartSetValue(`project.orders[${index}].billing.order_total`, projectPricing.orders[index].order_total);
					}
				});
			}
		}
	}, [projectPricing]);
	
	useEffect(() => {
		if(props.hidePricing && hidePricing !== props.hidePricing) {
			setHidePricing(props.hidePricing);
		}
	}, [props.hidePricing]);
	
	return (
		!hidePricing && displayPricing && pricingDisplay && parseFloat(pricingDisplay.displayTotal) > 0 && 
			<div className="truncate card-content">
				<div className="cart-box cart-totals" style={{marginBottom: pricingLocation === 'location' ? '0em' : '1em'}}>
					{parseFloat(pricingDisplay.displayItemsSubtotal) !== parseFloat(pricingDisplay.displaySubtotal) &&
						<Fragment>
							<div className="cart-totals-set">
								<div className="grid col-2 cells">
									<div className="column"><label>Items Subtotal</label></div>
									<div className="column">
									  <div className="flex align-right cart-price-value">{pricingDisplay ? props.priceDisplay(pricingDisplay.displayItemsSubtotal, languageCode) : labelUnavailable}</div>
									</div>
								</div>
								{
									parseFloat(pricingDisplay.displayCharges) !== parseFloat(pricingDisplay.displayChargesArrTotal) && (
										<div className="grid col-2 cells">
											<div className="column"><label>{`${pricingDisplay.displayChargesLabel ? pricingDisplay.displayChargesLabel : 'Charges'}`}</label></div>
											<div className="column">
											  <div className="flex align-right cart-price-value">{pricingDisplay ? props.priceDisplay(pricingDisplay.displayCharges, languageCode) : labelUnavailable}</div>
											</div>
										</div>
									)
								}
								{
									pricingDisplay && Array.isArray(pricingDisplay.displayChargesArr) && pricingDisplay.displayChargesArr.length > 0 &&
									  pricingDisplay.displayChargesArr.map((charge, index) => {
										return (
										  <div key={`prjctPrcngDspChrg${index}`} className="grid col-2 cells">
											<div className="column"><label>{`${charge.charge_name}`}</label></div>
											<div className="column">
											  <div className="flex align-right cart-price-value">{charge.charge_value ? props.priceDisplay(charge.charge_value, languageCode) : labelUnavailable}</div>
											</div>
										  </div>
										)
									})
								}
								<div className="grid col-2 cells">
									<div className="column"><label>Shipping</label></div>
									<div className="column">
									  <div className="flex align-right cart-price-value">{pricingDisplay ? props.priceDisplay(pricingDisplay.displayShipping, languageCode) : labelUnavailable}</div>
									</div>
								</div>
							</div>
							<CoreRule vertical={true} />
						</Fragment>
					}	
					<div className="cart-totals-set">
						<div className="grid col-2 cells">
							<div className="column"><label>Subtotal</label></div>
							<div className="column">
							  <div className="flex align-right cart-price-value">{pricingDisplay ? props.priceDisplay(pricingDisplay.displaySubtotal, languageCode) : labelUnavailable}</div>
							</div>
						</div>
						{pricingDisplay &&
							Object.keys(pricingDisplay.displayTaxRates).map((item, index) => {
							  const taxRate = Intl.NumberFormat("en-GB", {style: "percent",minimumFractionDigits: 0, maximumFractionDigits: 2}).format(pricingDisplay.displayTaxRates[item].tax_rate);
							  return (
								<div key={`prjctPrcngDspTxRt${index}`} className="grid col-2 cells">
								  <div className="column"><label>{`${item} (${taxRate})`}</label></div>
								  <div className="column">
									<div className="flex align-right cart-price-value">{pricingDisplay.displayTaxRates[item].tax_rate_total ? props.priceDisplay(pricingDisplay.displayTaxRates[item].tax_rate_total, languageCode) : labelUnavailable}</div>
								  </div>
								</div>
							  )
							})
						}
						<div className="grid col-2 cells">
							<div className="column"><label>Total</label></div>
							<div className="column">
							  <div className="flex align-right cart-price-value">{pricingDisplay ? props.priceDisplay(pricingDisplay.displayTotal, languageCode) : labelUnavailable}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
	)
}