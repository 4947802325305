import React from 'react';

import CardValueTag from 'components/molecules/CardValueTag';

export default (props) => {
	
	function removeSubProducts(params) {
		if (props.actionRemove) {
			props.actionRemove(params);
		}
	}

	return (
		<div>
			<div className="card-set">
				<div className="card-set-block">
					<div className="card-row">
						{props.subCategories && Array.isArray(props.subCategories) && props.subCategories.length && 
							props.subCategories.map((category, index) => {
								return <CardValueTag
									key={`crdVlTg${index}`}
									content={`${category.category_parent_name ? `${props.capitalizeString(category.category_parent_name, true)} > ` : ''}${props.capitalizeString(category.category_name)}`}
									contentType="category"
									contentID={category.category_id}
									editable={props.editable}
									actionRemove={removeSubProducts} />
							})
						}
					</div>
				</div>
			</div>
		</div>
	)
}
