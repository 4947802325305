import React, {useEffect, useState} from 'react';

import CategoryBlock from 'components/categories/CategoryBlock';

export default (props) => {

  const [languageCode, setLanguageCode] = useState(null);
  const [categories, setCategories] = useState(null);
  
  // HOOKS
  useEffect(() => {
    if(props.languageCode && props.languageCode !== languageCode) {
		setLanguageCode(props.languageCode)
	}
  }, [props.languageCode]);
  
  useEffect(() => {
	if(props.categories && props.categories !== categories) {
		setCategories(props.categories)
	}
  }, [props.categories]);

  return (
	<div>
	  {categories && Array.isArray(categories) && categories.length > 0 ?
		  <div className={`grid blocks${categories.length > 7 ? ' sml' : ''}`}>
			{categories.map((category, index) => {
			  return (
				<CategoryBlock {...props}
					key={`cb${index}`}
					languageCode={languageCode}
					category={category}
					link={`/category/${category.category_id}`} /> 
			  )
			})}
		  </div>
		:
		  <div className="margin-top-1em" style={{fontSize: '1.25em'}}>Sorry, there are no categories for {props.objExists(props.site, 'persona.organization_name') ? props.site.persona.organization_name : 'your organization' }{props.objExists(props.site, 'persona.languages') && props.site.persona.languages.length > 0 && ' in the language selected'}.</div>
	  }
	</div>
  )
}
