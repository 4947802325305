import React, {useEffect, useState, Fragment} from 'react';

import Control from 'components/atoms/Control';
import FormShippingAddEdit from 'components/forms/common_forms/FormShippingAddEdit';
import ShippingBlock from 'components/profiles/shipping/ShippingBlock';


export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [task, setTask] = useState(null);
	const [project, setProject] = useState(null);
	const [order, setOrder] = useState(null);
	const [orderLocations, setOrderLocations] = useState(null);
	const [shippingCarriers, setShippingCarriers] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.userID &&
			props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token &&
			props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode &&
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.task &&
			props.task !== task) {
			setTask(props.task);
		}
	}, [props.task]);
	
	useEffect(() => {
		if(props.project && 
			props.project !== project) {
			setProject(props.project);
		}
	}, [props.project])
	
	useEffect(() => {
		if(props.order &&
			props.order !== order) {
			setOrder(props.order);
		}
	}, [props.order]);
	
	useEffect(() => {
		if(props.shippingCarriers &&
			props.shippingCarriers !== shippingCarriers) {
			setShippingCarriers(props.shippingCarriers);
		}
	}, [props.shippingCarriers]);
	
	useEffect(() => {
		if(Array.isArray(props.objGetValue(order, `shipping.locations`)) &&
			order.shipping.locations !== orderLocations) {
			setOrderLocations(order.shipping.locations);
		}
	}, [order]);
	
	// FUNCTIONS
	function loadShipping(id, location) {
		props.setModal(
			<FormShippingAddEdit {...props}
				userID={userID}
				token={token}
				languageCode={languageCode}
				location={location}
				shippingCarriers={shippingCarriers}
				stateLocation={`user`}
				resultLocation={`reload.task.project`} />
		)
	}
	
	return (
		order &&
		<div className="card">
			{Array.isArray(order.shipping.locations) &&
			order.shipping.locations.map(loc => {
				return (
					<div className="block-border" key={`tskOrdrShppngLctn${loc.location_id}`}>
							<div className="card-block">
								<div className="grid-col-2">
									<div className="grid-col" style={{backgroundColor: 'rgba(51, 51, 51, 0.04)', marginRight: '0.5em'}}>
										<div style={{padding: '1em'}}>
											<div className="subtext">Ship to:</div>
											{loc.location_name &&
											<div>
												<b>{loc.location_name}</b>
											</div>
											}
											<div>{`${loc.location_address_1}${
												loc.location_address_2 ? 
													` - ${loc.location_address_2}` :
													''
											}`}</div>
											<div>
												{`${loc.location_city}, ${loc.location_province}`}
											</div>
											<div>
												{`${loc.location_country}  ${loc.location_postal_code.toUpperCase()}`}
											</div>
											<div className="subtext" style={{marginTop: '1em'}}>Attn:</div>
											<div>
												<b>{`${loc.contact_name_first} ${loc.contact_name_last}`}</b>
											</div>
											<div>
												{loc.contact_phone}
											</div>
											<div>
												<a href={`mailto:${loc.contact_email}`} target="_blank">
													{loc.contact_email}
												</a>
											</div>
										</div>
									</div>
									<div className="grid-col" style={{backgroundColor: 'rgba(51, 51, 51, 0.04)', marginLeft: '0.5em'}}>
										<div style={{padding: '1em'}}>
											{(loc.carrier_name || loc.shipping_tracking) &&
												<ShippingBlock {...props} location={loc} />
											}
											<div>
												<Control 
													icon="shipping"
													action={()=>{loadShipping(loc.location_id, loc)}}
													label={(loc.carrier_id || loc.shipping_tracking || loc.shipping_started) ? 		'Edit Shipping Info' : 'Add Shipping Info'}
													showLabel={true}
													className="ctl-btn info-bg"
													iconClass="no-btn" />
											</div>
										</div>
									</div>
								</div>
							</div>
							
							<div className="card-block" style={{marginTop: '1em'}}>
								<h4>Items</h4>
								
								<div className="grid" style={{gridTemplateColumns: 'auto 1fr auto auto'}}>
								<div className="grid-header">Qty</div>
								<div className="grid-header">Item</div>
								<div className="grid-header">Code</div>
								<div className="grid-header">SKU</div>
								{Object.entries(loc.items).length > 0 &&
								Object.entries(loc.items).map(lqty => {
									const item = order.items.find(oItem => oItem.item_id === parseInt(lqty[0]));
									return (
										<Fragment key={`tskOrdrItm${lqty[0]}`}>
											<div className="column">
												{lqty[1]}
											</div>
											<div className="column">
												{`${props.capitalizeString(item.product_name, true)}${
													item.product_nickname ? ` (${props.capitalizeString(item.product_nickname, true)})` : ''
												}`}
											</div>
											<div className="column">
												{item.details[languageCode].product_code}
											</div>
											<div className="column">
												{item.details[languageCode].product_sku}
											</div>
										</Fragment>
									)
								})}
							</div>
						</div>
					</div> // BORDER BLOCK END
				)
			})
		// LOCATIONS LOOP ENDS	
		} 
	
	</div>
	)
}