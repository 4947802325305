import React, {useEffect, useState, Fragment} from 'react';

export default (props) => {
	
	return (
		<div className="card-block" >
			{props.objExists(props.location, 'carrier_name') &&
			<div>Shipped via: <b>{props.location.carrier_name}</b></div>
			}
			{props.objExists(props.location, 'shipping_account_number') &&
			<div>Account #: <b>{props.location.shipping_account_number}</b></div>
			}
			{props.objExists(props.location, 'shipping_tracking') &&
			<div>Tracking #: <b>{props.location.shipping_tracking}</b></div>
			}
			{props.objExists(props.location, 'shipping_num_boxes') &&
			<div>Boxes: <b>{props.location.shipping_num_boxes}</b></div>
			}
			{props.objExists(props.location, 'shipping_cost') &&
			<div>Charge: <b>{props.priceDisplay(props.location.shipping_cost, 'en')}</b></div>
			}
			{props.objExists(props.location, 'shipping_started') &&
			<div>Shipping Date: <b>{props.dateDisplay(props.location.shipping_started)}</b></div>
			}
			{props.objExists(props.location, 'shipping_ended') &&
			<div>Delivery Date: <b>{props.dateDisplay(props.location.shipping_ended)}</b></div>
			}
		</div>
	)
}