import React, {useEffect, useState, Fragment} from 'react';

import IconSVG from 'components/icons/IconSVG';
import IconToken from 'components/icons/IconToken';
import Switch from 'components/forms/elements/Switch';
import FormPaymentOptionAddEdit from 'components/forms/common_forms/FormPaymentOptionAddEdit';
import ModalConfirmation from 'components/molecules/ModalConfirmation';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [userAccess, setUserAccess] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
		  setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
		  setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.organizationID && organizationID !== props.organizationID) {
		  setOrganizationID(props.organizationID);
		}
	}, [props.organizationID]);
	
	useEffect(() => {
		if(props.languageCode && languageCode !== props.languageCode) {
		  setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.userAccess && props.userAccess !== userAccess) {
		  setUserAccess(props.userAccess);
		} 
	}, [props.userAccess]);
	
	// FUNCTIONS
	function loadEditOption() {
		props.setModal(
			<FormPaymentOptionAddEdit {...props}
				userID={userID}
				token={token}
				organizationID={organizationID}
				languageCode={languageCode}
				userAccess={userAccess}
				payment={props.payment}
				actionCancel={()=>{props.setModal(null)}}
				actionAddEdit={editPaymentOption} />
		)
	}
	
	function editPaymentOption(params) {
		props.setModal(null);
		props.adminLoadState(params, 'site', 'reload.paymentOptions');
	}
	
	function loadRemoveOption() {
		props.setModal(
			<ModalConfirmation {...props}
				message={`Are you sure you want to remove the <b>${props.payment.option_name}</b> payment option?`}
				actionAccept={removeOption} />
		)
	}
	
	function removeOption() {
		props.setModal(null);
		const params = [
			{name: 'process', value: 'update_payment_option'},
			{name: 'user_id', value: userID},
			{name: 'token', value: token},
			{name: 'option_id', value: props.payment.option_id},
			{name: 'option_removed', value: true},
		];
		props.adminLoadState(params, 'site', 'reload.paymentOptions');
	}
	
	return (
		props.payment &&
		<Fragment>
			<div className={`text-center icon`}>
				<IconToken icon={`${props.payment.option_public ? 'eye' : 'hide'}`} />
			</div>
			<div className={``}>
				{props.capitalizeString(props.payment.option_name, true)}
			</div>
			<div className={``}>
				{props.capitalizeString(props.payment.option_component, true)}
			</div>
			<div className={``}>
				{props.payment.option_description}
			</div>
			<div className={``}>
				<div className="ctl">
					<IconToken className="btn" icon="edit" tooltip="Edit" action={loadEditOption} />
					<IconToken className="btn" icon="trash" tooltip="Remove" action={loadRemoveOption} />
				</div>
			</div>
		</Fragment>
	)
}