import React, {useEffect, useState} from 'react';
import {useParams, useLocation, useHistory} from 'react-router';

import * as utils from 'methods/site';

import AdminComBoard from 'components/admin/AdminComBoard';
import AdminProducts from 'components/admin/products/AdminProducts';
import AdminOrgs from 'components/admin/orgs/AdminOrgs';
import AdminUsers from 'components/admin/users/AdminUsers';
import AdminOrders from 'components/admin/orders/AdminOrders';
import AdminCore from 'components/admin/core/AdminCore';
import AdminMain from 'components/admin/AdminMain';
import AdminNav from 'components/admin/AdminNav';

export default (props) => {
  props = {...props.children, ...utils};
  const [adminComponent, setComponent] = useState(null);
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [canEdit, setCanEdit] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [coreOrg, setCoreOrg] = useState(null);
  const [pathNodes, setPathNodes] = useState(null);
  const [menuOptions, setMenuOptions] = useState(null);
  const [modules, setModules] = useState(null)
  const [currentOptions, setCurrentOptions] = useState(null);

  const pgParams = useParams();
  const location = useLocation();
  const pgHistory = useHistory();
  
  let ivlUsers;
  let ivlStaff
  let ivlGroupsUser;
  let ivlGroupsStaff;
  let ivlOrgs;
  
  // HOOKS
  useEffect(() => {
    return() => {
      // props.adminDeleteVal('organizations');
      props.adminDeleteVal('organization');
      // props.adminDeleteVal('products');
      props.adminDeleteVal('product');
      // props.adminDeleteVal('users');
      props.adminDeleteVal('user');
      
      ivlUsers &&
      clearInterval(ivlUsers);
      ivlStaff &&
      clearInterval(ivlStaff)
      ivlGroupsUser &&
      clearInterval(ivlGroupsUser);
      ivlGroupsStaff &&
      clearInterval(ivlGroupsStaff);
    }
  }, []);
  
  useEffect(() => {
    if(props.objExists(pgHistory, `location.search`)) {
      const sParams = props.getURLSearchParams(pgHistory.location.search);
      if(sParams.org && !coreOrg) {
        setCoreOrg(sParams.org);
      }
    }
  }, [pgHistory]);

  useEffect(() => {
    if(props.objExists(props.site, 'modules') &&
        Array.isArray(props.site.modules) &&
        props.site.modules.length > 0) {
      const modulesAdmin = props.site.modules.find(mod => mod.module_component === 'Admin');
      if(modulesAdmin && 
          modulesAdmin.modules &&
          Array.isArray(modulesAdmin.modules) &&
          modulesAdmin.modules.length > 0) {
        setModules(modulesAdmin);
      }
    }
  }, [props.site.modules]);
  
  // SETTING USER ID - received from parent page (PageInternal)
  useEffect(() => {
    if(props.userID && props.userID !== userID) {
      setUserID(props.userID);
    }
  }, [props.userID]);
  
  // SETTING TOKEN - received from parent page (PageInternal)
  useEffect(() => {
    if(props.token && props.token !== token) {
      setToken(props.token)
    }
  }, [props.token]);
  
  // SET LANGUAGE CODE - received from parent page (PageInternal)
  useEffect(() => {
    if(props.languageCode && props.languageCode !== languageCode) {
      setLanguageCode(props.languageCode)
    }
  }, [props.languageCode]);
  // useEffect(() => {
  //   if(props.languageCode && languageCode !== props.languageCode) {
  //     setLanguageCode(props.languageCode);
  //     if(props.objGetValue(props.site, 'langauge_code') !== props.languageCode) {
  //       props.siteSetValue(props.languageCode);
  //     }
  //   }else if(props.objExists(props.site, 'language_code') && 
  //             languageCode !== props.site.langauge_code) {
  //       setLanguageCode(props.site.language_code);        
  //   }else if(!props.objExists(props.site, 'language_code') &&
  //             !props.languageCode && !languageCode) {
  //       setLanguageCode('en');
  //       props.siteSetValue('language_code', 'en'); 
  //   }
  // }, [props.languageCode, props.site.language_code]);
  
  // SET ORGANIZATION ID - received from parent page (PageInternal)
  useEffect(() => {
    if(props.objExists(props.site, `persona.organization_id`) &&
      props.site.persona.organization_id !== organizationID) {
      setOrganizationID(props.site.persona.organization_id);
    }
    
    if(props.objExists(props.site, `persona.organization_name`) &&
      props.site.persona.organization_name !== organizationName) {
      setOrganizationName(props.site.persona.organization_name);
    }
    
  }, [props.site.persona]);
  
  useEffect(() => {
    if(organizationID > 1) {
      coreOrg !== organizationID &&
      setCoreOrg(organizationID);
    }
  }, [organizationID]);
  
  // SETTING USER ACCESS
  useEffect(() => {
    if(organizationID && props.user) {
      
      let uAccess = {};
      let staffAdmin = {};
      let roleAdmin = {};
      let inventoryControl = {};
      
      // SUPER ADMIN & ADMIN ACCESS
      if(Object.entries(props.objGetValue(props.user, `user_roles`)).length > 0) {
        Object.entries(props.user.user_roles).map(role => {
          if(parseInt(role[0]) === 1 && parseInt(role[1].role_id) === 1) {
            uAccess['super'] = true;
          } else if(parseInt(role[0]) === organizationID && parseInt(role[1].role_id) <= 2) {
            uAccess['admin'] = true;
          }
          if(role[1].role_id <= 2) {
            roleAdmin[role[0]] = true;
          }
        })
      }
      
      // USER GROUPS ACCESS
      if(props.objExists(props.user, `user_groups`) && Array.isArray(props.user.user_groups)) {
          props.user.user_groups.map(group => {
            // STAFF ADMIN ACCESS - if user is a Core staff admin
            if(group.group_name.toLowerCase() === 'staff admin') {
              staffAdmin[group.group_organization_id] = true;
            // INVENTORY CONTROL
            }else if(group.group_name.toLowerCase() === 'inventory control') {
              inventoryControl[group.group_organization_id] = true;
            }
          });
      }
      
      // const adminByOrgs = {...roleAdmin, ...staffAdmin};
      // if(Object.entries(adminByOrgs).length > 0 && uAccess.staffAdmin !== adminByOrgs) {
      //   uAccess['staffAdmin'] = adminByOrgs;
      // }
      
      uAccess['staffAdmin'] = staffAdmin;
      uAccess['inventory'] = inventoryControl;
      
      if(Object.entries(uAccess).length > 0 && uAccess !== userAccess) {
          setUserAccess(uAccess);
      }
      
    }
  }, [props.user, organizationID]);
  
  useEffect(() => {
    if(userID && token && 
      (organizationID === 1 ||
        props.objGetValue(userAccess, `admin`) || 
        props.objGetValue(userAccess, 'super'))) {
      let staffTimeout;
      const staffParams = [
        {name: 'process', value: 'get_users'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'organization_id', value: 1},
        {name: 'include_staff', value: true},
        {name: 'default_only', value: true},
        {name: 'org_only', value: true}
      ]
      if(!props.objExists(props.admin, 'staff.users') || 
          props.objGetValue(props.admin, 'reload.staff') === true) {
        props.adminLoadState(staffParams, 'users', 'staff.users'); 
        
        if(ivlUsers) {
          clearInterval(ivlUsers);
        }
        
        ivlUsers = setInterval(() => {
          if(document.visibilityState === 'visible') {
            props.adminLoadState(staffParams, 'users', 'staff.users');
          }
        }, props.minToMS(60));  
      }
    }
  }, [userID, token, userAccess, props.admin.staff, props.admin.reload]);
  
  useEffect(() => {
    if(userID && token && organizationID &&
      (organizationID == 1 || props.objGetValue(userAccess, `staffAdmin.${organizationID}`) || 
        props.objGetValue(userAccess, 'admin') ||
        props.objGetValue(userAccess, 'super'))) {
      
      const userParams = [
        {name: 'process', value: 'get_users'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'organization_id', value: coreOrg ? coreOrg : organizationID},
        {name: 'default_only', value: true},
        {name: 'include_staff', value: false},
        {name: 'org_only', value: false}
      ];
  
      if(!props.objExists(props.admin, 'users') || 
          props.objGetValue(props.admin, 'reload.users') === true ||
          ((organizationID > 1 || coreOrg > 1) && 
            Array.isArray(props.admin.users) &&
            props.admin.users.filter(usr => 
                  usr.organization_id === organizationID > 1 ? 
                    organizationID : coreOrg).length <= 0)) {
        props.adminLoadState(userParams, 'users', 'users');
        
        if(props.objGetValue(props.admin, 'reload.users') === true) {
          props.adminDeleteVal(`reload.users`);
        }
        
        if(ivlUsers) {
          clearInterval(ivlUsers);
        }
        
        ivlUsers = setInterval(()=> {
          if(document.visibilityState === 'visible') {
            props.adminLoadState(userParams, 'users', 'users');
          }
        }, props.minToMS(60));
      }
    }
  }, [userID, token, userAccess, coreOrg, organizationID, props.admin.users, props.admin.reload]);
  
  useEffect(() => {
    if(userID && token && coreOrg && 
        (props.objGetValue(userAccess, `staffAdmin.${coreOrg}`) || 
          props.objGetValue(userAccess, 'admin') ||
          props.objGetValue(userAccess, 'super'))) {
      
      const groupsParams = [
        {name: 'process', value: 'get_groups'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'organization_id', value: coreOrg},
        {name: 'group_type', value: 'user'}
      ];
      
      if(!props.objExists(props.admin, `groups.user`) ||
          (Array.isArray(props.objGetValue(props.admin, `groups.user`)) &&
            props.admin.groups.user.filter(
              group => group.organization_id === coreOrg
            ).length <= 0) ||
            props.objGetValue(props.admin, `reload.groups.user`) === true) {
        // props.adminDeleteVal('groups.user');
        props.adminLoadState(groupsParams, 'groups', 'groups.user');
        // props.adminSetVal('groups.loading.user', true);
        
        if(props.objGetValue(props.admin, 'reload.groups.user')) {
          props.adminDeleteVal('reload.groups.user');
        }
        
        if(ivlGroupsUser) {
          clearInterval(ivlGroupsUser);
        }
        
        ivlGroupsUser = setInterval(()=> {
          if(document.visibilityState === 'visible') {
            props.adminLoadState(groupsParams, 'groups', 'groups.user');
          }
        }, props.minToMS(30));
      }
    }
  }, [userID, token, userAccess, coreOrg, props.admin.groups, props.admin.reload]);
  
  useEffect(() => {
    if(userID && token && 
      (props.objGetValue(userAccess, 'admin') || 
        props.objGetValue(userAccess, 'super'))) {
      let sGroupTimeout;
      const sGroupsParams = [
        {name: 'process', value: 'get_org_groups_list'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'organization_id', value: 1},
        {name: 'group_type', value: 'user'}
      ]
      
      if(!props.objExists(props.admin, 'staff.groups') || 
          props.objGetValue(props.admin, 'reload.staff') === true) {
        props.adminLoadState(sGroupsParams, 'groups', 'staff.groups'); 
        
        if(ivlGroupsStaff) {
          clearInterval(ivlGroupsStaff);
        }
        
        ivlGroupsStaff = setInterval(() => {
          if(document.visibilityState === 'visible') {
            props.adminLoadState(sGroupsParams, 'groups', 'staff.groups'); 
          }
        }, props.minToMS(60));
      }
    }
  }, [userID, token, userAccess, props.admin.staff, props.admin.reload]);
  
  useEffect(() => {
    if(props.objGetValue(props.admin, 'groups.loading.user') === true &&
        props.objExists(props.admin, 'groups.user')) {
          props.adminDeleteVal('groups.loading.user');
    }
  }, [props.admin.groups]);
  
  useEffect(() => {
    if(userID && token && organizationID === 1 && languageCode) {
      const orgParams = [
        {name: 'process', value: 'get_organizations'},
      ]
      
      if(!props.admin.organizations || props.objGetValue(props.admin, 'reload.organizations') === true) {
        props.adminLoadState(orgParams, 'organizations', 'organizations');
        if(props.objGetValue(props.admin, 'reload.organizations') === true) {
          props.adminDeleteVal('reload.organizations');
        }
        
        if(ivlOrgs) {
          clearInterval(ivlOrgs);
        }
        
        ivlOrgs = setInterval(() => {
          if(document.visibilityState === 'visible') {
            props.adminLoadState(orgParams, 'organizations', 'organizations');
          }
        }, props.minToMS(60));
      }
    }
  }, [userID, token, organizationID, languageCode, props.admin.organizations, props.admin.reload]);
  
  useEffect(() => {
    if(pgParams.section) {
      switch(pgParams.section) {
        case 'products':
          setComponent(<AdminProducts {...props}
                                      userID={userID}
                                      token={token}
                                      canEdit={canEdit}
                                      isSuper={isSuper}
                                      userAccess={userAccess}
                                      organizationID={organizationID}
                                      organizationName={organizationName}
                                      languageCode={languageCode}
                                      coreOrg={coreOrg}
                                      pathNodes={pathNodes} 
                                      pgParams={pgParams}
                                      actionUpdateCoreOrg={selectCoreOrg} />);
          return;
        case 'organizations':
          setComponent(<AdminOrgs {...props}
                                  userID={userID}
                                  token={token}
                                  canEdit={canEdit}
                                  isSuper={isSuper}
                                  userAccess={userAccess}
                                  organizationID={organizationID}
                                  organizationName={organizationName}
                                  languageCode={languageCode}
                                  coreOrg={coreOrg}
                                  pathNodes={pathNodes}
                                  pgParams={pgParams}
                                  actionUpdateCoreOrg={selectCoreOrg} />);
          return;
        case 'users':
          setComponent(<AdminUsers {...props}
                                  userID={userID}
                                  token={token}
                                  canEdit={canEdit}
                                  isSuper={isSuper}
                                  userAccess={userAccess}
                                  organizationID={organizationID}
                                  organizationName={organizationName}
                                  languageCode={languageCode}
                                  coreOrg={coreOrg}
                                  pathNodes={pathNodes} 
                                  pgParams={pgParams}
                                  actionUpdateCoreOrg={selectCoreOrg} />);
          return;
        case 'orders':
          setComponent(<AdminOrders {...props}
                                  userID={userID}
                                  token={token}
                                  canEdit={canEdit}
                                  isSuper={isSuper}
                                  userAccess={userAccess}
                                  organizationID={organizationID}
                                  organizationName={organizationName}
                                  coreOrg={coreOrg}
                                  languageCode={languageCode}
                                  pathNodes={pathNodes} 
                                  pgParams={pgParams}
                                  actionUpdateCoreOrg={selectCoreOrg} />);
          return;
        case 'core':
        setComponent(<AdminCore {...props}
                                userID={userID}
                                token={token}
                                canEdit={canEdit}
                                isSuper={isSuper}
                                userAccess={userAccess}
                                organizationID={organizationID}
                                organizationName={organizationName}
                                languageCode={languageCode}
                                coreOrg={coreOrg}
                                pathNodes={pathNodes} 
                                pgParams={pgParams} />);
        return;
        default:
          setComponent(<AdminMain {...props}
                                  userID={userID}
                                  token={token}
                                  canEdit={canEdit}
                                  isSuper={isSuper}
                                  userAccess={userAccess}
                                  organizationID={organizationID}
                                  organizationName={organizationName}
                                  languageCode={languageCode}
                                  coreOrg={coreOrg}
                                  pathNodes={pathNodes} 
                                  pgParams={pgParams}
                                  actionUpdateCoreOrg={selectCoreOrg} />);
          return;
      }
    }
  }, [pgParams, props.admin, userID, token, languageCode, organizationID, coreOrg, userAccess, canEdit, isSuper, props.files,]);

  useEffect(() => {
    if(utils.objExists(props.admin, 'product') &&
        typeof props.admin.product === 'object' &&
        Object.entries(props.admin.product).length > 0) {
      sessionStorage.setItem('admPrd', JSON.stringify(props.admin.product));
    }
  }, [props.admin]);

  useEffect(() => {
    if(props.admin && !utils.objExists(props.admin, 'product') &&
          sessionStorage.getItem('admPrd') &&
          !sessionStorage.getItem('admPrd').length <= 0 &&
          sessionStorage.getItem('admPrd') !== 'undefined') {
      props.adminLoadFromSession('product', JSON.parse(sessionStorage.getItem('admPrd')));
    }
  }, [props.admin.product]);

  useEffect(() => {
    if(props.admin && !utils.objExists(props.admin, 'organization') &&
          sessionStorage.getItem('admOrg') &&
          !sessionStorage.getItem('admOrg').length <= 0 &&
          sessionStorage.getItem('admOrg') !== 'undefined') {
      props.adminLoadFromSession('organization', JSON.parse(sessionStorage.getItem('admOrg')));
    }
  }, [props.admin.organization]);
  
  // FUNCTIONS
  function selectCoreOrg(org) {
    if(organizationID === 1) {
      if(parseInt(org) > 0 && 
          parseInt(org) !== coreOrg) {
        setCoreOrg(parseInt(org));
      }else if((isNaN(org) || parseInt(org) <= 0) && coreOrg) {
        setCoreOrg(null);
      }  
    }
  }
 
  return (
    <div className="admin">
      <AdminComBoard {...props} {...utils} />
      <div className="admin-interface">
        {modules &&
          <AdminNav  {...props}
                        modules={modules}
                        pgParams={pgParams}
                        languageCode={languageCode}  />
        }
        <div className="admin-component">
          {adminComponent}
        </div>
      </div>
    </div>
  )
}
