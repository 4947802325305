import React, {useEffect, useState, Fragment} from 'react';
import {useParams, useLocation} from 'react-router';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import Loader from 'components/atoms/Loader';
import Card from 'components/atoms/Card';
import Control from 'components/atoms/Control';
import IconToken from 'components/icons/IconToken';
import IconSVG from 'components/icons/IconSVG';
import MediaFile from 'components/profiles/media/MediaFile';
import MediaToken from 'components/profiles/media/MediaToken';
import MediaFileSpotlight from 'components/profiles/media/MediaFileSpotlight';
import TaskInterfaceFiles from 'components/profiles/tasks/TaskInterfaceFiles';
import TaskPractical from 'components/profiles/tasks/TaskPractical';
import TaskApproval from 'components/profiles/tasks/TaskApproval';
import TaskToDo from 'components/profiles/tasks/TaskToDo';
import TaskListAdmin from 'components/profiles/tasks/TaskListAdmin';
import TaskProject from 'components/profiles/tasks/TaskProject';
import TaskOrder from 'components/profiles/tasks/TaskOrder';
import Approval from 'components/profiles/approvals/Approval';
import PDFViewer from 'components/pdf/PDFViewer';


export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [task, setTask] = useState(null);
	const [subtasks, setSubtasks] = useState(null);
	const [subtasksComplete, setSubtasksComplete] = useState(null);
	const [taskLoading, setTaskLoading] = useState(false);
	const [item, setItem] = useState(null);
	const [itemApprovals, setItemApprovals] = useState(null);
	const [shell, setShell] = useState(null);
	const [itemLoading, setItemLoading] = useState(false);
	const [taskName, setTaskName] = useState(null);
	const [taskStatus, setTaskStatus] = useState(null);
	const [taskOwnerType, setTaskOwnerType] = useState(null);
	const [taskOwnerID, setTaskOwnerID] = useState(null);
	const [taskRootType, setTaskRootType] = useState(null);
	const [taskRootID, setTaskRootID] = useState(null);
	const [taskAccess, setTaskAccess] = useState(null);
	const [processUpdating, setProcessUpdating] = useState(false);
	const [itemName, setItemName] = useState(null);
	const [orderNumber, setOrderNumber] = useState(null);
	const [projectNumber, setProjectNumber] = useState(null);
	const [project, setProject] = useState(null);
	const [order, setOrder] = useState(null);
	const [orderLocations, setOrderLocations] = useState(null);
	const [projectLoading, setProjectLoading] = useState(null);
	const [customFields, setCustomFields] = useState(null);
	const [subProducts, setSubProducts] = useState(null);
	const [subProductsActive, setSubProductsActive] = useState(null);
	const [currentProof, setCurrentProof] = useState(null);
	const [shippingCarriers, setShippingCarriers] = useState(null);
	const [activeShipping, setActiveShipping] = useState(null);
	const [odLink, setODLink] = useState(null);
	const [odPreview, setODPreview] = useState(null);
	
	
	
	// HOOKS 
	useEffect(() => {
		if(props.userID && 
			props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && 
			props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languageCode && 
			props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.task && 
			props.task !== task) {
			setTask(props.task);
		}
	}, [props.task]);
	
	
	
	useEffect(() => {
		if(task && languageCode) {
			let isItem = false;
			let isOrder = false;
			let isProject = false;
			
			if(props.objExists(task, `status[0].${languageCode}.status_name`) && 
				languageCode) {
				const curStatus = task.status[0][languageCode].status_name;
				
				if(curStatus === 'new') {
					setTaskStatus('Pending');
				}else if (curStatus && 
						(!taskStatus || 
						taskStatus.toLowerCase() !== curStatus.toLowerCase())){
					setTaskStatus(props.capitalizeString(curStatus, true));
				}
			}
			
			if(task.item_id) {
				isItem = true;
				setTaskOwnerType('taskToItem');
				setTaskOwnerID(task.item_id);
			}else if(task.order_id) {
				isOrder = true;
				setTaskOwnerType('taskToOrder');
				setTaskOwnerID(task.order_id);
			}else if(task.project_id) {
				isProject = true;
				setTaskOwnerType('taskToProject');
				setTaskOwnerID(task.project_id);
			}
			
			if(task.project_id) {
				setTaskRootType('taskToProject');
				setTaskRootID(task.project_id);
			}
			
			if(task.users && userID) {
				let tAccess = {};
				if(task.users.linked && 
					Array.isArray(task.users.linked) &&
					task.users.linked.find(user => user.user_id === userID)) {
					tAccess['linked'] = true;
				}
				if(task.users.owner && task.users.owner.user_id === userID) {
					tAccess['owner'] = true;
				}
				
				if(task.users.groups && 
					Array.isArray(props.objGetValue(props.user, 'user_groups'))) {
					props.user.user_groups.map(group => {
						if(task.users.groups.find(tGroup => tGroup.group_id === group.group_id)) {
							tAccess['group'] = true;
						}
					})
				}
				
				if(Object.entries(tAccess).length > 0 && 
					tAccess !== taskAccess) {
					setTaskAccess(tAccess);	
				}
 			}
			 
			if(task && languageCode) {
				let name = props.objGetValue(task, `${languageCode}.task_name`);
				if(name ==='client review' && isItem) {
					name = `creative review`;
				}else if(name === 'client review' && !isItem && !isOrder && isProject) {
					name = 'order review';
				}
				
				setTaskName(name);
			}
			
			if(props.objGetValue(task, 'project_id') && 
				props.objGetValue(task, 'organization_code')) {
				setProjectNumber(props.projectNumDisplay(task.project_id, task.organization_code));
			}
			
			if(props.objGetValue(task, 'project_id') && 
				props.objGetValue(task, 'organization_code') &&
				props.objGetValue(task, 'order_id')) {
				setOrderNumber(props.orderNumDisplay(task.project_id, task.order_id, task.organization_code));
			}
		}
		
		if(Array.isArray(props.objGetValue(task, 'subtasks')) && 
			task.subtasks !== subtasks) {
			setSubtasks(task.subtasks.sort((a, b) => {
				return !a.task_completed && !b.task_completed &&
						a.task_created > b.task_created ? -1 :
						!a.task_completed && !b.task_completed &&
						a.task_created < b.task_created ? 1 :
						a.task_completed && b.task_completed &&
						a.task_created < b.task_created ? -1 :
						a.task_completed && b.task_completed &&
						a.task_created > b.task_created ? 1 :
						!a.task_completed && b.task_completed ? -1 : 1
			}));
		}
	}, [task, languageCode, userID, props.user]);
	
	useEffect(() => {
		if(Array.isArray(subtasks)) {
			let stComplete = true;
			subtasks.map(sTask => {
				if(!sTask.task_completed) {
					stComplete = false;
				}
			});
			setSubtasksComplete(stComplete);
		}
	}, [subtasks]);
	
	useEffect(() => {
		if(props.item && 
			props.item !== item) {
			setItem(props.item);
		}
	}, [props.item]);
	
	useEffect(() => {
		if(props.project && 
			props.project !== project) {
			setProject(props.project);
		}
	}, [props.project]);
	
	useEffect(() => {
		if(props.objExists(task, 'order_id')  && 
			Array.isArray(props.objGetValue(project, `orders`))) {
			const taskOrder = project.orders.find(order => order.order_id === task.order_id);
			if(taskOrder && taskOrder !== order) {
				setOrder(taskOrder);
			} 	
		}
	}, [task, project]);
	
	useEffect(() => {
		if(props.shippingCarriers &&
			props.shippingCarriers !== shippingCarriers) {
			setShippingCarriers(props.shippingCarriers);
		}
	}, [props.shippingCarriers]);
	
	useEffect(() => {
		if(Array.isArray(props.objGetValue(order, `shipping.locations`)) &&
			order.shipping.locations !== orderLocations) {
			setOrderLocations(order.shipping.locations);
		}
	}, [order]);
	
	useEffect(() => {
		if(props.taskLoading && !taskLoading) {
			setTaskLoading(true);
		}else if(taskLoading) {
			setTaskLoading(false);
		}
	}, [props.taskLoading]);
	
	useEffect(() => {
		if(props.itemLoading && !itemLoading) {
			setItemLoading(true);
		}else if(itemLoading) {
			setItemLoading(false);
		}
	}, [props.itemLoading]);
	
	useEffect(() => {
		if(props.projectLoading && !projectLoading) {
			setProjectLoading(true);
		}else if(projectLoading) {
			setProjectLoading(false);
		}
	}, [props.projectLoading]);
	
	
	
	useEffect(() => {
		if(item) {
			let cFields = [];
			
			if(languageCode &&
				Array.isArray(props.objGetValue(item, 'fields'))) {
				
				
				item.fields.map((field, index) => {
					cFields.push(field);			
				});
			}
			
			if(cFields.length > 0) {
				cFields.sort((a, b)=> {
					return (
						a.field_type === 'file' &&
						   a.field_type !== b.field_type ?
						  1 : -1 )});
				setCustomFields(cFields);
			}
			
			if(languageCode) {
				setItemName(props.objGetValue(item, `product.details.${languageCode}.product_name`));
			}
			
			if(Array.isArray(props.objGetValue(item, `selected_products`)) &&
				item.selected_products.length > 0 && 
				subProducts !== item.selected_products) {
				setSubProducts(item.selected_products);
			}
			
			if(Array.isArray(props.objGetValue(item, `approvals`)) &&
				item.approvals.length > 0 &&
				item.approvals !== itemApprovals) {
				setItemApprovals(item.approvals);
			}
			
			if(props.objGetValue(item, `shell.shell_published`) === 1 &&
				item.shell !== shell) {
				setShell(item.shell);
			}
		}
	}, [item, languageCode]);
	
	useEffect(() => {
		if (userID && token && order && item && shell) {
			
			let odl;
			let odp;
			
			if (currentProof) {
				odl = `https://api.aecore.app/files/download.php?file=${currentProof.media_id ? currentProof.media_id : 0}`;
				odp = `https://api.aecore.app/files/load_file.php?file=${currentProof.media_id ? currentProof.media_id : 0}`;
			} else {
				odl = `https://api.aecore.app/files/create_pdf.php?query=${btoa(`?process=output_shell_pdf&user_id=${userID}&token=${token}&item_id=${item.item_id ? item.item_id : 0}&language_code=${item.language_code ? item.language_code : 'en'}&box_model=BleedBox&target=D&order_id=${order.order_id ? order.order_id  : 0}&project_id=${project.project_id ? project.project_id  : 0}&preview_mode=false`)}`;
				odp = `https://api.aecore.app/files/create_pdf.php?query=${btoa(`?process=output_shell_pdf&user_id=${userID}&token=${token}&item_id=${item.item_id ? item.item_id : 0}&language_code=${item.language_code ? item.language_code : 'en'}&box_model=TrimBox&target=I&order_id=${order.order_id ? order.order_id  : 0}&project_id=${project.project_id ? project.project_id  : 0}&preview_mode=false`)}`;
			}
			
			if (odl !== odLink) {
				setODLink(odl);
			}
			
			if (odp !== odPreview) {
				setODPreview(odp);
			}
		  
		}
	}, [userID, token, item, shell, languageCode]);
	
	useEffect(() => {
		if(props.objExists(item, `files.proofs[0]`) &&
			currentProof !== item.files.proofs[0]) {
			setCurrentProof(item.files.proofs[0]);
		}
	}, [item]);
	
	// FUNCTIONS 
	function taskUpdate(taskID, status) {
		if(languageCode && task && Array.isArray(task.status)) {
			let newStatus = task.status;
			
			switch(status) {
				case 'start':
				case 'resume':
					status = "in progress";
					break;
				default:
					break;
			}
			
			newStatus.unshift({[languageCode]: {status_name: status}});
			setTask({...task, 
						status: newStatus, 
						task_completed: 
							['complete', 'approved', 'declined'].includes(status) ? 
							true : false});	
		}else{
			setTaskStatus(status);
		}
	}
	
	function completeToDo(taskID, status) {
		if(languageCode && task && Array.isArray(task.subtasks)) {
			let sTasks = task.subtasks;
			subtasks.map((stask, index) => {
				if(stask.task_id === taskID) {
					let staskStatus = stask.status;
					staskStatus.unshift({[languageCode]: {status_name: 'complete'}});
					
					sTasks[index] = {...stask, task_completed: true, status: staskStatus};
					setTask({...task, subtasks: sTasks});
					setSubtasks(sTasks);
				}
			})
		}
	}
	
	function previewShell() {
		props.setModal(
			<div style={{width: '90vw'}}>
				<PDFViewer {...props}
					file={odPreview}
					stealthCtl={true} />
			</div>
		, false, true);
	  }
	
	return (
		<div className="task-interface">
			<div className="flex margin-bottom-2em align-top">
				<div className="task-ctl">
					<div className="task-ctl-info">
						<b>Status:</b>{` ${taskStatus}`}
					</div>
					{task && task.type_name === 'practical' &&
						<TaskPractical {...props}
							userID={userID}
							token={token}
							organizationID={task.organization_id}
							languageCode={languageCode}
							task={task}
							location={props.stateLocation ? props.stateLocation : 'dashboard'}
							isAdmin={true}
							source={'task'}
							task={task}
							taskOwnerType={taskOwnerType}
							taskOwnerID={taskOwnerID}
							taskRootType={taskRootType}
							taskRootID={taskRootID}
							onUpdate={setProcessUpdating}
							taskAccess={taskAccess}
							actionUpdate={taskUpdate}
							subtasksComplete={subtasksComplete}
							proofs={props.objGetValue(item, `files.proofs`)}
							shippingCarriers={shippingCarriers}
							orderLocations={props.objGetValue(order, `shipping.locations`)}
						 	/>
					}
					
					{task && task.type_name === 'approval' &&
						<TaskApproval {...props}
							userID={userID}
							token={token}
							organizationID={task.organization_id}
							languageCode={languageCode}
							task={task}
							location={props.stateLocation ? props.stateLocation : 'dashboard'}
							isAdmin={true}
							source={'task'}
							// userAccess={userAccess}
							// canEdit={canEdit}
							// isSuper={isSuper}
							task={task}
							taskOwnerType={taskOwnerType}
							taskOwnerID={taskOwnerID}
							taskRootType={taskRootType}
							taskRootID={taskRootID}
							onUpdate={setProcessUpdating}
							taskAccess={taskAccess}
							actionUpdate={taskUpdate}
						 	/>
					}
				</div>
			
				{currentProof &&
				taskName === 'creative review'
				&& item &&
				<MediaFileSpotlight {...props}
					key={`mftPrf0`}
					userID={userID}
					token={token}
					languageCode={languageCode}
					file={currentProof}
					fileMediaType='proof'
					fileOwner='item'
					fileOwnerID={item ? item.item_id : null}
					isHero={true}
					title={`Current Proof${itemName ? ` : ${props.capitalizeString(itemName, true)}` : ''}${item && item.item_nickname ? ` (${item.item_nickname})` : ''}`}
					canEdit={false}
					actionRemove={null} />
				}
			</div>
			
			{task && 
				task.type_name === 'practical' && 
				Array.isArray(subtasks) && 
				<div className="card">
					<h3>{`${props.capitalizeString(taskName, true)} To-do${subtasks.length > 1 ? 's' : ''}`}</h3>
					<div className="card-block list">
					{subtasks.map(subtask => {
						return (
							<TaskToDo {...props}
								userID={userID}
								token={token}
								languageCode={languageCode}
								task={subtask}
								taskParent={task}
								location={props.stateLocation ? props.stateLocation : 'dashboard'}
								stateLocation="task"
								isAdmin={true}
								source={'task'}
								taskAccess={taskAccess}
								taskOwnerType={taskOwnerType}
								taskOwnerID={taskOwnerID}
								taskRootType={taskRootType}
								taskRootID={taskRootID}
								actionUpdate={completeToDo} />
						);
					})}
					</div>
				</div>
			}
			
			{/* <div className="card">
				{props.taskLoading && 
					<Loader label="Loading task..." overlayContents={true} />
				}
				{task && task.type_name &&
					<h3>{props.capitalizeString(`${taskName ? taskName : 'Task'}${itemName ? ` :: ${itemName}` : ''}${item && item.item_nickname ? ` (${item.item_nickname})` : ''}`, true)}</h3>
				}
				
				{props.objExists(project, 'organization_name') &&
					<div className="card-row">
						<b>Client: </b>
						 {`${project.organization_name}`}
					</div>
				}
				{projectNumber &&
					<div className="card-row"><b>Project: </b> {projectNumber}</div>
				}
				{orderNumber &&
					<div className="card-row"><b>Order: </b> {orderNumber}</div>
				}
				{props.objExists(project, 'user.contact_name_first') &&
					<div className="card-row">
						<b>Ordered By: </b>
						 {`${project.user.contact_name_first} ${project.user.contact_name_last}`}
					</div>
				}
				{props.objExists(project, 'project_created') &&
					<div className="card-row">
						<b>Order Date: </b>
						 {props.dateDisplay(project.project_created)} {props.timeDisplay(project.project_created, false)}
					</div>
				}
			</div> */}
			
			<Card>
				{props.taskLoading && 
					<Loader label="Loading task..." overlayContents={true} />
				}
				<div className="card-block">
					<div className="card-columns">
						<div className="card-block">
							<div className="title">{props.capitalizeString(`${taskName ? taskName : 'Task'}${itemName ? ` :: ${itemName}` : ''}${item && item.item_nickname ? ` (${item.item_nickname})` : ''}`, true)}</div>
						</div>
					</div>
					<div className="card-columns">
						<div className="card-block">
							{props.objExists(project, 'organization_name') && 
								<div className="field">
									<label>Client</label>
									{project.organization_name}
								</div>
							}
							{projectNumber &&
								<div className="field">
									<label>Project N<sup><u>O</u></sup></label>
									{projectNumber}
								</div>
							}
							{orderNumber &&
								<div className="field">
									<label>Order N<sup><u>O</u></sup></label>
									{orderNumber}
								</div>
							}
							{!project && 
								<div className="field">
									<label>Project Status</label>
									Removed
								</div>
							}
						</div>
						<div className="card-block">
							{props.objExists(project, 'user.contact_name_first') &&
								<div className="field">
									<label>Ordered By</label>
									 {`${project.user.contact_name_first} ${project.user.contact_name_last}`}
								</div>
							}
							{props.objExists(project, 'project_created') &&
								<div className="field">
									<label>Order Date: </label>
									 {props.dateDisplay(project.project_created)} {props.timeDisplay(project.project_created, false)}
								</div>
							}
						</div>
					</div>
				</div>
			</Card>
			
			{task && task.type_name === 'admin' && 
				Array.isArray(task.subtasks) &&
				task.subtasks.length > 0 &&
				<div className="card">
					<h3>Tasks</h3>
					<TaskListAdmin {...props}
						userID={userID}
						token={token}
						languageCode={languageCode}
						task={task}
						tasks={subtasks}
						location={props.stateLocation ? props.stateLocation : 'dashboard'}
						stateLocation="task"
						isAdmin={true}
						source={'task'}
						taskAccess={taskAccess}
						taskOwnerType={taskOwnerType}
						taskOwnerID={taskOwnerID}
						taskRootType={taskRootType}
						taskRootID={taskRootID} />
				</div>
			
			}
			
			{task && project && ['client review'].includes(task[languageCode].task_name) &&
				<TaskProject {...props}
					userID={userID}
					token={token}
					languageCode={languageCode}
					task={task}
					project={project} />
			}
			
			{task && project && ['packaging', 'shipping', 'provide costs'].includes(task[languageCode].task_name) &&
				<TaskOrder {...props}
					userID={userID}
					token={token}
					languageCode={languageCode}
					task={task}
					location={props.stateLocation ? props.stateLocation : 'dashboard'}
					stateLocation="task"
					isAdmin={true}
					project={project}
					order={order}
					shippingCarriers={shippingCarriers} />
			}
			
			{item &&
				<div className="card">
					{props.itemLoading &&
						<Loader label="Loading item..." overlayContents={true}/>
					}
					{languageCode && 
					props.objExists(item, `product.details.${languageCode}.product_name`) &&
						<h3>{props.capitalizeString(item.product.details[languageCode].product_name)}</h3>
					}
					
					{props.objExists(item, 'item_quantity') &&
						<div className="card-row">
							<b>Quantity: </b>
						 	{`${item.item_quantity}`}
						</div>
					}
					{itemApprovals &&
						<div className="card-row">
							<Approval {...props}
								approvals={props.objExists(currentProof, "approvals") ? currentProof.approvals : itemApprovals}
								languageCode={languageCode} />
						</div>
					}
					
					{(odLink || odPreview) &&
						<div className="card-row">
							<div onClick={previewShell} href={odLink} class="link-btn" style={{flex: '0 1 15em'}}>
								<IconSVG icon="view-file" />
								Preview Print File
							</div>
							<a href={odLink} class="link-btn" style={{flex: '0 1 15em'}}>
								<IconSVG icon="download-file" />
								Download Print File
							</a>
						</div>
					}
					
					{customFields &&
						<div className="card-contents">
							<div className="grid info-table col-2" style={{gridTemplateColumns: 'auto 2fr'}}>
								<div className="info-table-title">Custom Information</div>
								{Array.isArray(customFields) &&
								customFields.map((field, index) => {
									return (
										<Fragment>
											<div className="name">
												{props.capitalizeString(field.field_label, true)}:
											</div>
											{field.field_type === 'file' ?
												<div className="value">
													<MediaFile {...props}
														userID={userID}
														token={token}
														inline={true}
														media={field.file}
														location={props.stateLocation ? props.stateLocation : "dashboard"} />
												</div>
											:
												<div className="value">
													{field.field_value ? 
														ReactHtmlParser(props.htmlBreaks(field.field_value)) :
														``}
												</div>
											}
										</Fragment>
									)
								})
								}
							</div>
						</div>
					}
				</div>
			}
			{task && Array.isArray(subProducts) &&
				subProducts.length > 0 && 
				<div className="card">
					<h3>Included Products{subProductsActive && <IconToken icon="collapse" tooltip={`Hide Product${subProducts.length === 1 ? '' : 's'}`} action={()=>{setSubProductsActive(false)}} />}</h3>
					{!subProductsActive &&
					<Control {...props}
						icon='expand'
						iconClass="no-btn"
						label={`Show ${subProducts.length} Included Product${subProducts.length === 1 ? '' : 's'}`}
						showLabel={true}
						action={()=>setSubProductsActive(true)} />
					}
					<div className={`expandable${subProductsActive ? ' active' : ''}`}>
						{subProducts.map((product, index) => {
							return (
								<div className="card-row">
									<MediaToken {...props}
										media={props.objGetValue(product, `details.${languageCode}.media[0]`)}
										preview={true} />
									{props.capitalizeString(props.objGetValue(product, `details.${languageCode}.product_name`), true)}
								</div>
							)
						})}
					</div>
				</div>
			}
			
			{task && item && 
				<TaskInterfaceFiles {...props}
					userID={userID}
					token={token}
					languageCode={languageCode}
					taskAccess={taskAccess}
					stateLocation={props.stateLocation ? props.stateLocation : 'dashboard'}
					taskType="item"
					task={task}
					item={item}
					currentProof={currentProof && item && 
									(taskName === 'client review' ||
									taskName === 'internal review') ? 
									currentProof : null}
 					project={project}
					reloadCue={'item'} />
			}
		</div>
	)
	
}