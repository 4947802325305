import React, {useEffect, useState} from 'react';

import TypeListItem from 'components/profiles/types/TypeListItem';
import Control from 'components/atoms/Control';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [coreOrg, setCoreOrg] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [types, setTypes] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID){
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token){
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.coreOrg && props.coreOrg !== coreOrg){
			setCoreOrg(props.coreOrg);
		}
	}, [props.coreOrg]);
	
	useEffect(() => {
		if(props.languageCode && props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}else if(!languageCode) {
			setLanguageCode('en');
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.types && languageCode) {
			if(Array.isArray(props.types) &&
				props.objExists(props.types[0], 'type_id') && 
				props.types !== types) {
				setTypes(props.types);			
			}else if(Object.entries(props.types).length > 0 && 
					props.objExists(props.types, `${languageCode}`)) {
				setTypes(props.types[languageCode]);
			}
		}
	}, [props.types, languageCode]);
	
	// FUNCTIONS
	function handleAddEditType(type = null) {
		if(props.actionAddEdit) {
			props.actionAddEdit(type);
		}
	}
	
	return (
		<div className="list-set margin-top-1em">
			<div className="info-block margin-top-1em margin-bottom-1em">
				{`A product type cannot be removed until there are no products assigned to it${props.site.persona.languages && props.site.persona.languages.length > 0 ? ' in all languages.' : '.'}`}
			</div>
			<ul className="ul">
				{types && 
					Array.isArray(types) &&
					types.map((type, index) => {
						return <TypeListItem
									{...props}
									key={`typLstItm${index}`}
									userID={userID}
									token={token}
									coreOrg={coreOrg} 
									type={type}
									languageCode={languageCode}	/>
					})
				}
			</ul>
			{coreOrg &&
				<div className="list-set-ctl margin-top-2em">
					<Control icon="add" label="Add Type" showLabel={true} action={()=>{handleAddEditType()}} />
				</div>
			}
		</div>
	)
}