import React, {useEffect, useState} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

import AdminUsersMain from 'components/admin/users/AdminUsersMain';
import AdminOrdersList from 'components/admin/orders/AdminOrdersList';
import AdminOrderQuery from 'components/admin/orders/AdminOrderQuery';
import AdminUsersAdd from 'components/admin/users/AdminUsersAdd';
import AdminOrder from 'components/admin/orders/AdminOrder';
import AdminOrderTasks from 'components/admin/orders/AdminOrderTasks';
import ModalConfirmation from 'components/molecules/ModalConfirmation';
import Loader from 'components/atoms/Loader';

import * as apis from 'methods/api.js';

export default (props) => {
  const [orderComponent, setOrderComponent] = useState(null);
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [canEdit, setCanEdit] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [orders, setOrders] = useState(null);
  const [noPermissionOrders, setNoPermissionOrders] = useState(null);
  const [listArgs, setListArgs] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [isAddingItemsToCart, setIsAddingItemsToCart] = useState(null);
  
  let ivlOrders;
  
  // HOOKS
  useEffect(() => {
    return() => {
      ivlOrders &&
      clearInterval(ivlOrders);
    }
  }, []);
  
  useEffect(() => {
    props.userID &&
    props.userID !== userID &&
    setUserID(props.userID);
  }, [props.userID]);

  useEffect(() => {
    props.token &&
    props.token !== token &&
    setToken(props.token);
  }, [props.token])

  useEffect(() => {
    props.organizationID &&
    props.organizationID !== organizationID &&
    setOrganizationID(props.organizationID);
  }, [props.organizationID]);

  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);

  useEffect(() => {
    props.languageCode	&&
    props.languageCode	!== languageCode &&
    setLanguageCode(props.languageCode);
  }, [props.languageCode	]);
  
  useEffect(() => {
    if(props.canEdit &&
      props.canEdit !== canEdit) {
      setCanEdit(props.canEdit);
    }
  }, [props.canEdit]);
  
  useEffect(() => {
    if(props.isSuper &&
        props.isSuper !== isSuper) {
        setIsSuper(props.isSuper);
      }
  }, [props.isSuper]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);
  
  useEffect(() => {
    if(userID && organizationID && token) {
      let params = [
         {name: 'process', value: 'get_projects'},
         {name: 'user_id', value: userID},
         {name: 'token', value: token}
      ]
      if(![1, 2].includes(organizationID)) {
        params.push({name: 'organization_id', value: organizationID});
      }
      if(Array.isArray(listArgs)) {
        params.push(...listArgs);
      }
      
      if(!props.objExists(props.admin, 'orders') || 
          props.objGetValue(props.admin, 'reload.orders') === true) {
        props.objGetValue(props.admin, 'reload.orders') === true &&
        props.adminDeleteVal('reload.orders');
        props.adminLoadState(params, 'orders', 'orders');
        
        if(ivlOrders) {
          clearInterval(ivlOrders);
        }
        
        ivlOrders = setInterval(() => {
          if(document.visibilityState === 'visible') {
            props.adminLoadState(params, 'orders', 'orders');
          }
        }, props.minToMS(10));
      }
    }
  }, [userID, token, organizationID, props.admin.orders, props.admin.reload, listArgs]);

  useEffect(() => {
    if(props.objExists(props.admin, 'orders') && 
      Array.isArray(props.admin.orders)) {
        
        let ordersAll = [];
        props.admin.orders.map(org => {
          if(props.objExists(org, 'project_id')) {
            ordersAll.push(org);
          }else if(([1, 2].includes(organizationID) ||
              org.organization_id === organizationID) &&
              org.projects && Array.isArray(org.projects)) {
                org.projects.map(project => {
                  ordersAll.push(project);
                })
          }
        });
        // Order
        ordersAll.sort((a, b)=>{return a.project_created > b.project_created ? -1 : 1});
        if(ordersAll !== orders){
          setOrders(ordersAll);  
        }
        
        if(noPermissionOrders) {
          setNoPermissionOrders(false);
        }
    }else if(props.objGetValue(props.admin, 'orders') === 'no permission') {
      setNoPermissionOrders(true);
    }else if(props.objGetValue(props.admin, 'orders') === 'no results') {
      setOrders('no results');
    }
  }, [props.admin.orders]);


  useEffect(() => {
    if(userID && token && organizationID && (props.pgParams || props.objExists(props.admin, 'params'))) {
      const activity = props.pgParams.activity ? props.pgParams.activity : 
                        props.objExists(props.admin, 'params.activity') ? 
                          props.admin.params.activity : null;
      switch(activity) {
        case 'order':
          setOrderComponent(<AdminOrder {...props}
                                            userID={userID}
                                            organizationID={organizationID}
                                            token={token}
                                            organizationName={organizationName}
                                            pgParams={props.pgParams}
                                            languageCode={languageCode}
                                            canEdit={canEdit}
                                            isSuper={isSuper}
                                            userAccess={userAccess}
                                            actionAddOrderItemsToCart={addOrderItemsToCart} />);
          return;
        case 'order-tasks':
        setOrderComponent(<AdminOrderTasks {...props}
                                          userID={userID}
                                          organizationID={organizationID}
                                          token={token}
                                          organizationName={organizationName}
                                          pgParams={props.pgParams}
                                          languageCode={languageCode}
                                          canEdit={canEdit}
                                          isSuper={isSuper}
                                          userAccess={userAccess} />);
        return;
        case 'order-query':
          setOrderComponent(<AdminOrderQuery {...props}
                                            userID={userID}
                                            token={token}
                                            organizationID={organizationID}
                                            organizationName={organizationName}
                                            languageCode={languageCode}
                                            pgParams={props.pgParams}
                                            canEdit={canEdit}
                                            isSuper={isSuper}
                                            userAccess={userAccess}
                                            orders={orders}
                                            noPermission={noPermissionOrders}
                                            actionListArgs={updateArgs}
                                            listArgs={listArgs}
                                            actionSelectOrg={props.actionUpdateCoreOrg} />);
          return;
        case 'orders-list':
        default:
          setOrderComponent(<AdminOrdersList {...props}
                                            userID={userID}
                                            token={token}
                                            organizationID={organizationID}
                                            organizationName={organizationName}
                                            languageCode={languageCode}
                                            pgParams={props.pgParams}
                                            canEdit={canEdit}
                                            isSuper={isSuper}
                                            userAccess={userAccess}
                                            orders={orders}
                                            noPermission={noPermissionOrders}
                                            actionListArgs={updateArgs}
                                            listArgs={listArgs}
                                            actionAddOrderItemsToCart={addOrderItemsToCart} />);
          return;
      }
    }
  }, [props.pgParams, userID, token, organizationID, organizationName, languageCode, userAccess, props.admin, orders]);
  
  function updateArgs(args) {
    setListArgs(args);
    props.adminSetVal('reload.orders', true);
  }
  
  function handleSubmit() {
    props.setModal(null);
    setRedirect(`/cart`);
  }
  
  function addOrderItemsToCart(project_id) {
    
    if(project_id) {
      
      if(props.objExists(props.cart, 'items') && Array.isArray(props.cart.items) && props.cart.items.length > 0) {
        props.cartRemoveVal('project');
        props.cartRemoveVal('items');
        props.filesDeleteVal('uploads');
        props.filesDeleteVal('uploadComplete');
        sessionStorage.removeItem('coreCRT');
        sessionStorage.removeItem('corePrjct');
      }
      
      setIsAddingItemsToCart(true);
      
      let formData = `process=duplicate_order_items&user_id=${userID}&token=${token}&project_id=${project_id}`;
      axios.post(apis.getReqUrl('orders'), formData).then(res => {
        console.log('TEST res.data', res.data);
        if((res.data.approval_required && res.data.approval_required.length > 0) || (res.data.declined_max && res.data.declined_max.length > 0) || (res.data.declined_stock && res.data.declined_stock.length > 0)) {
          props.setModal(
            <ModalConfirmation {...props}
              labelDecline={`Close`}
              labelAccept={`View Cart`}
              message={props.buildConfirmationMessage(res.data.approval_required, res.data.declined_max, res.data.declined_stock)}
              actionAccept={handleSubmit} />
          )
        }
        if(res.data.approved && res.data.approved.length > 0) {
          props.cartAssignValue('items', res.data.approved);
          setRedirect(`/cart`);
          setIsAddingItemsToCart(false);
        }else if((res.data.declined_max && res.data.declined_max.length > 0) || (res.data.declined_stock && res.data.declined_stock.length > 0)) {
          setIsAddingItemsToCart(false);
        }
      }).catch(error => {
        console.log("INIT SITE ERROR:", error);
      })
      
    }
    
  }

  return (
    <div>
      {redirect && <Redirect to={redirect} />}
      {!isAddingItemsToCart && orderComponent}
      {isAddingItemsToCart && <Loader label="Adding items to cart" overlayContents={true} />}
    </div>
  )
}
