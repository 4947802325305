import React, {useEffect, useState, Fragment} from 'react';

import InputMulti from 'components/forms/elements/InputMulti';
import Loader from 'components/atoms/Loader';
import FormPaymentOptionAddEdit from 'components/forms/common_forms/FormPaymentOptionAddEdit';
import PaymentOption from 'components/profiles/payments/PaymentOption';
import Control from 'components/atoms/Control';

export default(props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [paymentOptions, setPaymentOptions] = useState(null);
  
  // HOOKS
  useEffect(() => {
    if(props.userID) {
      setUserID(props.userID)
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token) {
      setToken(props.token)
    }
  }, [props.token]);

  useEffect(() => {
    if(props.organizationID && organizationID !== props.organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);
  
  useEffect(() => {
    if(userID && token && 
      (userAccess.super || userAccess.admin)) {
      const paymentParams = [
        {name: 'process', value: 'get_payment_options'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
      ];
      if(!props.objExists(props.admin, 'paymentOptions') ||
          props.objGetValue(props.admin, 'reload.paymentOptions') === true) {
            props.adminLoadState(paymentParams, 'site', 'paymentOptions');
            if(props.objGetValue(props.admin, 'reload.paymentOptions') === true) {
              props.adminDeleteVal('reload.paymentOptions');
              setPaymentOptions(null);
            }
        }
      
    }
  }, [userID, token, userAccess, props.admin]);
  
  useEffect(() => {
    if(props.objExists(props.admin, 'paymentOptions')) {
      setPaymentOptions(props.admin.paymentOptions);
    }
  }, [props.admin.paymentOptions]);
  
  // FUNCTIONS
  function loadAddPaymentOption() {
    props.setModal(
      <FormPaymentOptionAddEdit {...props}
          userID={userID}
          token={token}
          organizationID={organizationID}
          languageCode={languageCode}
          userAccess={userAccess}
          coreOrg={null}
          actionCancel={()=>{props.setModal(null)}}
          actionAddEdit={addPaymentOption} />
    )
  }
  
  function addPaymentOption(params) {
    props.setModal(null);
    props.adminLoadState(params, 'site', 'reload.paymentOptions');
  }
  
  return (
    <div>
      <h2>Payment Options</h2>
      {paymentOptions ?
        <Fragment>
          <div className="grid" style={{gridTemplateColumns: 'auto auto 1fr 2fr auto'}}>
            <div className="grid-header text-center">Public</div>
            <div className="grid-header">Option</div>
            <div className="grid-header">Component</div>
            <div className="grid-header">Description</div>
            <div className="grid-header text-center">Controls</div>
            
            {paymentOptions.map((payment, index) => {
              return <PaymentOption {...props}
                          key={`admnCrPymntOptn${index}`}
                          userID={userID}
                          token={token}
                          organizationID={organizationID}
                          userAccess={userAccess}
                          payment={payment} />
            })}
            
          </div>
          <Control icon="payment" 
              label="Add Payment Option"
              showLabel={true} 
              action={loadAddPaymentOption} />
        </Fragment>
      :
        <Loader label="Loading payment options..." />
      }
    </div>
  )
}
