import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router';

import CategoryList from 'components/categories/CategoryList';
import Loader from 'components/atoms/Loader';

import * as utils from 'methods/site';

export default (props) => {
  props = {...props.children, ...utils};
  const [organizationID, setOrganizationID] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [categories, setCategories] = useState(null);
  const [redirect, setRedirect] = useState(null);

  // HOOKS
  useEffect(() => {
  if(props.objExists(props.site, 'persona.organization_id')) {
    setOrganizationID(props.site.persona.organization_id);
  }
  if(props.objExists(props.site, 'site_language')) {
    setLanguageCode(props.site.site_language);
  }else{
    setLanguageCode('en');  
  }
  }, [props.site]);
  
  useEffect(() => {
  if(organizationID && props.objExists(props.site, 'persona.organization_name')) {
    document.title = `${props.site.persona.organization_name} : Categories`;
  }
  }, [organizationID]);

  useEffect(() => {
  if(props.store.categories !== categories) {
    setCategories(props.store.categories);
  }
  }, [props.store.categories]);

  return (
  <div className="set-container margin-top-1em">
    {redirect && <Redirect to={redirect} />}
    <h2>Categories</h2>
    {categories ? 
      <CategoryList {...props}
                    languageCode={languageCode}
                    categories={categories} />
    :
      <Loader label="Loading Categories" />
    }
  </div>
  )
}