import React, {useEffect, useState} from 'react';

import Loader from 'components/atoms/Loader';
import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
	const [form, setForm] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	
	useEffect(() => {
		let typeFieldsets = [
			{
				fieldset_name: `${props.objExists(props.type, `text_id`) ? 'Edit Product Type' : props.objExists(props.type, `type_id`) ? 'Add Language' : 'Add Product Type'}`,
				fields: []
			}
		];
		typeFieldsets[0].fields.push(
			{field_type: 'hidden',
			field_name: 'process',
			field_value: props.type && props.type.text_id ? 'update_value' : 'create_product_type'
			},
			{field_type: 'text',
			field_name: props.type && props.type.type_name ? 'field_value' : 'type_name',
			field_label: 'Type Name',
			field_value: props.type && props.type.type_name ? props.type.type_name : null,
			field_required: true,
			field_validate: false
			},
		);
		if(props.objExists(props.type, `text_id`)) {
			typeFieldsets[0].fields.push(
				{field_type: 'hidden',
				field_name: 'field_id',
				field_value: props.type && props.type.text_id ? props.type.text_id : ''
				},
				{field_type: 'hidden',
				field_name: 'field_name',
				field_value: 'type_name'
				},
				{field_type: 'hidden',
				field_name: 'update_id',
				field_value: props.userID
				}
			);
		}else{
			typeFieldsets[0].fields.push(
				{field_type: 'hidden',
				field_name: 'user_id',
				field_value: props.userID
				},
				{field_type: 'hidden',
				field_name: 'token',
				field_value: props.token
				},
				{field_type: 'hidden',
				field_name: 'organization_id',
				field_value: props.coreOrg
				},
				{field_type: 'hidden',
				field_name: 'type_id',
				field_value: props.objExists(props.type, `type_id`) ? props.type.type_id : ''
				},
				{field_type: 'hidden',
				field_name: 'type_published',
				field_value: true
				}
			);
			if(props.objExists(props.type, `type_id`)) {
				typeFieldsets[0].fields.push(
					{field_type: 'select',
					field_name: 'language_code',
					field_label: `Language`,
					field_default: props.languages ? props.languages[0].option_value : null,
					field_required: true,
					option_groups: [
						{options: props.languages}
					]}
				)
			}else{
				typeFieldsets[0].fields.push(
					{field_type: 'hidden',
					field_name: 'language_code',
					field_value: props.languageCode
					}
				)
			}
		}
		setFieldsets(typeFieldsets);
	}, [props.type]);
	
	useEffect(() => {
		if(fieldsets) {
			setForm(
				<FormDynamic
				  content={fieldsets}
				  name="add-edit-type"
				  returnObject={true}
				  fieldOnLoad={null}
				  formActions={{formCancel: handleCancel, formSubmit: handleAddEdit}} />
			)
		}
	}, [fieldsets]);
	
	function handleCancel(e) {
		props.setModal(null);
	}
	
	function handleAddEdit(params) {
		props.objExists(props, 'actionSubmit') &&
		props.actionSubmit(params);
	}
	
	return (
		<div>
			{form ? form : <Loader />}
		</div>
	)	
}