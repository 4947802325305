import React, {useEffect, useState, Fragment} from 'react';
import {useParams, useLocation} from 'react-router';

import Loader from 'components/atoms/Loader';
import Product from 'components/profiles/products/Product';
import IconSVG from 'components/icons/IconSVG';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [canEdit, setCanEdit] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  const [curLanguage, setCurLanguage] = useState(null);
  const [product, setProduct] = useState(null);
  const [categories, setCategories] = useState(null);
  const [coreOrg, setCoreOrg] = useState(null);
  
  const pgParams = useParams();
  const location = useLocation();
  
  if(sessionStorage.getItem('admPrd')) {
    sessionStorage.removeItem('admPrd');
  }

  // HOOKS
  useEffect(() => {
    props.adminSetVal(`reload.product`, true);
    return () => {
      props.adminDeleteVal('product');
      setProduct(null);
    }
  }, []);
  
  useEffect(() => {
    if(props.userID && props.userID !== userID) {
      setUserID(props.userID)
    }
  }, [props.userID]);
  
  useEffect(() => {
    if(props.token && props.token !== token) {
      setToken(props.token)
    }
  }, [props.token]);

  useEffect(() => {
    if(props.organizationID && organizationID !== props.organizationID) {
      setOrganizationID(props.organizationID);
    }
  }, [props.organizationID]);
  
  useEffect(() => {
    props.organizationName &&
    props.organizationName !== organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);
  
  useEffect(() => {
    if(props.userAccess && props.userAccess !== userAccess) {
      setUserAccess(props.userAccess);
    } 
  }, [props.userAccess]);
  
  useEffect(() => {
    props.canEdit &&
    setCanEdit(props.canEdit);
  }, [props.canEdit]);
  
  useEffect(() => {
    props.isSuper &&
    setIsSuper(props.isSuper);
  }, [props.isSuper]);
  
  useEffect(() => {
    if(props.coreOrg && props.coreOrg !== coreOrg) {
      setCoreOrg(props.coreOrg);
    }
  }, [props.coreOrg]);

  useEffect(() => {
    if(userID && token && organizationID && pgParams.component) {
      let params = [
        {name: 'process', value: 'get_product'},
        {name: 'product_id', value: pgParams.component},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'product_published', value: 0}
      ]
  
      if(![1, 2].includes(organizationID)) {
        params.push({name: 'organization_id', value: organizationID});
      }
      
      if(!props.objExists(props.admin, 'product.product_id') || 
          (props.objExists(props.admin, 'product.product_id') && 
            parseInt(props.objGetValue(props.admin, 'product.product_id')) !== parseInt(pgParams.component)) || 
              props.objGetValue(props.admin, 'reload.product') === true) {
        props.adminLoadState(params, 'products', 'product');
        if(props.objExists(props.admin, 'reload.product')) {
          props.adminDeleteVal('reload.product');
        }
      }
    }
  }, [userID, organizationID, token, pgParams, props.admin]);
  
  useEffect(()=> {
    if(props.objExists(props.admin, `product`) && 
        props.admin.product.product_id === parseInt(pgParams.component) &&
        props.admin.product !== product) {
      setProduct(props.admin.product); 
    }
  }, [props.admin.product]);
  
  useEffect(() => {
    if(product && languageCode) {
      if(props.objExists(product, `organization_id`) &&
        props.actionSelectOrg) {
          props.actionSelectOrg(parseInt(product.organization_id));
      }
      if(props.objExists(product, `details.${languageCode}`)) {
        setCurLanguage(languageCode);
      }else{
        setCurLanguage(null);
      }
    }
  }, [product, languageCode]);
  
  useEffect(() => {
    if(languageCode && 
        Array.isArray(props.objGetValue(props.admin, `categories.${languageCode}`)) &&
        props.admin.categories[languageCode].length > 0) {
        let cats = props.admin.categories[languageCode];
        if(props.objExists(product, 'organization_id')) {
          cats = cats.filter(oCat => oCat.organization_id === product.organization_id);
        }
        
        if(cats !== categories) {
          setCategories(cats)
        }
    }else if(categories &&
            (!Array.isArray(props.objGetValue(props.admin, `categories.${languageCode}`)) ||
              props.admin.categories[languageCode].length <= 0 ||
              (props.objExists(product, `product.organization_id`) &&
              categories.find(oCat => oCat.organization_id === product.organization_id)))) {
      setCategories(null);
    }
  }, [props.admin.categories, product, languageCode]);
  
  // FUNCTIONS
  
  return (
    <Fragment>
      {product ?
        curLanguage ? 
          <Product {...props}
                  userID={userID}
                  token={token}
                  organizationID={organizationID}
                  organizationName={organizationName}
                  languageCode={languageCode}
                  curLanguage={curLanguage}
                  userAccess={userAccess}
                  isAdmin={true}
                  canEdit={canEdit}
                  isSuper={isSuper}
                  location='admin'
                  product={product}
                  productTypes={props.admin.product_types}
                  categories={categories} />
        :
          <div className="admin-page-notice">
            <div>
              <IconSVG icon="alert" className="admin-page-icon" />
              <div className="admin-page-msg">Sorry, this product is not available in the language selected.</div>
            </div>
          </div>
      :
        <Loader />
      }
    </Fragment>
  )
}
