import React, {useEffect, useState, Fragment} from 'react';
import {Redirect} from 'react-router-dom';

import OrganizationSelector from 'components/profiles/orgs/OrganizationSelector';
import TypeList from 'components/profiles/types/TypeList';
import Loader from 'components/atoms/Loader';
import ModalConfirmation from 'components/molecules/ModalConfirmation';
import FormTypeAddEdit from 'components/forms/common_forms/FormTypeAddEdit';

export default (props) => {
	
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [organizationName, setOrganizationName] = useState(null);
	const [organizations, setOrganizations] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [userAccess, setUserAccess] = useState(null);
	const [canEdit, setCanEdit] = useState(null);
	const [isSuper, setIsSuper] = useState(null);
	const [coreOrg, setCoreOrg] = useState(null);
	const [coreOrgName, setCoreOrgName] = useState(null);
	const [loadingTypes, setLoadingTypes] = useState(null);
	const [types, setTypes] = useState(null);
	const [redirect, setRedirect] = useState(null);
	
	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
			setUserID(props.userID)
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
			setToken(props.token)
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.organizationID && organizationID !== props.organizationID) {
			setOrganizationID(props.organizationID);
		}
	}, [props.organizationID]);
	
	useEffect(() => {
		props.organizationName && props.organizationName !== organizationName &&
			setOrganizationName(props.organizationName);
	}, [props.organizationName]);
	
	useEffect(() => {
		props.organizations && props.organizations !== organizations &&
			setOrganizations(props.organizations);
	}, [props.organizations]);
	
	useEffect(() => {
		if(props.languageCode && languageCode !== props.languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(props.userAccess && props.userAccess !== userAccess) {
			setUserAccess(props.userAccess);
		} 
	}, [props.userAccess]);
	
	useEffect(() => {
		props.canEdit && props.canEdit !== canEdit &&
			setCanEdit(props.canEdit);
	}, [props.canEdit]);
	  
	useEffect(() => {
		props.isSuper && props.isSuper !== isSuper &&
			setIsSuper(props.isSuper);
	}, [props.isSuper]);
	
	useEffect(() => {
		if(organizationID > 1){
			setCoreOrg(organizationID)
		}else if(props.coreOrg && props.coreOrg !== coreOrg) {
			setCoreOrg(props.coreOrg);
		}else if(organizationID <= 1 && !props.coreOrg && coreOrg){
			setCoreOrg(null);
		}
	}, [props.coreOrg, organizationID]);
	
	useEffect(() => {
		if(coreOrg && props.objExists(props.admin, 'organizations') && Array.isArray(props.admin.organizations)) {
			const orgInfo = props.admin.organizations.find(org => org.organization_id === coreOrg);
			if(orgInfo) {
				setCoreOrgName(orgInfo.organization_name);
			}
		}else if(!coreOrg && coreOrgName) {
			setCoreOrgName(null);
		}
	}, [coreOrg, props.admin.organizations]);
	
	useEffect(() => {
		setLoadingTypes(true);
		if(props.objExists(props.admin, `product_types`) && 
			Array.isArray(props.admin.product_types) &&
			props.admin.product_types.length > 0) {
			let prdTypes = [];
			props.admin.product_types.map(prdType => {
				if(props.objExists(prdType, `${languageCode}`)) {
					prdTypes.push(prdType);
				}
			})
			if(coreOrg) {
				prdTypes = prdTypes.filter(oType => oType.organization_id === coreOrg);
			}
			if(prdTypes !== types) {
				setTypes(prdTypes);
			}
		}else if(types && 
				(!props.objExists(props.admin, `product_types`) || 
				!Array.isArray(props.admin.product_types) ||
				props.admin.product_types.length <= 0)) {
			setTypes(null);
		}
	}, [props.admin.product_types, coreOrg, languageCode]);
	
	useEffect(() => {
		if(types && loadingTypes) {
			setLoadingTypes(false);
		} 
	}, [types]);
	
	// FUNCTIONS
	function loadAddEditType(type, languages) {
		props.setModal(
			<FormTypeAddEdit
				{...props}
				userID={userID}
				token={token}
				coreOrg={coreOrg}
				languageCode={languageCode}
				languages={languages}
				type={type}
				actionSubmit={addEditType}
				cancelable={true} />
		)
	}
	
	function addEditType(params) {
		props.setModal(null);
		const processParam = params.find(param => param.name === 'process');
		if(processParam.value === 'create_product_type') {
			props.adminLoadState(params, 'types', 'reload.product_types');
			props.adminSetVal('reload.product_types', true);
		}else if(processParam.value === 'update_value') {
			props.adminLoadState(params, 'common_tasks', 'reload.product_types');
			props.adminSetVal('reload.products', true);
		}
	}
	
	function loadRemoveType(type) {
		if(type) {
			props.setModal(
				<ModalConfirmation {...props}
					message={`<p>Permanently remove <b>${type[languageCode].type_name ? props.capitalizeString(type[languageCode].type_name, true) : 'this type'}</b> from ${coreOrgName ? coreOrgName : 'this organization'}?</p>`}
					actionAccept={()=>{removeType(type.type_id)}} />
			);	
		}
	}
	
	function removeType(type_id) {
		props.setModal(null);
		const paramsRemove = [
		  {name: 'process', value: 'update_value'},
		  {name: 'field_id', value: type_id},
		  {name: 'field_name', value: 'type_removed'},
		  {name: 'field_value', value: 'true'},
		  {name: 'update_id', value: userID}
		];
		props.adminLoadState(paramsRemove, 'common_tasks', 'reload.product_types');
	}
	
	return (
		<div className="full-row">
			{redirect && <Redirect to={redirect} />}
			<h2>{coreOrgName ? `${coreOrgName} ` : ''}Product Types</h2>
			<Fragment>
				<OrganizationSelector {...props}
					organizations={organizations}
					action={props.actionSelectOrg}
					orgID={coreOrg}
					skipCore={true}
					userAccess={userAccess}  />
				{types && !loadingTypes ?
					<TypeList
						{...props}
						userID={userID}
						token={token}
						coreOrg={coreOrg}
						languageCode={languageCode}
						isAdmin={true}
						isSuper={isSuper}
						canEdit={canEdit}
						types={types}
						actionAddEdit={loadAddEditType}
						actionRemove={loadRemoveType} />
				: !types && !loadingTypes ?
					<p>To view types, please select an organization</p>
				:
					<Loader label="Loading types..." />
				}
			</Fragment>
		</div>
	)
}