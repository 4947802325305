import React from 'react';

export default (props) => {
  
  // props = {...props.children. props.utils};
  // console.log("admin props", props);
  // className="admin-component-content"
  
  return (
    <div className="">
      <p>Now here's stuff for you to get going with!</p>
      <p onClick={()=>{props.adminSetComponent('tasks')}}>Go To Tasks</p>
    </div>
  )
}
