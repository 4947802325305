import React, {useEffect, useState, Fragment} from 'react';
import axios from 'axios';

import Card from 'components/atoms/Card';
import CardContent from 'components/molecules/CardContent';
import CardValueTag from 'components/molecules/CardValueTag';
import IconSVG from 'components/icons/IconSVG';
import IconToken from 'components/icons/IconToken';
import Loader from 'components/atoms/Loader';
import Switch from 'components/forms/elements/Switch';
import FormDialogBox from 'components/forms/FormDialogBox';
import PropertySet from 'components/molecules/PropertySet';
import FormFileUpload from 'components/forms/common_forms/FormFileUpload';
import FileUpload from 'components/forms/elements/FileUpload';
import MediaSet from 'components/profiles/media/MediaSet';
import MediaFile from 'components/profiles/media/MediaFile';
import FileUploadPreview from 'components/forms/elements/FileUploadPreview';
import Control from 'components/atoms/Control';
import Link from 'components/navigation/Link';
import LiveFieldInput from 'components/forms/elements/LiveFieldInput';
import ModalConfirmation from 'components/molecules/ModalConfirmation';
import FormCategoryAdd from 'components/forms/common_forms/FormCategoryAdd';
import FormPropertyAdd from 'components/forms/common_forms/FormPropertyAdd';
import FormFieldAddEdit from 'components/forms/common_forms/FormFieldAddEdit';
import FormFieldList from 'components/forms/form_display/FormFieldList';
import FormMediaInfo from 'components/forms/common_forms/FormMediaInfo';
import ProductPricing from 'components/profiles/products/ProductPricing';
import FormPricingAddEdit from 'components/forms/common_forms/FormPricingAddEdit';
import ProductCharges from 'components/profiles/products/ProductCharges';
import FormChargesAddEdit from 'components/forms/common_forms/FormChargesAddEdit';
import ProductRestrictions from 'components/profiles/products/ProductRestrictions';
import FormRestrictionAddEdit from 'components/forms/common_forms/FormRestrictionAddEdit';
import FormProductInventoryAdd from 'components/forms/common_forms/FormProductInventoryAdd';
import FormCreateProductShell from 'components/forms/common_forms/FormCreateProductShell';
import SubProducts from 'components/profiles/products/SubProducts';
import FormSubProductsAdd from 'components/forms/common_forms/FormSubProductsAdd';
import FormProductTextAdd from 'components/forms/common_forms/FormProductTextAdd';

import * as utils from 'methods/site';
import * as forms from 'methods/forms';
import * as apis from 'methods/api.js';

export default (props) => {
  
  if(!utils.objExists(props, 'user') && utils.objExists(props.children, 'user')) {
    props = {...props.children, ...utils};
  }else{
    props = {...props, ...utils};
  }

  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [canEdit, setCanEdit] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  const [organizationID, setOrganizationID] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [curLanguage, setCurLanguage] = useState(null);
  const [userAccess, setUserAccess] = useState(null);
  const [curOrgID, setCurOrgID] = useState(null);
  const [showUpload, setShowUpload] = useState(null);
  const [optionsLang, setOptionsLang] = useState(null);
  const [files, setFiles] = useState(null);
  const [downloads, setDownloads] = useState(null);
  const [optionsOrg, setOptionsOrg] = useState(null);
  const [productTypes, setProductTypes] = useState(null);
  const [optionsType, setOptionsType] = useState(null);
  const [categories, setCategories] = useState(null);
  const [properties, setProperties] = useState(null);
  const [newFieldOrder, setNewFieldOrder] = useState(null);
  const [updateField, setUpdateField] = useState(null);
  const [newOrderFieldset, setNewOrderFieldset] = useState(null);
  const [productCharges, setProductCharges] = useState(null);
  const [productFields, setProductFields] = useState(null);
  const [inventoryHistory, toggleInventoryHistory] = useState(null);
  const [languagesAssigned, setLanguagesAssigned] = useState(null);
  const [languagesAvailable, setLanguagesAvailable] = useState(null);
  const [shellActive, setShellActive] = useState(null);

  useEffect(() => {
    props.userID &&
    setUserID(props.userID);
  }, [props.userID]);

  useEffect(() => {
    props.token &&
    setToken(props.token);
  }, [props.token])

  useEffect(() => {
    props.isSuper &&
    setIsSuper(props.isSuper);
  }, [props.isSuper]);

  useEffect(() => {
    props.organizationID &&
    setOrganizationID(props.organizationID);
  }, [props.organizationID]);

  useEffect(() => {
    props.organizationName &&
    setOrganizationName(props.organizationName);
  }, [props.organizationName]);
  
  useEffect(() => {
    if(props.languageCode && languageCode !== props.languageCode) {
      setLanguageCode(props.languageCode);
    }
  }, [props.languageCode]);

  useEffect(() => {
    if(props.curLanguage) {
      setCurLanguage(props.curLanguage);
    }else{
      setCurLanguage('en');
    }
  }, [props.curLanguage]);
  
  useEffect(() => {
    props.userAccess &&
    setUserAccess(props.userAccess);
  }, [props.userAccess]);
  
  useEffect(() => {
    if(props.product && userAccess &&
        (userAccess.super ||
          (userAccess.staffAdmin &&
          userAccess.staffAdmin[props.product.organization_id]) ||
        userAccess.admin)
    ) {
      setCanEdit(true);
    }else if(canEdit){
      setCanEdit(false);
    }
  }, [userAccess, props.product]);
  
  useEffect(() => {
    if(props.objExists(props, 'product.organization_id') && 
        props.product.organization_id !== curOrgID) {
      setCurOrgID(props.product.organization_id);
    }
  }, [props.product]);
  
  useEffect(() => {
    if(props.objExists(props.site.persona, 'languages') && Array.isArray(props.site.persona.languages) && props.site.persona.languages.length > 1) {
        let langOptions = [];
        props.site.persona.languages.map((lang, index) => {
          langOptions.push({option_name: props.capitalizeString(lang.language_name.toLowerCase(), true), option_value: lang.language_code});
        })
        setOptionsLang(langOptions);
      }
  }, [props.site.persona.languages]);
  
  useEffect(() => {
    if(curOrgID && props.productTypes &&
      Array.isArray(props.productTypes)) {
      const orgTypes = props.productTypes.filter(type => type.organization_id === curOrgID);
      if(orgTypes !== productTypes){
        setProductTypes(orgTypes);
      }
    }
  }, [props.productTypes, curOrgID]);
  
  useEffect(() => {
    if(showUpload) {
      props.setModal(<FileUpload {...props}
                                  userID={userID}
                                  token={token}
                                  organizationID={organizationID}
                                  language_code={curLanguage}
                                  media_type="image"
                                  media_owner_type="product"
                                  media_owner_id={props.product.product_id}
                                  media_published={true}
                                  create_images={true}
                                  return_boolean={true}
                                  cueReload={true}
                                  actionCancel={()=>{props.setModal(null); setShowUpload(false)}}
                                  actionUpload={{action: ()=>{setShowUpload(false)}}}
                                  cueReload={{location: 'admin', type: 'product'}}
                                  />);
    }
  }, [showUpload]);
  
  useEffect(() => {
    if(props.objExists(props[props.location], 'organizations')) {
      setOptionsOrg(
        props[props.location].organizations &&
        Array.isArray(props[props.location].organizations) &&
        props[props.location].organizations.map((org) => {
          return {option_name: org.organization_name, option_value: org.organization_id};
        })
      )
    }
  }, [props[props.location].organizations]);

  useEffect(() => {
    if(productTypes &&
      Array.isArray(productTypes) && curLanguage) {
        let types = [];
        let typeOptions = [];

          productTypes.map((type, index) => {
            if(!types.includes(type[curLanguage] && type[curLanguage].type_name.toLowerCase())) {
              types.push(type[curLanguage] && type[curLanguage].type_name.toLowerCase());
              typeOptions.push({option_name: props.capitalizeString(type[curLanguage] && type[curLanguage].type_name.toLowerCase(), true),
                      option_value: type.type_id});
            }
          })
        setOptionsType(typeOptions);
      }
  }, [productTypes, curLanguage, props.product]);

  useEffect(() => {
    if(props.categories && props.categories !== categories) {
      setCategories(props.categories);
    }else if (categories && !props.categories) {
      setCategories(null);
    }
  }, [props.categories]);

  useEffect(() => {
    if(props.objExists(props.product, `details.${curLanguage}.properties`)) {
      let activeProperties = [];
      Array.isArray(props.product.details[curLanguage].properties) &&
      props.product.details[curLanguage].properties.map(prop => {
        if(!activeProperties.includes(prop.property_name.toLowerCase())) {
          activeProperties.push(prop.property_name);
        }
      })
      activeProperties.length > 0 &&
      setProperties(activeProperties);
    }
  }, [props.product]);

  useEffect(() => {
    if(newFieldOrder && updateField && newOrderFieldset) {
      let params = [{name: 'process', value: 'update_field_order'},
                {name: 'user_id', value: userID},
                {name: 'token', value: token},
                {name: 'fieldset_id', value: updateField.fieldset_id},
                {name: 'field_id', value: updateField.field_id},
                {name: 'field_order', value: newFieldOrder},
      ]
      if(newOrderFieldset !== updateField.fieldset_id) {
        params.push({name: 'new_fieldset_id', value: newOrderFieldset});
      }

      props[`${props.location}LoadState`](params, 'fields', 'reload.product');

      setNewFieldOrder(null);
      setUpdateField(null);
      setNewOrderFieldset(null);
    }

  }, [newFieldOrder, updateField, newOrderFieldset]);
  
  useEffect(() => {
    if(props.product) {
      let charges = [];
      if(props.objExists(props.product, `details.${curLanguage}.charges`) && Array.isArray(props.product.details[curLanguage].charges) && props.product.details[curLanguage].charges.length > 0) {
        charges = props.product.details[curLanguage].charges;
      }
      let optionalCharges = [];
      if(props.objExists(props.product, `details.${curLanguage}.fieldsets`) && Array.isArray(props.product.details[curLanguage].fieldsets) && props.product.details[curLanguage].fieldsets.length > 0) {
        let fields = [];
        props.product.details[curLanguage].fieldsets.map((fieldset, index) => {
          if (Array.isArray(fieldset.fields) && fieldset.fields.length > 0) {
            fieldset.fields.map((field, index) => {
              fields.push(field);
              if (Array.isArray(field.charges) && field.charges.length > 0) {
                field.charges.map((charge, index) => {
                  charge.field_id = field.field_id;
                  charge.field_label = field.field_label;
                  optionalCharges.push(charge);
                })
              }
            })
          }
        })
        if(fields.length > 0) {
          fields.sort((a, b) => {return a["field_label"] > b["field_label"] ? 1 : -1})
          setProductFields(fields);
        }
      }
      let tempCharges = [...charges, ...optionalCharges];
      if(tempCharges.length > 0) {
        tempCharges.sort((a, b) => {return a["charge_name"] > b["charge_name"] ? 1 : -1})
        setProductCharges(tempCharges);
      } else {
        setProductCharges(null);
      }
    }
  }, [props.product]);
  
  useEffect(() => {
    if(props.site.persona.languages && props.site.persona.languages.length > 0) {
      let assignedLanguages = [];
      let availableLanguages = [];
      props.site.persona.languages.map(lang => {
        if(props.product.details[lang.language_code]) {
          assignedLanguages.push(lang);
        }else{
          availableLanguages.push(lang);
        }
      });
      setLanguagesAssigned(assignedLanguages);
      setLanguagesAvailable(availableLanguages);
    }
  }, [props.product, props.site.persona.languages]);
  
  useEffect(() => {
    if(userID &&
       token && 
       curLanguage && 
       props.product && 
       shellActive){
        props.setModal(
          <FormCreateProductShell {...props}
            userID={userID}
            token={token}
            languageCode={curLanguage}
            product={props.product}
            />
        );
    }
  }, [userID, token, curLanguage, props.product, props.files, props.admin, shellActive]);

  // FUNCTIONS
  function productPublish(value) {
    const params = [
      {name: 'process', value: 'publish_product'},
      {name: 'product_id', value: parseInt(props.product.product_id)},
      {name: 'language_code', value: curLanguage},
      {name: 'user_id', value: userID},
      {name: 'product_published', value: value},
    ]
    props[`${props.location}LoadState`](params, 'products', 'reload.product');
    props[`${props.location}SetVal`]('reload.products', true);
  }

  function updateValue(field_name, field_value) {
    const params = [
      {name: 'process', value: 'update_value'},
      {name: 'field_id', value: props.product.details[curLanguage].text_id},
      {name: 'field_name', value: field_name},
      {name: 'field_value', value: field_value},
      {name: 'field_parent', value: props.product.product_id},
    ]
    props[`${props.location}LoadState`](params, 'common_tasks', 'reload.product');
    props[`${props.location}SetVal`]('reload.products', true);
  }
  
  function updatePageCount(field_name, field_value) {
    let formData = `process=get_links&link_to=${props.product.product_id}&link_type=pageCountToProduct`;
    axios.post(apis.getReqUrl('links'), formData).then(res => {
      if (res.data.length > 0 && field_value >= 0) {
        const updateParams = [
          {name: 'process', value: 'update_link'},
          {name: 'user_id', value: userID},
          {name: 'link_id', value: res.data[0].link_id},
          {name: 'link_from', value: field_value},
          {name: 'link_removed', value: field_value == 0 ? true : false},
        ];
        props[`${props.location}LoadState`](updateParams, 'links', 'reload.product');
        props[`${props.location}SetVal`]('reload.products', true);
      } else if (field_value >= 0) {
        const addParams = [
          {name: 'process', value: 'add_link'},
          {name: 'link_from', value: field_value},
          {name: 'link_to', value: props.product.product_id},
          {name: 'link_type', value: 'pageCountToProduct'},
          {name: 'user_id', value: userID},
        ];
        props[`${props.location}LoadState`](addParams, 'links', 'reload.product');
        props[`${props.location}SetVal`]('reload.products', true);
      }
    }).catch(error => {
      console.log("INIT SITE ERROR:", error);
    })
  }
  
  function updateOrder(field_name, field_value) {
    const params = [
      {name: 'process', value: 'update_product_order'},
      {name: 'user_id', value: userID},
      {name: 'token', value: token},
      {name: 'category_id', value: parseInt(props.product.details[curLanguage].categories[0].category_id)},
      {name: 'product_id', value: parseInt(props.product.product_id)},
      {name: 'product_order', value: parseInt(field_value)},
      {name: 'return_boolean', value: true},
    ];
    //console.log("GOT ORDER PARAMS", params);
    props[`${props.location}LoadState`](params, 'products', 'reload.product');
    props[`${props.location}SetVal`]('reload.products', true);
  }

  function updateLink(linkName, linkValue, linkParams = []) {
    if(userID && token) {
      const params = [
        ...linkParams,
        {name: 'process', value: 'update_links'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'link_field', value: linkName},
        {name: 'link_value', value: linkValue},
      ];
      props[`${props.location}LoadState`](params, 'links', 'reload.product');
      props[`${props.location}SetVal`]('reload.products', true);
      props[`${props.location}SetVal`]('reload.product_types', true);
    }
  }
  
  function addLink(linkName, linkValue, linkParams = []) {
    if(userID && token) {
      const params = [
        ...linkParams,
        {name: linkName, value: linkValue},
        {name: 'process', value: 'add_link'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
      ];
      props[`${props.location}LoadState`](params, 'links', 'reload.product');
      props[`${props.location}SetVal`]('reload.products', true);
    }
  }
  
  function updateBundle(field_name, field_value) {
    const params = [
      {name: 'process', value: 'update_value'},
      {name: 'field_id', value: props.product.product_id},
      {name: 'field_name', value: field_name},
      {name: 'field_value', value: field_value},
    ]
    props[`${props.location}LoadState`](params, 'common_tasks', 'reload.product');
    props[`${props.location}SetVal`]('reload.products', true);
  }
  
  function updateProductLanguage(field_name, field_value) {
    const params = [
      {name: 'process', value: 'update_product_language'},
      {name: 'user_id', value: userID},
      {name: 'token', value: token},
      {name: 'product_id', value: props.product.product_id},
      {name: 'language_from', value: curLanguage},
      {name: 'language_to', value: field_value},
    ]
    props[`${props.location}LoadState`](params, 'products', 'reload.product');
    props[`${props.location}SetVal`]('reload.products', true);
  }
  
  // *** PROPERTIES ***
  function loadAddSpec(e) {
    props.setModal(<FormPropertyAdd {...props}
                                    product_id={props.product.product_id}
                                    actionAdd={propertyAdd}
                                    actionCancel={()=>{props.setModal(null);}}
                                    formName="Add Product Spec"
                                    properties={forms.commonPropertyMap.product[curLanguage]}
                                    activeProperties={properties} />);
  }

  function propertyAdd(params) {
    props.setModal(null);
    params.push({name: 'process', value: 'create_property' });
    params.push({name: 'user_id', value: userID});
    params.push({name: 'token', value: token});
    params.push({name: 'organization_id', value: organizationID});
    params.push({name: 'property_type', value: 'product'});
    params.push({name: 'property_link', value: props.product.product_id});
    params.push({name: 'language_code', value: curLanguage});

    //console.log("PROPERTY PARAMS", params);
    props[`${props.location}LoadState`](params, 'properties', 'reload.product');
  }

  function propertyEdit(values) {
    if(Array.isArray(values)) {
      values.map(value => {
        if(value.name.includes('property_id:')) {
          const params = [
            {name: 'process', value: 'update_property_pair'},
            {name: 'user_id', value: userID},
            {name: 'token', value: token},
            {name: 'property_id', value: value.name.split(':')[1]},
            {name: 'property_value', value: value.value},
          ];
          props[`${props.location}LoadState`](params, 'properties', 'reload.product');
        }
      })
    }
  }

  function propertyRemove(value) {
    if(parseInt(value.property_id) > 0) {
      const params = [
          {name: 'process', value: 'update_links'},
          {name: 'user_id', value: userID},
          {name: 'token', value: token},
          {name: 'link_type', value: 'propertyToProduct'},
          {name: 'link_from', value: value.property_id},
          {name: 'link_to', value: props.product.product_id},
          {name: 'link_field', value: 'link_removed'},
          {name: 'link_value', value: true},
      ]
      props[`${props.location}LoadState`](params, 'links', 'reload.product');
      props.notifyUser({icon: "check", message: `<b>${props.capitalizeString(value.property_name)}</b> has been removed.`});
    }else{
      props.notifyUser({icon: "alert", message: `<b>${props.capitalizeString(value.property_name)}</b> could not be removed.`});
    }
  }

  // *** CATEGORIES ***
  function loadCategoryRemove(value) {
    props.setModal(<ModalConfirmation
                      {...props}
                      message="<h3>Remove this category?</h3>"
                      actionAccept={()=>{categoryRemove(value)}} />)
  }

  function categoryRemove(value) {
    props.setModal(null);
    const category = props.product.details[curLanguage].categories.find(cat => cat.category_id === value);
    if(parseInt(value) > 0) {
      const params = [
          {name: 'process', value: 'update_links'},
          {name: 'user_id', value: userID},
          {name: 'token', value: token},
          {name: 'link_type', value: 'productToCategory'},
          {name: 'link_from', value: parseInt(props.product.product_id)},
          {name: 'link_to', value: parseInt(value)},
          {name: 'link_field', value: 'link_removed'},
          {name: 'link_value', value: true},
      ]
      props[`${props.location}LoadState`](params, 'links', 'reload.product');
      props.notifyUser({icon: "check", message: `<b>${category && category.category_name ? props.capitalizeString(category.category_name) : 'The category'}</b> has been removed.`});
    }else{
      props.notifyUser({icon: "alert", message: `<b>${props.capitalizeString(value.property_name)}</b> could not be removed.`});
    }

  }

  function loadCategoryAdd() {
    let catOptions = [];
    
    props.setModal(<FormCategoryAdd {...props}
                                    product_id={props.product.product_id}
                                    action={categoryAdd}
                                    isEdit={false}
                                    categories={categories}
                                    activeCategories={props.objExists(props.product, `details.${curLanguage}.categories`) ? props.product.details[curLanguage].categories : null} />);
  }

  function categoryAdd(params) {
    props.setModal(null);
    const category_id = params.find(param => param.name === 'category_id').value;
    const link_type = params.find(param => param.name === 'optional_product').value === 'true' ? 'optionalProductToCategory' : 'productToCategory';
    const product_quantity = params.find(param => param.name === 'product_quantity').value;
    if(category_id && parseInt(category_id) > 0) {
      if(product_quantity && parseInt(product_quantity) > 0) {
        let formData = `process=add_link&link_from=${props.product.product_id}&link_to=${category_id}&link_type=${link_type}&user_id=${userID}&link_published=1`;
        axios.post(apis.getReqUrl('links'), formData).then(res => {
          if (res.data.length > 0) {
            const qtyParams = [
              {name: 'process', value: 'add_link'},
              {name: 'user_id', value: userID},
              {name: 'token', value: token},
              {name: 'link_from', value: parseInt(product_quantity)},
              {name: 'link_to', value: parseInt(res.data)},
              {name: 'link_type', value: 'qtyToProductToCategory'},
              {name: 'link_published', value: false},
              {name: 'return_bool', value: true}
            ]
            props[`${props.location}LoadState`](qtyParams, 'links', `reload.product`);
          }
        }).catch(error => {
          console.log("INIT SITE ERROR:", error);
        })
      } else {
        params = [
          {name: 'process', value: 'add_link'},
          {name: 'user_id', value: userID},
          {name: 'token', value: token},
          {name: 'link_from', value: parseInt(props.product.product_id)},
          {name: 'link_to', value: parseInt(category_id)},
          {name: 'link_type', value: link_type},
          {name: 'link_published', value: true},
          {name: 'return_bool', value: true}
        ]
        props[`${props.location}LoadState`](params, 'links', `reload.product`);
      }
      props.notifyUser({icon: "check", message: `The category has been added.`});
    }else{
      params.push({name: 'process', value: 'create_category' });
      params.push({name: 'user_id', value: userID});
      params.push({name: 'token', value: token});
      params.push({name: 'organization_id', value: props.product.organization_id});
      params.push({name: 'category_type', value: params.find(p => p.name === 'optional_product').value == 'true' ? 'optionalProduct' : 'product'});
      params.push({name: 'category_link', value: parseInt(props.product.product_id)});
      params.push({name: 'language_code', value: curLanguage});
      params.push({name: 'category_published', value: true});
      params.push({name: 'return_boolean', value: true});
      props[`${props.location}LoadState`](params, 'categories_int', `reload.product`);
    }
  }
  
  function updateProductQuantity(name, value, optionalParams) {
    // Add of update qtyToProductToCategory link
    if (value > 0) {
      // Update qtyToProductToCategory link
      if (optionalParams.find(p => p.name === 'product_quantity_link_id').value) {
        const params = [
            {name: 'process', value: 'update_link'},
            {name: 'user_id', value: userID},
            {name: 'token', value: token},
            {name: 'link_type', value: 'qtyToProductToCategory'},
            {name: 'link_from', value: parseInt(value)},
            {name: 'link_id', value: optionalParams.find(p => p.name === 'product_quantity_link_id').value}
        ];
        props.adminLoadState(params, `links`, `reload.product`);
      // Add qtyToProductToCategory link
      } else {
        const params = [
            {name: 'process', value: 'add_link'},
            {name: 'user_id', value: userID},
            {name: 'token', value: token},
            {name: 'link_type', value: 'qtyToProductToCategory'},
            {name: 'link_from', value: parseInt(value)},
            {name: 'link_to', value: optionalParams.find(p => p.name === 'product_category_link_id').value}
        ];
        props.adminLoadState(params, `links`, `reload.product`);
      }
    // Remove qtyToProductToCategory link
    } else if (value == 0 && optionalParams.find(p => p.name === 'product_quantity_link_id').value) {
      const params = [
          {name: 'process', value: 'update_link'},
          {name: 'user_id', value: userID},
          {name: 'token', value: token},
          {name: 'link_removed', value: true},
          {name: 'link_id', value: optionalParams.find(p => p.name === 'product_quantity_link_id').value}
      ];
      props.adminLoadState(params, `links`, `reload.product`);
    }
  }
  
  function toggleOptionalProduct(value, params) {
    if(params) {
      const paramsOptional = [
          {name: 'process', value: 'update_links'},
          {name: 'user_id', value: userID},
          {name: 'token', value: token},
          {name: 'link_type', value: value ? 'productToCategory' : 'optionalProductToCategory'},
          {name: 'link_from', value: parseInt(props.product.product_id)},
          {name: 'link_to', value: parseInt(params.content_id)},
          {name: 'link_field', value: 'link_type'},
          {name: 'link_value', value: value ? 'optionalProductToCategory' : 'productToCategory'},
      ];
      props.adminLoadState(paramsOptional, `links`, `reload.product`);
    }
  }
  
  // *** SUB-PRODUCTS ***
  function loadSubProductsRemove(value) {
    props.setModal(<ModalConfirmation
                      {...props}
                      message="<h3>Remove this category?</h3>"
                      actionAccept={()=>{subProductsRemove(value)}} />)
  }
  
  function subProductsAdd(params) {
    props.setModal(null);
    const category_id = params.find(param => param.name === 'category_id').value;
    
    if(category_id && parseInt(category_id) > 0) {
      params = [
        {name: 'process', value: 'add_link'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'link_from', value: parseInt(category_id)},
        {name: 'link_to', value: parseInt(props.product.product_id)},
        {name: 'link_type', value: 'categoryToProduct'},
        {name: 'link_published', value: false},
        {name: 'return_bool', value: true}
      ]
      props[`${props.location}LoadState`](params, 'links', `reload.product`);
      props.notifyUser({icon: "check", message: `The sub-products have been added.`});
    }
  }
  
  function loadSubProductsAdd() {
    let catOptions = [];
    
    props.setModal(<FormSubProductsAdd {...props}
                                    product_id={props.product.product_id}
                                    action={subProductsAdd}
                                    isEdit={false}
                                    categories={categories}
                                    activeCategories={props.objExists(props.product, `details.${curLanguage}.sub_categories`) ? props.product.details[curLanguage].sub_categories : null}
                                    parentCategories={props.objExists(props.product, `details.${curLanguage}.categories`) ? props.product.details[curLanguage].categories : null} />);
  }
  
  function subProductsRemove(value) {
    props.setModal(null);
    const category = props.product.details[curLanguage].sub_categories.find(cat => cat.category_id === value);
    if(parseInt(value) > 0) {
      const params = [
          {name: 'process', value: 'update_links'},
          {name: 'user_id', value: userID},
          {name: 'token', value: token},
          {name: 'link_type', value: 'categoryToProduct'},
          {name: 'link_from', value: parseInt(value)},
          {name: 'link_to', value: parseInt(props.product.product_id)},
          {name: 'link_field', value: 'link_removed'},
          {name: 'link_value', value: true},
      ]
      props[`${props.location}LoadState`](params, 'links', 'reload.product');
      props.notifyUser({icon: "check", message: `<b>${category && category.category_name ? props.capitalizeString(category.category_name) : 'The category'}</b> has been removed.`});
    }else{
      props.notifyUser({icon: "alert", message: `<b>${props.capitalizeString(value.property_name)}</b> could not be removed.`});
    }
  }
  
  // *** MEDIA ***
  function loadAddMedia(type, createImages = false) {
    props.setModal(
      <FileUpload {...props}
          userID={userID}
          token={token}
          organizationID={organizationID}
          language_code={curLanguage}
          media_type={type}
          media_owner_type="product"
          media_owner_id={props.product.product_id}
          media_published={true}
          return_boolean={true}
          create_images= {createImages}
          includeSubmit={true}
          actionCancel={()=>{props.setModal(null);}}
          actionUpload={{action: ()=>{
            props.setModal(null);
            props[`${props.location ? props.location : 'admin'}SetVal`]('reload.products', true);
            props[`${props.location ? props.location : 'admin'}SetVal`]('reload.product', true);
          }}}
          cueReload={[
            {location: 'admin', type: 'product'},
            {location: 'admin', type: 'products'}
          ]}
          />
    )
  };

  // *** CUSTOM FIELDS ***
  function loadAddEditField(fieldset_id =  null, option_id = null, field_count = 0, field = null) {
    if(!option_id && !parseInt(fieldset_id)) {
      fieldset_id = null;
      field_count = null;
    }
    props.setModal(<FormFieldAddEdit {...props}
      field={field}
      fieldset_id={fieldset_id}
      option_id={option_id}
      field_count={field_count}
      actionAdd={addEditField}
      product_id={props.product.product_id}
    />);
  }

  function addEditField(params) {
    props.setModal(null);
    params = [...params,
              {name: 'user_id', value: userID},
              {name: 'token', value: token},
              {name: 'organization_id', value: organizationID},
              {name: 'language_code', value: curLanguage},
    ]
    props[`${props.location}LoadState`](params, 'fields', 'reload.product');
  }
  
  //console.log("ADMIN RELOAD", props.admin.reload);

  function confirmRemoveField(params) {
    props.setModal(
      <ModalConfirmation {...props}
                          message={`Are you sure you wish to remove ${params.field_name ? `'${props.capitalizeString(params.field_name, true)}'` : 'this field'} from this product?`}
                          actionCancel={()=>{props.setModal(null)}}
                          actionAccept={()=>{removeField(params);}} />
      )
  }
  
  function removeField(params){
    props.setModal(null);
    params = [
      ...params,
      {name: 'user_id', value: userID},
      {name: 'token', value: token},
      {name: 'language_code', value: curLanguage}
    ];
    
    props.adminLoadState(params, 'fieldsets', 'reload.product');
  }

  function setFieldOrder(newOrder, newFieldsetID) {
    newOrder &&
    setNewFieldOrder(newOrder);

    newFieldsetID &&
    setNewOrderFieldset(newFieldsetID);
  }

  function updateFieldsetName(name, value, fieldset_id) {
    const params = [
      {name: 'process', value: 'update_product_fieldset'},
      {name: 'user_id', value: userID},
      {name: 'token', value: token},
      {name: 'language_code', value: curLanguage},
      {name: 'fieldset_id', value: fieldset_id},
      {name: name, value: value},
    ]
    // console.log("FIELDSET UPDATE PARAMS", params);
    props[`${props.location}LoadState`](params, 'fieldsets', 'reload.product');
  }

  function addUpdateOption(params) {
    params = [...params,
            {name: 'user_id', value: userID},
            {name: 'token', value: token},
            {name: 'organization_id', value: organizationID},
            {name: 'language_code', value: curLanguage},];

    props[`${props.location}LoadState`](params, 'fieldsets', 'reload.product');
    props[`${props.location}SetVal`]('reload.products', true);
    
  }

  // PRICING AND DISCOUNT FUNCTIONS
  function launchAddEditPricing(page_pricing) {
    props.setModal(
      <FormPricingAddEdit {...props}
                          pricing={null}
                          product_id={props.product.product_id}
                          page_pricing={page_pricing ? true : false}
                          actionCancel={()=>{props.setModal(null);}}
                          actionAddEdit={addEditPricing} />);
  }

  function addEditPricing(params) {
    params = [...params,
            {name: 'user_id', value: userID},
            {name: 'token', value: token},
            {name: 'organization_id', value: props.product.organization_id ? props.product.organization_id : organizationID},
            {name: 'language_code', value: curLanguage}]
    props[`${props.location}LoadState`](params, 'pricing', 'reload.product');
    props[`${props.location}SetVal`]('reload.products', true);
  }

  function removePricingOrDiscount(params) {
    params = [...params,
            {name: 'user_id', value: userID},
            {name: 'token', value: token},
            {name: 'organization_id', value: props.product.organization_id ? props.product.organization_id : organizationID},
            {name: 'language_code', value: curLanguage}]
    props[`${props.location}LoadState`](params, 'links', 'reload.product');
    props[`${props.location}SetVal`]('reload.products', true);
  }

  function addEditDiscount(params) {
    params = [...params,
            {name: 'user_id', value: userID},
            {name: 'token', value: token},
            {name: 'organization_id', value: props.product.organization_id ? props.product.organization_id : organizationID},
            {name: 'language_code', value: curLanguage}]
    props[`${props.location}LoadState`](params, 'pricing', 'reload.product');
    props[`${props.location}SetVal`]('reload.products', true);
  }
  
  function launchAddEditCharges() {
    props.setModal(
      <FormChargesAddEdit {...props}
                          charges={null}
                          fields={productFields}
                          product_id={props.product.product_id}
                          actionCancel={()=>{props.setModal(null);}}
                          actionAddEdit={addEditCharge} />);
  }
  
  function addEditCharge(params) {
    props.setModal(null);
    params = [...params,
            {name: 'user_id', value: userID},
            {name: 'token', value: token}]
    props[`${props.location}LoadState`](params, 'pricing', 'reload.product');
    props[`${props.location}SetVal`]('reload.products', true);
  }
  
  function removeCharge(params) {
    params = [...params,
            {name: 'user_id', value: userID},
            {name: 'token', value: token}]
    props[`${props.location}LoadState`](params, 'links', 'reload.product');
    props[`${props.location}SetVal`]('reload.products', true);
  }
  
  // RESTRICTIONS
  function updateClientApproval(value) {
    const params = [
      {name: 'process', value: 'update_value'},
      {name: 'field_id', value: props.product.details[curLanguage].product_id},
      {name: 'field_name', value: 'product_approval_required'},
      {name: 'field_value', value: value},
      {name: 'update_id', value: userID},
    ]
    props[`${props.location}LoadState`](params, 'common_tasks', 'reload.product');
    props[`${props.location}SetVal`]('reload.products', true);
  }
  
  function addEditRestriction(params) {
    params = [...params,
            {name: 'user_id', value: userID},
            {name: 'token', value: token}]
    let startIndex = params.findIndex(p => p.name == "restriction_date_start");
    if (params[startIndex].value) {
      params[startIndex].value = new Date(params[startIndex].value).toJSON().slice(0, 10);
    }
    let endIndex = params.findIndex(p => p.name == "restriction_date_end");
    if (params[endIndex].value) {
      params[endIndex].value = new Date(params[endIndex].value).toJSON().slice(0, 10);
    }
    props[`${props.location}LoadState`](params, 'products', 'reload.product');
    props[`${props.location}SetVal`]('reload.products', true);
  }
  
  function removeRestriction(params) {
    params = [...params,
            {name: 'user_id', value: userID},
            {name: 'token', value: token}]
    props[`${props.location}LoadState`](params, 'common_tasks', 'reload.product');
    props[`${props.location}SetVal`]('reload.products', true);
  }
  
  function launchAddEditRestriction() {
    props.setModal(
      <FormRestrictionAddEdit {...props}
                          organization_id={props.product.organization_id}
                          product_id={props.product.product_id}
                          restriction={null}
                          actionCancel={()=>{props.setModal(null);}}
                          actionAddEdit={addEditRestriction} />);
  }
  
  // INVENTORY AND QUANTITY LIMITS
  function launchAddInventory() {
    props.setModal(
      <FormProductInventoryAdd {...props}
                        userID={userID}
                        token={token}
                        organizationID={props.product.organization_id}
                        languageCode={curLanguage}
                        productID={props.product.product_id}
                        product={props.product}
                        actionCancel={()=>{props.setModal(null);}}
                        actionAdd={addInventory} />);
  }
  
  function addInventory(params) {
    if(Array.isArray(params)) {
      let operation = params.find(param => param.name === 'inventory_operation');
      let inventoryProcess;
      
      switch(operation.value) {
        case 'add':
          inventoryProcess = 'add_product_inventory';
          break;
        case 'subtract':
          inventoryProcess = 'remove_product_inventory';
          break;
        default:
          break; 
      }
      props.setModal(null);
      
      if(inventoryProcess) {
        params = [{name: 'process', value: inventoryProcess}, ...params];
        props.adminLoadState(params, 'products', 'reload.product');
      }else{
        props.notifyUser({message: "The adjustment could be made", icon: 'alert'});
      }
    }  
  }
  
  function updateProductSwitchValue(value, params) {
    params = [
      ...params,
      {name: 'update_value', value: value},
    ]
    props.adminLoadState(params, 'products', 'reload.product');
    props[`${props.location}SetVal`]('reload.products', true);
  }
  
  function updateProductValue(name, value, params) {
    params = [
      ...params,
      {name: 'update_column', value: name},
      {name: 'update_value', value: value},
    ]
    props.adminLoadState(params, 'products', 'reload.product');
    props[`${props.location}SetVal`]('reload.products', true);
  }
  
  // LANGUAGES
  function loadLanguageAdd() {
    props.setModal(
      <FormProductTextAdd {...props}
                          languages={languagesAvailable}
                          product_id={props.product.product_id}
                          product_code={props.product.details[curLanguage].product_code}
                          product_name={props.product.details[curLanguage].product_name}
                          product_description={props.product.details[curLanguage].product_description}
                          actionCancel={()=>{props.setModal(null);}}
                          actionSubmit={addProductText} />);
  }
  
  function addProductText(params) {
    props.setModal(null);
    props.adminLoadState(params, 'products', 'reload.product');
    props.adminLoadState('reload.products', true);
  }
  
  function loadLanguageRemove(language_id, params) {
    props.setModal(<ModalConfirmation
                      {...props}
                      message={`Are you sure you want to remove the <b>${params.language_name}</b> language from this product?`}
                      actionAccept={()=>{languageRemove(params.text_id, language_id)}} />)
  }
  
  function languageRemove(text_id, language_id) {
    props.setModal(null);
    const params = [
        {name: 'process', value: 'update_links'},
        {name: 'user_id', value: userID},
        {name: 'token', value: token},
        {name: 'link_type', value: 'productTextToLanguage'},
        {name: 'link_from', value: text_id},
        {name: 'link_to', value: language_id},
        {name: 'link_field', value: 'link_removed'},
        {name: 'link_value', value: true},
    ]
    props.adminLoadState(params, 'links', 'reload.product');
    props.adminLoadState('reload.products', true);
  }
  
  // ON DEMAND SHELLS
  function loadCreateShell() {
    setShellActive(true);
  }
  
  //console.log("PRODUCT", props.product);
  
  return (
    <article className="project" style={{padding: 0}}>
      {canEdit && props.objExists(props.product, `details.${curLanguage}.shell`) &&
          <Link to={`../shell/${props.product && props.product.product_id}`} className="ctl-label">
            <IconToken icon="edit" className="no-btn" style={{textAlign: 'left'}} />
            Edit On-Demand File
          </Link>
      }
      {canEdit && !props.objExists(props.product, `details.${curLanguage}.shell`) &&
        <Control 
          icon="on-demand" 
          label="Create On-Demand File" 
          showLabel={true} 
          iconClass="no-btn"
          action={loadCreateShell} />
      }
      
      <h2><IconSVG icon="cart" className="h2" />Product</h2>
      {userID && organizationID && curLanguage && props.product &&
        props.objExists(props.product, `details.${curLanguage}`) ?
        <Fragment>
          <Card>
            <h3 style={{width: '100%'}}>
              <LiveFieldInput
                value={props.capitalizeString(props.product.details[curLanguage].product_name, true)}
                name="product_name"
                action={updateValue}
                disabled={canEdit ? false : true}
                style={{maxWidth: '30em'}}
                 />
            </h3>
            {props.isAdmin &&
              <div className="card-ctl top">
                { canEdit ?
                    <Switch name='product_published'
                      action={productPublish}
                      label={`Publish`}
                      value={props.product.details[curLanguage].product_published === 1 ? true : false}
                       />
                  :
                    <Fragment>
                    {props.product.details[curLanguage].product_published === 1 ? 'Published' : 'Unpublished'}
                    </Fragment>
                }
                
              </div>
            }

            <div className="card-set">
              <div className="card-set-block">
                <div className="card-row">
                  <LiveFieldInput value={props.product.details[curLanguage].product_sku}
                                  label="Product SKU"
                                  name="product_sku"
                                  action={updateValue}
                                  disabled={canEdit ? false : true} />
                </div>
                <div className="card-row">
                  <LiveFieldInput value={props.product.details[curLanguage].product_code}
                                label="Product Code"
                                name="product_code"
                                action={updateValue}
                                disabled={canEdit ? false : true} />
                </div>
                <div className="card-row">
                  <LiveFieldInput type="number"
                                  value={parseInt(props.product.details[curLanguage].product_order)}
                                  label="Product Order"
                                  name="product_order"
                                  action={updateOrder}
                                  disabled={canEdit ? props.objExists(props, `product.details.${curLanguage}.categories[0].category_id`) ? false : true : true} />
                </div>
                <div className="card-row">
                  <LiveFieldInput type="number"
                                  value={parseInt(props.product.details[curLanguage].product_page_count)}
                                  label="Product Page Count"
                                  name="product_page_count"
                                  action={updatePageCount}
                                  disabled={canEdit ? false : true} />
                </div>
                {props.isAdmin && optionsOrg && props[props.location].organizations &&
                  <div className="card-row">
                    <LiveFieldInput type="select"
                                    label="Organization"
                                    option_groups={[{options: optionsOrg}]}
                                    name="link_to"
                                    value={props.product.organization_id}
                                    action={updateLink}
                                    optionalParams={[{name: 'link_type', value: 'productToOrganization'},
                                                      {name: 'link_from', value: props.product.product_id},
                                                      {name: 'link_to', value: props.product.organization_id}]}
                                    
                                    disabled={userAccess.super && organizationID === 1 ? false : true} />
                  </div>
                }
                {props.isAdmin && optionsType ?
                  <div className="card-row">
                    <LiveFieldInput type="select"
                                    label="Type"
                                    option_groups={[{options: optionsType}]}
                                    name={props.objExists(props, `product.details.${curLanguage}.type.type_id`) ? 
                                            'link_to' : 'link_to'}
                                    value={props.objExists(props, `product.details.${curLanguage}.type.type_id`) ? 
                                            parseInt(props.product.details[curLanguage].type.type_id) : null}
                                    action={props.objExists(props, `product.details.${curLanguage}.type.type_id`) ?
                                              updateLink : addLink}
                                    optionalParams={[{name: 'link_type', value: 'productToType'},
                                                      {name: 'link_from', value: props.product.product_id},
                                                      props.objExists(props, `product.details.${curLanguage}.type.type_id`)  ?
                                                        {name: 'link_to', 
                                                        value: props.objExists(props, `product.details.${curLanguage}.type.type_id`) ? 
                                                                parseInt(props.product.details[curLanguage].type.type_id) : 
                                                                  null} :
                                                        {name: 'link_default', value: 1},
                                                      {name: 'link_published', value: 1},
                                                      {name: 'return_bool', value: true}]}
                                    disabled={canEdit ? false : true} />
                  </div>
                :
                  <div className="card-row">
                    <label>Product Type</label>
                    {props.objExists(props.product, `details.${curLanguage}.type.type_name`) ?
                      props.capitalizeString(props.product.details[curLanguage].type.type_name, true) :
                      `None`}
                  </div>
                }
                <div className="card-row">
                  <LiveFieldInput value={props.product.details[curLanguage].product_bundle}
                                label="Product Bundle"
                                name="product_bundle"
                                action={updateBundle}
                                disabled={canEdit ? false : true} />
                </div>
              </div>
              <div className="card-set-block">
                <div className="card-row">
                  <LiveFieldInput value={props.product.details[curLanguage].product_start}
                                  type='date'
                                  label="Start Date"
                                  name="product_start"
                                  optionalParams={[
                                    {name: 'process', value: 'update_product_value'},
                                    {name: 'user_id', value: userID},
                                    {name: 'token', value: token},
                                    {name: 'product_id', value: props.product.product_id},
                                    {name: 'organization_id', value: props.product.organization_id},
                                    {name: 'update_value_type', value: 'DATE'},
                                    {name: 'return_bool', value: true}
                                  ]}
                                  action={updateProductValue}
                                  minDate={props.convertISODate('2020-01-01 00:00:00')}
                                  disabled={canEdit ? false : true} />
                </div>
                <div className="card-row">
                  <LiveFieldInput value={props.product.details[curLanguage].product_end}
                                  type='date'
                                  label="End Date"
                                  name="product_end"
                                  optionalParams={[
                                    {name: 'process', value: 'update_product_value'},
                                    {name: 'user_id', value: userID},
                                    {name: 'token', value: token},
                                    {name: 'product_id', value: props.product.product_id},
                                    {name: 'organization_id', value: props.product.organization_id},
                                    {name: 'update_value_type', value: 'DATE'},
                                    {name: 'return_bool', value: true}
                                  ]}
                                  action={updateProductValue}
                                  minDate={props.convertISODate('2020-01-01 00:00:00')}
                                  disabled={canEdit ? false : true} />
                </div>
                <div className="card-row">
                  <label>Date Created</label>
                  {props.objExists(props.product, `details.${curLanguage}.product_created`) && 
                  props.datetimeDisplay(props.product.details[curLanguage].product_created)}
                </div>
                {props.objExists(props.product, `details.${curLanguage}.product_created`) && 
                props.objExists(props.product, `details.${curLanguage}.product_modified`) && 
                  props.product.details[curLanguage].product_modified !== props.product.details[curLanguage].product_created && 
                  <div className="card-row">
                    <label>Last Update</label>
                    {props.datetimeDisplay(props.product.details[curLanguage].product_modified)}
                  </div>
                }
              </div>
            </div>

            <div className="card-set">
             <div className="card-set-block">
                <div className="card-row">
                  <LiveFieldInput type="textarea"
                                    label="Description"
                                    name="product_description"
                                    value={props.product.details[curLanguage].product_description}
                                    action={updateValue}
                                    disabled={canEdit ? false : true} />
                </div>
                {optionsLang && optionsLang.length > 1 &&
                  <div className="card-row">
                    <LiveFieldInput type="select"
                      label="Language"
                      option_groups={[{options: optionsLang}]}
                      name="language_code"
                      value={curLanguage}
                      action={updateProductLanguage}
                      disabled={canEdit ? false : true} />
                  </div>
                }
              </div>
            </div>
          </Card>

          <Card title="Categories">
            {props.objExists(props[props.location], `product.details.${curLanguage}.categories`) &&
              Array.isArray(props[props.location].product.details[curLanguage].categories) &&
                <div className="card-set">
                  <div className="card-set-block">
                    <div className="card-row">
                      {props[props.location].product.details[curLanguage].categories.map((category, index) => {
                        return <CardValueTag
                                  key={`prdCatCVT_${props.product.product_id}_${index}`}
                                  content={`${category.category_parent_name ? `${props.capitalizeString(category.category_parent_name, true)} > ` : ''}${props.capitalizeString(category.category_name)}`}
                                  contentType="category"
                                  contentID={category.category_id}
                                  showOptions={category.category_product_link_id ? true : false}
                                  contentOptions={
                                    [
                                      {option_name: 'Product Quantity',
                                      option_value: category.product_quantity ? category.product_quantity : null,
                                      option_type: 'live-field-input',
                                      option_category_link_id: category.product_category_link_id ? category.product_category_link_id : null,
                                      option_quantity_link_id: category.product_quantity_link_id ? category.product_quantity_link_id : null,
                                      option_action: updateProductQuantity},
                                      {option_name: 'Optional Product',
                                      option_value: category.category_optional_product ? true : false,
                                      option_type: 'switch',
                                      option_action: toggleOptionalProduct},
                                    ]
                                  }
                                  className={category.category_optional_product ? `optional` : null}
                                  actionRemove={loadCategoryRemove}
                                  editable={canEdit} />
                      })}
                    </div>
                  </div>
                </div>
            }
            {canEdit && <Control icon="add" showLabel={true} label="Add Category" action={loadCategoryAdd} />}
          </Card>

          <Card title="Product Images">
            {props.objExists(props.product, `details.${curLanguage}.media`) &&
                <MediaSet {...props}
                          setType='images'
                          userID={userID}
                          token={token}
                          organizationID={organizationID}
                          language_code={curLanguage}
                          location={props.location}
                          useLocation={null}
                          isAdmin={canEdit}
                          media={props.product.details[curLanguage].media}
                          parent_id={props.product.product_id}
                          link_type="imageToProduct"
                          parent_type="product" /> }
            {canEdit && <Control icon="image" showLabel={true} label="Add Image" action={()=>{loadAddMedia('image', true)}} />}
            {files && Array.isArray(files) &&
              files.map((file, index) => {
                return <FileUploadPreview {...props} 
                                          index={index}
                                          file={file}
                                          media_type={'image'}
                                          media_owner_id={props.objExists(props.product, 'product_id') ? 
                                                  props.product.product_id : null}
                                          media_owner_type ='Product'
                                          user_id={props.userID}
                                          token={props.token}
                                          organization_id={organizationID}
                                          language_code={curLanguage ? curLanguage : 'en'} />
              })
            }
          </Card>
          
          <Card title="Sub-Products">
            {props.objExists(props.product, `details.${curLanguage}.sub_categories`) &&
                <SubProducts {...props}
                  subCategories={props.product.details[curLanguage].sub_categories}
                  actionRemove={loadSubProductsRemove}
                  editable={canEdit} />
            }
            {canEdit && !props.objExists(props.product, `details.${curLanguage}.sub_categories`) &&
              <Control icon="add" showLabel={true} label="Add Sub-Products" action={loadSubProductsAdd} />
            }
          </Card>
          
          <Card title="Product Downloads">
            <div className="info-block">
              Downloadable files available on the product page in the store front.
            </div>
            {Array.isArray(props.objGetValue(props.product, `details.${curLanguage}.downloads`)) &&
              <div className="file-set">
                {props.product.details[curLanguage].downloads.map((file, index) => {
                  return (
                    <MediaFile {...props}
                      key={`mftPrf${index}`}
                      userID={userID}
                      token={token}
                      inline={false}
                      media={file}
                      location={props.stateLocation ? props.stateLocation : "admin"}
                      showSpecs={true} />
                  )	
                })}
              </div>
            }
            {canEdit && <Control icon="file" showLabel={true} label="Add Downloadable File" action={()=>{loadAddMedia('download')}} />}
          </Card>

          <Card title="Product Files">
            <div className="info-block">
              General files related to the product. For internal use only - Admin access only.
            </div>
            {props.objExists(props.product, `details.${curLanguage}.files`) &&
              <div className="file-set">
                {props.product.details[curLanguage].files.map((file, index) => {
                  return (
                    <MediaFile {...props}
                      key={`mftPrf${index}`}
                      userID={userID}
                      token={token}
                      inline={false}
                      media={file}
                      location={props.stateLocation ? props.stateLocation : "admin"}
                      showSpecs={true} />
                  )	
                })}
              </div>
            }
            {canEdit && <Control icon="file" showLabel={true} label="Add Files" action={()=>{loadAddMedia('file')}} />}
          </Card>

          <Card title="Product Specs">
          {props.product.details[curLanguage].properties &&
            Array.isArray(props.product.details[curLanguage].properties) &&
            props.product.details[curLanguage].properties.length > 0 &&
            <PropertySet {...props}
                          editable={canEdit}
                          location="admin"
                          properties={props.product.details[curLanguage].properties}
                          objectPath={`product.details.${curLanguage}.properties`}
                          ctlActions={{delete: propertyRemove, edit: propertyEdit}}
                          updateObj={false}
                          disableTruncate={true}
                           />

          }
            {canEdit && <Control action={loadAddSpec} icon="add" showLabel={true} label="Add Spec?" />}
          </Card>
          <Card title="Custom Fields">
            {props.objExists(props.product, `details.${curLanguage}.fieldsets`) &&
              Array.isArray(props.product.details[curLanguage].fieldsets) &&
              props.product.details[curLanguage].fieldsets.length > 0 &&
              props.product.details[curLanguage].fieldsets.map((fieldset, index) => {
                  return <FormFieldList {...props}
                                  key={`cfffl${index}`}
                                  title={fieldset.fieldset_name}
                                  text_id={fieldset.text_id}
                                  fields={fieldset.fields}
                                  index={index}
                                  fieldset_id={fieldset.fieldset_id}
                                  actionAdd={loadAddEditField}
                                  actionEdit={loadAddEditField}
                                  actionSetFieldOrder={setFieldOrder}
                                  actionSetUpdateField={setUpdateField}
                                  actionFieldsetName={updateFieldsetName}
                                  actionOption={addUpdateOption}
                                  actionRemove={confirmRemoveField} />
              })
            }
            {canEdit && <Control action={(e)=>{e.preventDefault(); loadAddEditField(null, null, null)}}
                    icon="add"
                    showLabel={true}
                    label={`Add a ${!props.objExists(props.product, `details.${curLanguage}.fieldsets`) ||
                                  !Array.isArray(props.product.details[curLanguage].fieldsets) ||
                                  props.product.details[curLanguage].fieldsets.length === 0 ? 'field' : 'field in a new fieldset (group)'}?`} /> }
          </Card>
          {userAccess &&
            <Card title="Pricing">
              {props.objExists(props.product, `details.${curLanguage}.pricing`) &&
              Array.isArray(props.product.details[curLanguage].pricing) &&
              props.product.details[curLanguage].pricing.length > 0 &&
                  <ProductPricing {...props}
                                  pricing={props.product.details[curLanguage].pricing}
                                  isAdmin={true}
                                  product_id={props.product.product_id}
                                  actionAddEdit={addEditPricing}
                                  actionRemove={removePricingOrDiscount}
                                  actionDiscountAddEdit={addEditDiscount} />
              }
  
              {canEdit && <Control action={null}
                icon={"add"}
                action={()=>{launchAddEditPricing(false)}}
                showLabel={true}
                label={`Add Pricing`} /> }
            </Card>
          }
          {userAccess && 
            <Card title="Page Pricing">
              {props.objExists(props.product, `details.${curLanguage}.page_pricing`) &&
                  <ProductPricing {...props}
                                  page_pricing={props.product.details[curLanguage].page_pricing}
                                  isAdmin={true}
                                  product_id={props.product.product_id}
                                  actionAddEdit={addEditPricing}
                                  actionRemove={removePricingOrDiscount}
                                  actionDiscountAddEdit={addEditDiscount} />
              }
              {canEdit && !props.objExists(props.product, `details.${curLanguage}.page_pricing`) &&
                <Control action={null}
                  icon={"add"}
                  action={()=>{launchAddEditPricing(true)}}
                  showLabel={true}
                  label={`Add Page Pricing`} /> 
              }
            </Card>
          }
          {userAccess &&
            <Card title="Product Charges">
              {productCharges && Array.isArray(productCharges) && productCharges.length > 0 &&
                  <ProductCharges {...props}
                    editable={canEdit}
                    fields={productFields}
                    charges={productCharges}
                    isAdmin={true}
                    product_id={props.product.product_id}
                    actionAddEdit={addEditCharge}
                    actionRemove={removeCharge} />
              }
              {canEdit &&
                <Control action={null}
                  icon={"add"}
                  action={launchAddEditCharges}
                  showLabel={true}
                  label={`Add Charge`} />
              }
            </Card>
          }
          {userAccess &&
            <Card title="Product Restrictions">
              {props.isAdmin &&
                <div className="card-ctl top margin-top-1em">
                  {canEdit ?
                      <Switch name='product_approval_required'
                        action={updateClientApproval}
                        label={`Requires Approval`}
                        value={props.product.details[curLanguage].product_approval_required === 1 ? true : false}
                         />
                    :
                      <Fragment>
                        {props.product.details[curLanguage].product_approval_required === 1 ? 'Approval Required' : ''}
                      </Fragment>
                  }
                </div>
              }
              {props.objExists(props.product, `details.${curLanguage}.restrictions`) &&
                  <ProductRestrictions {...props}
                    editable={canEdit}
                    curLanguage={curLanguage}
                    organization_id={props.product.organization_id}
                    product_id={props.product.product_id}
                    details={props.product.details[curLanguage]}
                    restrictions={props.product.details[curLanguage].restrictions}
                    actionAddEdit={addEditRestriction}
                    actionRemove={removeRestriction} />
              }
              {canEdit && <Control action={null}
                icon={"add"}
                action={()=>{launchAddEditRestriction(false)}}
                showLabel={true}
                label={`Add Restriction`} /> }
              </Card>
          }
          {userAccess && 
          (userAccess.super || 
          props.objGetValue(userAccess, `staffAdmin.${props.product.organization_id}`) || 
          props.objGetValue(userAccess, `inventory.${props.product.organization_id}`)) &&
            <Card title="Inventory">
              {
                props.site.persona.languages && 
                props.site.persona.languages.length > 1 &&
                props.product.details[curLanguage].product_inventoried === 1 && 
                  <div className="info-block" style={{margin: '0 0 2em 0'}}>
                    The Inventoried switch must be <b>off</b> to add languages to this product.
                  </div>
              }
              <div className="card-row">
                <Switch name='product_inventoried'
                  action={updateProductSwitchValue}
                  label={`Inventoried`}
                  value={props.product.details[curLanguage].product_inventoried === 1 ? true : false}
                  params={[
                    {name: 'process', value: 'update_product_value'},
                    {name: 'user_id', value: userID},
                    {name: 'token', value: token},
                    {name: 'organization_id', value: props.product.organization_id},
                    {name: 'product_id', value: props.product.product_id},
                    {name: 'update_column', value: 'product_inventoried'},
                    {name: 'update_value_type', value: 'BIT'}
                  ]}
                   />
              </div>
              <div className="card-row" style={{fontSize: '2em'}}>
                <b>
                  {props.objGetValue(props.product, `details.${curLanguage}.product_stock`) > 0 ?
                    `${props.product.details[curLanguage].product_stock} in Stock` :
                    "No Inventory"}
                </b>
              </div>
              <div className="card-row">
                <Control action={null}
                  icon={"add"}
                  action={launchAddInventory}
                  showLabel={true}
                  label={`Add/Remove Inventory`} />
              </div>
              {Array.isArray(props.objGetValue(props.product, `product_transactions`)) &&
                <div className="card-block">
                  <h4>
                    Inventory History
                    <IconToken icon={inventoryHistory ? `collapse` : `expand`} action={()=>{toggleInventoryHistory(inventoryHistory ? false : true)}} />
                  </h4>
                  <div className={`grid col-5 scrollable fixed-header expandable${inventoryHistory ? ' active' : ''}`}
                      style={{gridTemplateColumns: 'auto 2fr auto auto auto'}}>
                    <div className="grid-header header-row">Date</div>
                    <div className="grid-header header-row">Transaction</div>
                    <div className="grid-header header-row">User</div>
                    <div className="grid-header header-row">Qty</div>
                    <div className="grid-header header-row">Total</div>
                    {props.product.product_transactions.map((trans, index) => {
                      return (
                        <Fragment key={`prdTrnsctn${index}`}>
                          <div className="column">
                            {`${props.dateDisplay(trans.transaction_date)}, ${props.timeDisplay(trans.transaction_date)}`}
                          </div>
                          <div className="column" style={{fontWeight: 'bold'}}>
                            {trans.project_number ?
                              `Order ${trans.project_number}`
                            :
                              `Inventory Adjustment (${
                                trans.transaction_source.replace(' inventory', '')
                              })` 
                            }
                            {`${trans.transaction_name ? ` : ${props.capitalizeString(trans.transaction_name, true)}` : ''}`}
                          </div>
                          <div className="column">
                            {trans.created_user_name}
                          </div>
                          <div className="column" 
                                style={{textAlign: 'right'}}>{`${
                            trans.transaction_source === 'remove inventory' ?
                            `-` : '+'
                            }${trans.transaction_value}`
                          }</div>
                          <div className="column"
                                style={{textAlign: 'right'}}>
                                {trans.transaction_value_end}
                          </div>
                        </Fragment>
                      )
                    })
                      
                    }
                  </div>
                </div>
              }
              <div className="card-row">
                <LiveFieldInput
                    {...props}
                    name="product_stock_min"
                    label="Minimum Stock Level to Maintain"
                    value={props.objGetValue(props.product, `details.${curLanguage}.product_stock_min`) > 0 ? 
                            props.product.details[curLanguage].product_stock_min : null}
                    action={updateProductValue}
                    optionalParams={[
                      {name: 'process', value: 'update_product_value'},
                      {name: 'user_id', value: userID},
                      {name: 'token', value: token},
                      {name: 'organization_id', value: props.product.organization_id},
                      {name: 'product_id', value: props.product.product_id},
                      {name: 'update_value_type', value: 'INT'}
                ]} />
              </div>
              <div className="card-row">
                <LiveFieldInput
                    {...props}
                    name="product_quantity_max"
                    label="Max Quantity Per Order"
                    value={props.product.details[curLanguage].product_quantity_max}
                    action={updateProductValue}
                    optionalParams={[
                      {name: 'process', value: 'update_product_value'},
                      {name: 'user_id', value: userID},
                      {name: 'token', value: token},
                      {name: 'organization_id', value: props.product.organization_id},
                      {name: 'product_id', value: props.product.product_id},
                      {name: 'update_value_type', value: 'INT'}
                ]} />
              </div>
            </Card>
          }
          {
            props.site.persona.languages && 
            props.site.persona.languages.length > 1 &&
            props.product.details[curLanguage].product_inventoried === 0 && 
            languagesAssigned && languagesAssigned.length > 0 &&
              <Card title="Languages">
                <div className="card-set">
                  <div className="card-set-block">
                    <div className="card-row">
                      {languagesAssigned.map((lang, index) => {
                        return <CardValueTag
                                  key={`prdLang_${props.product.product_id}_${index}`}
                                  content={`${props.capitalizeString(lang.language_name)}`}
                                  contentType="language"
                                  contentID={lang.language_id}
                                  className={"options"}
                                  actionRemove={languagesAssigned.length > 1 ? loadLanguageRemove : null}
                                  values={{
                                    language_name: props.capitalizeString(lang.language_name),
                                    text_id: props.product.details[lang.language_code] && props.product.details[lang.language_code].text_id
                                  }}
                                  editable={canEdit} />
                      })}
                    </div>
                  </div>
                </div>
                {
                  canEdit && 
                  languagesAvailable && 
                  languagesAvailable.length > 0 && 
                    <Control icon="add" showLabel={true} label="Add Language" action={loadLanguageAdd} />
                }
              </Card>
          }
        </Fragment>
      :
        <Loader />
      }
    </article>
  )
}
