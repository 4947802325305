import React, {useEffect, useState} from 'react';

import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
  const [userID, setUserID] = useState(null);
  const [token, setToken] = useState(null);
  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState(null);
  const [languageCode, setLanguageCode] = useState(null);
  const [value, setValue] = useState(null);
  const [parentValue, setParentValue] = useState(null);
  const [coreOrg, setCoreOrg] = useState(null);
  const [optionsOrgs, setOptionsOrgs] = useState(null);
  const [optionsCategories, setOptionsCategories] = useState(null);
  const [optionsParentCategories, setOptionsParentCategories] = useState(null);
  const [fieldsets, setFieldsets] = useState(null);
  const [form, setForm] = useState(null);
  
  // HOOKS
  	useEffect(() => {
		if(props.userID &&
			props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);

	useEffect(() => {
  		if(props.token &&
	  		props.token !== token) {
	  		setToken(props.token);
  		}
	}, [props.token]);
	
	
	
  useEffect(() => {
	  if(props.category &&
		  props.category !== category) {
		  setCategory(props.category);
	  }
  }, [props.category]);
  
  useEffect(() => {
	  if(props.languageCode && 
		  props.languageCode !== languageCode) {
		  setLanguageCode(props.languageCode);
	  }else if(!languageCode) {
		  setLanguageCode('en');
	  }
  }, [props.langaugeCode]);
  
  useEffect(() => {
	if(props.coreOrg &&
		props.coreOrg !== coreOrg) {
			setCoreOrg(props.coreOrg);
	}
  }, [props.coreOrg]);
  
  useEffect(() => {
	  if(Array.isArray(props.organizations)) {
		  let orgOpts = [];
		  props.organizations.map(org => {
			  org.organization_id !== 1 &&
			  orgOpts.push(
				  {option_name: org.organization_name,
					option_value: org.organization_id
				  }
			  );
		  })
		  setOptionsOrgs([
			  {options: orgOpts}
		  ]);
	  }
  }, [props.organizations]);
  
  useEffect(() => {
	  if(props.categories && languageCode) {
		  if(Array.isArray(props.categories) &&
			  props.objExists(props.categories[0], 'category_id') && 
			  props.categories !== categories) {
			  setCategories(props.categories);			
		  }else if(Object.entries(props.categories).length > 0 && 
				  props.objExists(props.categories, `${languageCode}`)) {
			  setCategories(props.categories[languageCode]);
		  }
	  }
  }, [props.categories, languageCode]);
  
  useEffect(() => {
  	if(categories && 
		Array.isArray(categories) && 
		categories.length > 0) {
		let categoriesFlat = [];
		categories.map(cat => {
			categoriesFlat.push(...setCatOptions(cat));
		})
		if(categoriesFlat.length > 0) {
			categoriesFlat.sort((a, b) => {return a.category_name > b.category_name ? 1 : -1});
			setOptionsCategories(categoriesFlat);
		}
	}
  }, [categories]);
  
  useEffect(() => {
	let catFieldsets = [
		{
			fieldset_name: `${props.objExists(category, `category_id`) ? 'Edit' : 'Add'} ${
				props.objGetValue(category, 'category_parent') > 0 || props.objGetValue(props, 'categoryParent') > 0 ? 
				`Subcategory` :`Category`}`,
			fields: []
		}
	];
	let fieldOrder = 1;
	if(optionsOrgs && !props.objExists(category, `category_id`)) {
		catFieldsets[0].fields.push(
			{field_type:'select',
			field_name: 'organization_id',
			field_label: `Organization`,
			field_value: coreOrg,
			field_required: true,
			field_validate: true,
			field_order: fieldOrder,
			option_groups: optionsOrgs,
			fieldOnChange: updateOrg
			}
		);
		fieldOrder++;
	}else{
		catFieldsets[0].fields.push(
			{field_type: 'hidden',
			field_name: 'organization_id',
			field_value: coreOrg,
			field_required: true
			}
		);
	}
	
	catFieldsets[0].fields.push(
		{field_type: 'text',
			field_name: 'category_name',
			field_label: 'Category Name',
			field_placeholder: 'Name of category',
			field_value: category && category.category_name ? category.category_name : '',
			field_required: true,
			field_validate: true,
			field_order: fieldOrder,
		}	
	);
	fieldOrder++;
	if(optionsCategories) {
		catFieldsets[0].fields.push(
			{field_type: 'select',
			field_name: 'category_parent',
			field_label: `Parent Category`,
			field_default: props.objExists(category, `category_parent`) ? 
								category.category_parent : 
									props.categoryParent ? 
										props.categoryParent :
											null,
			field_placeholder: 'Select Parent...',
			field_order: fieldOrder,
			option_groups: [
				{options: optionsCategories}
			]
		});
		fieldOrder++;
	}
	
	catFieldsets[0].fields.push(
		{field_type: 'switch',
			field_name: 'category_published',
			field_label: 'Publish',
			field_value: category && category.category_published === 0 ? false : category && category.category_published === 1 ? true : false,
			field_required: true,
			field_validate: true,
			field_order: fieldOrder,
		}	
	)
	fieldOrder++;
	catFieldsets[0].fields.push(
		{field_type: 'switch',
			field_name: 'category_private',
			field_label: 'Hidden',
			field_value: category && category.category_private === 0 ? false : category && category.category_private === 1 ? true : false,
			field_required: true,
			field_validate: true,
			field_order: fieldOrder,
		}	
	)
	
	catFieldsets[0].fields.push(
		{field_type: 'hidden',
		field_name: 'process',
		field_value: `${props.objExists(category, 'category_id') ? 'update' : 'create'}_category`
		},
		{field_type: 'hidden',
		field_name: 'user_id',
		field_value: userID
		},
		{field_type: 'hidden',
		field_name: 'token',
		field_value: token
		},
		{field_type: 'hidden',
		field_name: 'language_code',
		field_value: languageCode
		},
		{field_type: 'hidden',
		field_name: 'category_type',
		field_value: props.categoryType
		},
		{field_type: 'hidden',
		field_name: 'return_bool',
		field_value: props.returnBool ? props.returnBool : false
		}
	);
	
	if(props.objExists(category, `category_id`)) {
		catFieldsets[0].fields.push(
			{field_type: 'hidden',
			field_name: 'category_id',
			field_value: category.category_id}
		);
	}
	
	setFieldsets(catFieldsets);
	}, [category, optionsCategories, coreOrg, optionsOrgs, userID, token]);
  
  useEffect(() => {
	fieldsets &&
	setForm(
		<FormDynamic
			content={fieldsets}
			name="add-edit-category"
			returnObject={true}
			fieldOnChange={handleChange}
			cancelable={props.cancelable ? true : false}
			fieldOnLoad={(e)=>{e.preventDefault()}}
			formActions={{formCancel: handleCancel, formSubmit: handleSubmit}} />
	 )
	}, [fieldsets]);

  

  useEffect(() => {
	if(optionsParentCategories) {
	  if(Array.isArray(optionsParentCategories)) {
		let parentCategories = props.objClone(optionsParentCategories);



		if(value) {
		  parentCategories[0] = {options: parentCategories[0].options.filter(option => option.option_value !== value)};
		}
		setOptionsParentCategories(parentCategories);
	  }
	}
  }, [value]);

  // FUNCTIONS
  function setCatOptions(cat, parentName = null) {
	let catOpt = [];
	catOpt.push({option_name: props.capitalizeString(`${parentName ? `${parentName}/` : ''}${cat.category_name}`, true), option_value: cat.category_id});
	if(cat.sub_categories && 
		Array.isArray(cat.sub_categories) && 
		cat.sub_categories.length > 0) {
		cat.sub_categories.map (sCat => {
			catOpt.push(...setCatOptions(sCat, parentName ? `${parentName}/${cat.category_name}` : `${cat.category_name}`));
		})
	}
	return catOpt;
  }
  
  function updateOrg(params) {
	  const orgID = params ? parseInt(params.value) : null;
	  if(orgID && orgID > 0 && 
		  orgID !== coreOrg &&
	  		props.actionSelectOrg) {
		console.log("selecting org", orgID);
		props.actionSelectOrg(orgID);
	  }
  }

  function handleCancel() {
	props.setModal(null);
  }

  function handleSubmit(params) {
	if(props.actionSubmit) {
	  props.actionSubmit(params);
	}
  }

  function handleChange(e) {
	if(e && e.name) {
	  switch(e.name) {
		case 'category_id':
		  setValue(e.value ? parseInt(e.value) : null);
		  break;
		case 'parent_id':
		  setParentValue(parseInt(e.value));
		  break;
		default:
		  break;
	  }
	}
  }

  return (fieldsets && form)
}
