import React, {useEffect, useState, Fragment} from 'react';

import Loader from 'components/atoms/Loader';
import IconSVG from 'components/icons/IconSVG';
import IconToken from 'components/icons/IconToken';
import Control from 'components/atoms/Control';
import FileUpload from 'components/forms/elements/FileUpload';
import Switch from 'components/forms/elements/Switch';
import LiveFieldInput from 'components/forms/elements/LiveFieldInput';
import MediaHero from 'components/profiles/media/MediaHero';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [category, setCategory] = useState(null);
	const [parent, setParent] = useState(null);
	const [isLoading, setIsLoading] = useState(null);
	
	// HOOKS	
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.organizationID && props.organizationID !== organizationID) {
			setOrganizationID(props.organizationID);
		}
	}, [props.organizationID]);
	
	useEffect(() => {
		if(props.languageCode && props.languageCode !== languageCode) {
			setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		setIsLoading(true);
		if(props.category && props.category !== category) {
			setCategory(props.category);
		}
	}, [props.category]);
	
	useEffect(() => {
		if(category && isLoading) {
			setIsLoading(false);
		} 
	}, [category]);
	
	// FUNCTIONS
	function getLanguageId(language_code) {
		let language_id;
		if(props.site.persona.languages && props.site.persona.languages.length > 1) {
			language_id = props.site.persona.languages.find(lang => lang.language_code === language_code).language_id;
		}
		return language_id;
	}
	
	function getLanguageName(language_code) {
		let language_name = language_code;
		if(props.site.persona.languages && props.site.persona.languages.length > 1) {
			language_name = props.site.persona.languages.find(lang => lang.language_code === language_code).language_name;
		}
		return language_name;
	}
	
	function handleEdit() {
		if(category && props.actionAddEdit) {
			props.actionAddEdit(category, category.category_parent);
		}
	}
	
	function handleAddLanguage() {
		if(category && props.actionAddLanguage) {
			props.actionAddLanguage(category.category_id);
		}
	}
	
	function handleRemoveLanguage() {
		if(category && props.actionRemoveLanguage) {
			props.actionRemoveLanguage(category, getLanguageId(languageCode));
		}
	}
	
	function handleRemove() {
		if(category && props.actionRemove) {
			props.actionRemove(category);
		}
	}
	
	function loadAddImage() {
		props.setModal(
			<FileUpload {...props}
			  userID={userID}
			  token={token}
			  organizationID={organizationID}
			  language_code={languageCode}
			  media_type="image"
			  media_owner_type="category"
			  media_owner_id={category.category_id}
			  media_published={true}
			  return_boolean={true}
			  includeSubmit={true}
			  actionCancel={()=>{props.setModal(null);}}
			  actionUpload={{action: ()=>{props.setModal(null)}}}
			  cueReload={{location: props.location ? props.location : 'admin', type: 'category'}}
			  />
		);
	}
	
	//console.log("CATEGORY", category);
	
	return(
		<div className="profile full">
			<Fragment>
				<div className="grid-col-2 grid-col-2-header">
					<div className="grid-col">
						<h2>Category</h2>
					</div>
					<div className="grid-col text-right">
						{category && category.category_id && <Control {...props}
							icon="trash" 
							label="Remove Category" 
							showLabel={true}
							action={handleRemove} />
						}
					</div>
				</div>
				<div className="grid-col-2 grid-gap-2">
					<div className="grid-col grid-bg">
						{category && !isLoading ?
							typeof category === 'object' && !Array.isArray(category) && category !== null ?
								<div className="grid-col-container">
									<div>
										<h2>{props.capitalizeString(category.category_name, true)}</h2>
										<div className="grid-col-controls">
											<IconToken icon="edit" 
												className=""
												tooltip="Edit Category" 
												showTooltip={true}
												action={handleEdit} />
											<IconToken icon="trash" 
												className=""
												tooltip="Remove Language" 
												showTooltip={true}
												action={handleRemoveLanguage} />
										</div>
									</div>
									<div className="grid-col-text-sub">
										{
											category && 
											category.languages && 
											category.languages.length > 0 && 
											props.site.persona.languages && 
											props.site.persona.languages.length > 1 && 
												category.languages.map((lang, index) => {
													if((index + 1) != category.languages.length) {
														return (getLanguageName(lang) + '\xa0\xa0|\xa0\xa0');
													}else{
														return getLanguageName(lang);
													}
												})
										}
									</div>
									<div className="grid-col-info">
										<div className="grid-col-image">
											{props.objExists(category, 'media[0].media_file_name') ?
												<MediaHero {...props} 
													media={category.media[0]}
													isAdmin={false} />
											:
												<MediaHero {...props} 
													mediaFileName="aeImageUnavailable.jpg"
													isAdmin={false} />	
											}
										</div>	
										<div className="grid-col-details">
											<div>
												{category.parent && 
													<div>Parent: <b>{props.capitalizeString(category.parent.category_name, true)}</b></div>
												}
												{category && 
													<div>Published: <b>{category.category_published === 1 ? 'Yes' : 'No'}</b></div>
												}
												{category && 
													<div>Hidden: <b>{category.category_private === 1 ? 'Yes' : 'No'}</b></div>
												}
												{category.products && 
													<div>
														Product Count: <b>{category.products && Array.isArray(category.products) && category.products.length}</b>
													</div>
												}
												{category.category_created &&
													<div>Created: <b>{props.dateDisplay(category.category_created)}</b></div>
												}
												{category.category_modified &&
													category.category_created !== category.category_modified &&
													<div>Updated: <b>{props.dateDisplay(category.category_modified)}</b></div>
												}
											</div>
										</div>
									</div>
									<div className="grid-col-control">
										<Control {...props}
											icon="image" 
											label="Upload Image" 
											showLabel={true}
											action={loadAddImage} />
									</div>
								</div>
							:
								<div className="flex-column-center-center">
									<div className="flex-column-message">
									  <IconSVG icon="alert" className="admin-page-icon" />
									  <div className="admin-page-msg">Sorry, this catagory is not available for {props.objExists(props.site, 'persona.organization_name') ? props.site.persona.organization_name : 'your organization' }{props.objExists(props.site, 'persona.languages') && props.site.persona.languages.length > 0 && ' in the language selected'}.</div>
									</div>
								  </div>
						:
							<Loader label="Loading Category" />
						}
					</div>
					<div className="grid-col grid-bg">
						<div className="flex-column-center-center">
							{props.addLanguage ? 
								<Control {...props}
									icon="add" 
									label="Add Language" 
									showLabel={true}
									action={handleAddLanguage} />
							:
								<Control {...props}
									icon="add" 
									label="Add Language" 
									showLabel={true}
									className="disabled" />
							}
						</div>
					</div>
				</div>
			</Fragment>
		</div>
	)
}