import React, {useEffect, useState, Fragment} from 'react';

import FormDynamic from 'components/forms/FormDynamic';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [languages, setLanguages] = useState(null);
	const [fieldsets, setFieldsets] = useState(null);
	const [form, setForm] = useState(null);

	// HOOKS
	useEffect(() => {
		if(props.userID && props.userID !== userID) {
			setUserID(props.userID);
		}
	}, [props.userID]);
	
	useEffect(() => {
		if(props.token && props.token !== token) {
			setToken(props.token);
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.languages && props.languages !== languages) {
			setLanguages(props.languages);
		} 
	}, [props.languages]);
	
	useEffect(() => {
		if(userID && token && languages) {
			setFieldsets([
				{fieldset_name: 'Add Language',
					fields: [
						{field_type: 'hidden',
						field_name: 'process',
						field_value: 'add_product_text',
						},
						{field_type: 'hidden',
						field_name: 'user_id',
						field_value: userID,
						},
						{field_type: 'hidden',
						field_name: 'token',
						field_value: token,
						},
						{field_type: 'hidden',
						field_name: 'product_id',
						field_value: props.product_id,
						},
						{field_type: 'select',
						field_name: 'language_id',
						field_label: 'Language',
						field_placeholder: 'Select language',
						field_value: null,
						field_required: true,
						field_validate: true,
						option_groups: [
							{options: 
								languages && Array.isArray(languages) &&
								languages.map(language => {
									return {option_name: language.language_name,
											option_value: language.language_id}
								})}
							],
						field_order: 1,
						},
						{field_type: 'text',
						field_name: 'text_code',
						field_label: `Code`,
						field_value: props.objExists(props, 'product_code') ? props.product_code : null,
						field_placeholder: 'Product Code',
						field_order: 2,
						field_required: false,
						field_validate: false},
						{field_type: 'text',
						field_name: 'text_name',
						field_label: `Name`,
						field_value: props.objExists(props, 'product_name') ? props.product_name : null,
						field_placeholder: 'Product Name',
						field_order: 3,
						field_required: true,
						field_validate: true},
						{field_type: 'textarea',
						field_name: 'text_description',
						field_label: `Description`,
						field_value: props.objExists(props, 'product_description') ? props.product_description : null,
						field_placeholder: 'Product Description',
						field_order: 4,
						field_required: false,
						field_validate: false}
				]}]);
		}else if(fieldsets) {
			setFieldsets(null);
		}
	}, [userID, token, languages]);
	
	useEffect(() => {
		if(fieldsets) {
			setForm(
				<FormDynamic {...props}
					content={fieldsets}
					name="product-text-add"
					returnObject={true}
					fieldOnChange={null}
					fieldOnLoad={null}
					formActions={{formSubmit: handleSubmit, formCancel: handleCancel}} />
			);
		}
	}, [fieldsets]);
	
	// FUNCTIONS
	function handleSubmit(params) {
		if(props.actionSubmit) {
			props.actionSubmit(params);
		}
	}
	
	function handleCancel() {
		if(props.actionCancel) {
			props.actionCancel();
		}else{
			props.setModal(null);
		}
	}
	
	return form
}